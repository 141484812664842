import React from "react";
import { request, getQueryStringValue, arraysEqual } from "../lib/library";
import ReserveService from "../services/ReserveService";
import ItemDisplay from "./ItemDisplay";
import AccountService from "../services/AccountService";
import ExpandingInfiniteScroll from "./ExpandingInfiniteScroll";
import Pagination from "../pages/Pagination";

import "./Feed.scss";
import { _get } from "../lib/litedash";
import TriInfoSection from "../pages/landing/TriInfoSection";
import URLAnchorService from "../services/URLAnchorService";
import { SORT_ENUM } from "../lib/itemEnums";
const ITEMS_PER_PAGE = 24;
class Feed extends React.Component {
  constructor(props) {
    super(props);
    this.state = Feed.startingState;
  }

  static get startingState() {
    return {
      items: [],
      loaded: false,
      LastEvaluatedKey: false,
      nextStart: 0
    };
  }

  componentDidMount = () => {
    this.selectID = this.props.selectID;
    this.sortKey = this.props.sortKey;
    this.shopLocation = this.props.shopLocation;
    this.sizes = this.props.sizes;
    this.categories = this.props.categories;
    ReserveService.addEventListener(this);
    this.fetchData();
  };

  componentWillUnmount = () => {
    ReserveService.removeEventListener(this);
  };

  componentDidUpdate() {
    // if (this.props.refresh) {
    //   this.setState({
    //     items: [],
    //     loaded: false
    //   });
    //   this.fetchData();
    //   this.props.setRefresh();
    if (this.props.filterable && this.sortKey != this.props.sortKey) {
      this.sortKey = this.props.sortKey;
      this.resetState();
      this.newFetch = true;
    } else if (
      this.props.filterable &&
      !arraysEqual(this.sizes, this.props.sizes)
    ) {
      this.sizes = this.props.sizes;
      this.resetState();
      this.newFetch = true;
    } else if (
      this.props.filterable &&
      !arraysEqual(this.categories, this.props.categories)
    ) {
      this.categories = this.props.categories;
      this.resetState();
      this.newFetch = true;
    } else if (this.getCurrentPage() != this.currentPage) {
      this.currentPage = this.getCurrentPage();
      this.resetState();
      this.newFetch = true;
    } else if (this.props.selectID != this.selectID) {
      this.selectID = this.props.selectID;
      this.resetState();
      this.newFetch = true;
    } else if (this.newFetch) {
      this.newFetch = false;
      this.fetchData();
    } else if (
      this.props.shopLocation &&
      this.props.shopLocation != this.shopLocation
    ) {
      this.shopLocation = this.props.shopLocation;
      this.resetState();
      this.newFetch = true;
    }
  }

  resetState() {
    this.setState(Feed.startingState);
  }

  onNotification = notification => {
    if (notification.action == "reserve") {
      let items = [...this.state.items];
      for (let item of items) {
        if (item.itemID == notification.itemID) {
          item.status = 2;
        }
      }
      this.setState({
        items
      });
    }
  };

  hasMore = () => {
    // using this.state.loading prevents two loads from happening at the start of the page.
    if (this.props.type === "watch") {
      return this.state.LastEvaluatedKey;
    }
    return this.state.nextStart > 0;
  };

  get isLoadingMore() {
    return this.state.loading || this.hasMore();
  }

  getLastEvaluatedKeyQueryParams = () => {
    let { type } = this.props;
    let resp = {};
    if (this.props.shopLocation) {
      resp.lat = this.props.shopLocation.lat;
      resp.long = this.props.shopLocation.long;
    }
    if (type === "school" || type === "user") {
      if (
        this.state.nextStart &&
        this.state.nextStart != -1 &&
        !this.state.refresh
      ) {
        resp.start = this.nextStart;
      }
      resp.sort = this.props.sortKey;
      if (this.props.categories && this.props.categories.length) {
        resp.categories = this.props.categories.join(",");
      }
      if (this.props.sizes && this.props.sizes.length) {
        resp.sizes = this.props.sizes.join(",");
      }

      if (type === "user") {
        resp.handle = this.props.selectID;
        resp.sort = 6;
      }
    } else if (type === "watch") {
      resp.selectID = this.props.selectID;
      if (this.state.LastEvaluatedKey) {
        const { pk, sort } = this.state.LastEvaluatedKey;
        resp.pk = pk;
        resp.sort = sort;

        const { gsi_1_pk, gsi_1_sort } = this.state.LastEvaluatedKey;
        resp.gsi_1_pk = gsi_1_pk;
        resp.gsi_1_sort = gsi_1_sort;
      }
    }
    return resp;
  };

  isDefaultFilters = () => {
    return (
      this.props.sortKey == SORT_ENUM.FEATURED.id &&
      (!this.props.sizes || this.props.sizes.length == 0) &&
      (!this.props.categories || this.props.categories.length == 0) &&
      this.props.type != "user" &&
      this.props.type != "watch"
    );
  };

  fetchData = async (loadStatus = this.state.lastLoadedStatus, isImmediate) => {
    const URL = this.props.type === "watch" ? "/user/watching" : "/items";
    const requiresAuth =
      this.props.type === "watch" || AccountService.isLoggedIn;

    if (!isImmediate) {
      this.setState({
        loading: true
      });
    }
    let LastEvaluatedKeyParams = this.getLastEvaluatedKeyQueryParams();
    if (this.getCurrentPage() != 1) {
      LastEvaluatedKeyParams.start =
        ITEMS_PER_PAGE * (this.getCurrentPage() - 1);
    }
    const response = await request(
      "get",
      URL,
      requiresAuth,
      LastEvaluatedKeyParams
    );
    if (!response.success) {
      this.setState({
        loaded: true,
        loading: false
      });
      return;
    }
    const pastItems = [];

    let shouldImmediatelyLoadMore = response.items.length < 3 && loadStatus < 3;
    this.setState({
      items: pastItems.concat(response.items),
      loaded: true,
      loading: shouldImmediatelyLoadMore,
      LastEvaluatedKey: response.LastEvaluatedKey,
      nextStart: response.nextStart,
      totalItems: response.total
    });
    if (shouldImmediatelyLoadMore) {
      // fetchData is only called by the automatic stroller when items increase in size.
      // since response.items == 0, it is not increasing in size so another fetch doesn't
      // happen so we have to do it ourselves.
      this.fetchData(loadStatus + 1, true);
    }
  };

  goToPage = pageNumber => {
    URLAnchorService.setKeyValue("page", pageNumber);
  };

  scrollToTop = () => {
    let headerFiller = document.getElementsByClassName("headerFiller")[0];
    let scrollToElement = document.getElementsByClassName("splash")[0];
    let splashMobile = document.getElementsByClassName("splash__textMobile")[0];

    window.scrollTo(
      0,
      _get(headerFiller, "offsetHeight") +
        _get(scrollToElement, "offsetHeight") +
        _get(splashMobile, "offsetHeight", 0)
    );
  };

  getCurrentPage() {
    return parseInt(URLAnchorService.getHashValue("page", "1"));
  }

  render() {
    const items = this.state.items;
    const mapped = items.map((item, index) => (
      <ItemDisplay key={index} item={item} />
    ));
    let loadingIndicator = null;
    if (
      this.state.loaded &&
      this.state.items.length == 0 &&
      !this.state.loading
    ) {
      if (this.isDefaultFilters()) {
        return (
          <div className="feed">
            <div className="noSellersLabel">
              <h3 className="marginBottomSmall">
                There are currently no sellers in your area
              </h3>
              <p className="marginBottomXLarge">
                To become the first, just list some items that you would like to
                sell, <br /> and then post a link to your social media to share
              </p>
              <p>
                <a
                  href="/shop#lat=34.068921&lng=-118.4451811"
                  className="mainLink marginBottomLarge"
                  target="_blank"
                >
                  See what's selling at UCLA
                </a>
              </p>
              <br />
              <p>
                <a href="https://nextbestshop.com" className="mainLink">
                  Or check out our online curated shop
                </a>{" "}
              </p>
            </div>
          </div>
        );
      } else {
        return (
          <div className="feed">
            <p className="noItemsLabel">No items to display.</p>
          </div>
        );
      }
    } else if (this.isLoadingMore) {
      loadingIndicator = (
        <div className="loadingContainer">
          <p className="rippleStatusContainer textCenter">
            <img src="/ripple.svg" />
          </p>
        </div>
      );
    }
    const totalItems = this.state.totalItems;
    if (!this.state.loading) {
      return (
        <div className="feed">
          <div className="main paginationContainer">
            <span>{this.state.totalItems} items</span>
            <Pagination
              itemsPerPage={ITEMS_PER_PAGE}
              totalItems={totalItems}
              items={this.state.items}
              goToPage={this.goToPage}
              currentPage={this.getCurrentPage()}
            />
          </div>
          <div className="main">{mapped}</div>
          <div className="center bottomPaginationContainer marginBottomMedium">
            <Pagination
              itemsPerPage={ITEMS_PER_PAGE}
              totalItems={totalItems}
              items={this.state.items}
              goToPage={this.goToPage}
              currentPage={this.getCurrentPage()}
              scrollToTop={this.scrollToTop}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="main">
          <div className="loadingContainer">
            <p className="rippleStatusContainer textCenter">
              <img src="/ripple.svg" />
            </p>
          </div>
        </div>
      );
    }
  }
}

export default Feed;
