import React from "react";
import "./Select.scss";
import {
  CATEGORY_KEY_LIST,
  CATEGORY_ENUM,
  SIZE_GROUP_ENUM,
  SIZE_ENUM,
  MENS_CATEGORY_KEY_LIST
} from "../lib/itemEnums.js";

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false
    };
  }

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = e => {
    if (this.node.contains(e.target)) {
      return;
    } else {
      this.setState({
        opened: false
      });
    }
  };

  onOpen = () => {
    this.setState({
      opened: !this.state.opened
    });
  };

  addCategoriesSectionToMapped(categoryTitle, categoriesKeyList, mapped) {
    mapped.push(
      <p key={categoryTitle} className="size_header">
        {categoryTitle}
      </p>
    );
    let new_mapped = [];
    categoriesKeyList.forEach(key => {
      new_mapped.push(
        <p
          className={
            this.props.selected.indexOf(CATEGORY_ENUM[key].id) === -1
              ? "size_option unselected"
              : "size_option selected"
          }
          key={key}
          onClick={() =>
            this.props.handleSelect(CATEGORY_ENUM[key], this.props.content)
          }
        >
          {CATEGORY_ENUM[key].text}
        </p>
      );
    });
    mapped.push(
      <div key="cat_section" className="size_section">
        {new_mapped}
      </div>
    );
  }

  render() {
    const items = this.props.options;
    let mapped = [];
    let mapped_options = [];

    for (const section in items) {
      mapped.push(
        <p key={section + "_size_header"} className="size_header">
          {section}
        </p>
      );

      if (Array.isArray(items[section])) {
        mapped_options = items[section].map(option => {
          if (
            this.props.selected.length === 0 &&
            (option === "All Categories" || option === "All Sizes")
          ) {
            return (
              <p
                className="size_option selected"
                key={option}
                onClick={() =>
                  this.props.handleSelect(option, this.props.content)
                }
              >
                {option}
              </p>
            );
          }
          return (
            <p
              className={
                this.props.selected.indexOf(option) === -1
                  ? "size_option unselected"
                  : "size_option selected"
              }
              key={option}
              onClick={() =>
                this.props.handleSelect(option, this.props.content)
              }
            >
              {option}
            </p>
          );
        });
        mapped.push(
          <div key={section + "_size_section"} className="size_section">
            {mapped_options}
          </div>
        );
      }
    }

    let new_mapped = [];

    if (this.props.content === "category") {
      this.addCategoriesSectionToMapped(
        "Women's Categories",
        CATEGORY_KEY_LIST,
        mapped
      );
      this.addCategoriesSectionToMapped(
        "Men's Categories",
        MENS_CATEGORY_KEY_LIST,
        mapped
      );
    } else if (this.props.content === "size") {
      for (const key in SIZE_GROUP_ENUM) {
        mapped.push(
          <p key={key + "_header"} className="size_header">
            {SIZE_GROUP_ENUM[key].text}
          </p>
        );
        SIZE_GROUP_ENUM[key].sizes.forEach(num => {
          new_mapped.push(
            <p
              className={
                this.props.selected.indexOf(SIZE_ENUM[num].id) === -1
                  ? "size_option unselected"
                  : "size_option selected"
              }
              key={`${key}_${num}`}
              onClick={() =>
                this.props.handleSelect(SIZE_ENUM[num], this.props.content)
              }
            >
              {SIZE_ENUM[num].text}
            </p>
          );
        });
        mapped.push(
          <div key={key + "_section"} className="size_section">
            {new_mapped}
          </div>
        );
        new_mapped = [];
      }
    }

    let selected = this.props.selected.map(num => {
      let list = this.props.content === "size" ? SIZE_ENUM : CATEGORY_ENUM;
      return list[num].text;
    });

    if (selected.length === 0) {
      selected = this.props.content === "size" ? "All Sizes" : "All Categories";
    } else {
      selected = selected.join(", ");
    }
    let cssClass = this.state.opened ? "filter_content" : "hide_content";

    return (
      <div className="select_component" ref={node => (this.node = node)}>
        <div className="filter_mimic" onClick={this.onOpen}>
          <p className="mimic_text">{selected}</p>
        </div>
        <div className={cssClass}>{mapped}</div>
      </div>
    );
  }
}

export default Select;
