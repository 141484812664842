import React from "react";
import "./Comments.scss";
import { Link } from "react-router-dom";

class CommentReplies extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let reply = this.props.commentReply;
    return (
      <div
        id={"replyContainer_" + reply.uuid}
        className="cmt_msgContainer replySection"
      >
        <div className="userImage">
          <Link to={"/user/" + reply.commenter.handle}>
            <img alt="" src={reply.commenter.profilePic} />
          </Link>
        </div>
        <div className="cmt_msgBox">
          <div className="cmt_msg cmt_msgReply">
            <p className="lineWithoutSpace">
              <span className="marginRightSmall">
                <Link to={"/user/" + reply.commenter.handle}>
                  {reply.commenter.handle}
                </Link>
              </span>
              {reply.comment}
            </p>
          </div>
          <div className="msgTime">
            <div className="time marginRightMedium">
              <h6>{this.props.daysAgoLabel}</h6>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CommentReplies;
