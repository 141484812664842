import React from "react";
import PageComponent from "../../components/PageComponent";
import MessageToList from "./MessageToList";

class MessageToPage extends PageComponent {
  render = () => {
    return <MessageToList />;
  };
}

export default MessageToPage;
