import AppService from "./AppService";

/**
 * The purpose of this class is to provide a way to manage
 * state when the data is tied directly to the URL Anchor.
 *
 * The url anchor is the content that follows the # symbol in the url.
 * This class syncs the anchorHash state with the url state to allow
 * dynamic updating when the url hash changes.
 *
 * This class provides one source of truth for the URL.
 */
class URLAnchorServiceClass extends AppService {
  startingState() {
    return {
      ...super.startingState(),
      ...{
        anchorHash: URLAnchorServiceClass.extractHash(window.location.hash)
      }
    };
  }

  static extractHash(hash) {
    let anchorHash = {};
    if (hash == "" || hash == "#") {
      return anchorHash;
    }
    let hashPairs = hash.substr(1).split("&");
    for (let pair of hashPairs) {
      let pairValues = pair.split("=");
      anchorHash[decodeURIComponent(pairValues[0])] = decodeURIComponent(
        pairValues[1]
      );
    }
    return anchorHash;
  }

  static resetURL() {
    window.history.pushState(
      "",
      window.document.title,
      window.location.pathname + window.location.search
    );
  }

  getHashValue(key, defaultValue) {
    if (this.state.anchorHash[key] !== undefined) {
      return this.state.anchorHash[key];
    } else {
      return defaultValue;
    }
  }

  getHashArray(key, defaultValue) {
    let arrayString = this.state.anchorHash[key];
    if (arrayString !== undefined && arrayString.length) {
      return arrayString.split(",");
    } else {
      return defaultValue;
    }
  }

  saveHashToURL(anchorHash) {
    let hashPairs = [];
    let count = 0;
    for (let key in anchorHash) {
      count++;
      hashPairs.push(
        encodeURIComponent(key) + "=" + encodeURIComponent(anchorHash[key])
      );
    }
    if (count == 0) {
      URLAnchorServiceClass.resetURL();
    } else {
      window.location.hash = hashPairs.join("&");
    }
  }

  saveNewAnchorHash(anchorHash) {
    this.setState({
      anchorHash
    });
    this.saveHashToURL(anchorHash);
  }

  updateKeyValues(updates) {
    let anchorHash = { ...this.state.anchorHash };
    for (let update of updates) {
      switch (update.action) {
        case "set":
          if (update.value instanceof Array) {
            update.value = update.value.join(",");
          }
          anchorHash[update.key] = update.value;
          break;
        case "remove":
          delete anchorHash[update.key];
          break;
      }
    }

    this.saveNewAnchorHash(anchorHash);

    this.notifyEventListeners({
      action: "update URLAnchor"
    });
  }

  setKeyValue(key, value) {
    let anchorHash = { ...this.state.anchorHash };
    let hasUpdated = anchorHash[key] !== undefined;
    if (value instanceof Array) {
      value = value.join(",");
    }
    anchorHash[key] = value;
    this.saveNewAnchorHash(anchorHash);

    this.notifyEventListeners({
      action: "set URLAnchor",
      key,
      value,
      hasUpdated
    });
  }

  removeKeyValue(key) {
    let anchorHash = { ...this.state.anchorHash };
    let hasUpdated = anchorHash[key] !== undefined;

    delete anchorHash[key];
    this.saveNewAnchorHash(anchorHash);

    this.notifyEventListeners({
      action: "remove URLAnchor",
      key,
      hasUpdated
    });
  }

  clearHash() {
    this.saveNewAnchorHash({});

    this.notifyEventListeners({
      action: "clear URLAnchor"
    });
  }
}

const URLAnchorService = new URLAnchorServiceClass();

export default URLAnchorService;
