import React from "react";
import PageComponent from "../../components/PageComponent";
import ReservationCard from "./ReservationCard.js";
import LoadingScreen from "../../components/LoadingScreen";
import ReserveService from "../../services/ReserveService";
import "./ReservationPage.scss";
import { request } from "../../lib/library";

class ReservationPage extends PageComponent {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      sellers: [],
      type: "buy"
    };
  }

  componentDidMount = () => {
    super.componentDidMount();
    ReserveService.addEventListener(this);
    this.fetchData();
  };

  componentWillUnmount = () => {
    ReserveService.removeEventListener(this);
  };

  onNotification = notification => {
    if (notification.action === "cancel") {
      this.removeItem(notification.itemID);
    } else if (notification.action === "mark") {
      this.markSold(notification.sellerID);
    }
  };

  markSold(sellerID) {
    let sellers = [...this.state.sellers];
    for (let seller of sellers) {
      if (seller.uuid === sellerID) {
        for (let item of seller.items) {
          item.status = 2;
        }
      }
    }
    this.setState({
      sellers
    });
  }

  removeItem(itemID) {
    let sellers = [...this.state.sellers];
    for (let seller of sellers) {
      for (let i = 0; i < seller.items.length; i++) {
        if (seller.items[i].pk === `item_${itemID}`) {
          seller.items.splice(i, 1);
        }
      }
    }
    sellers = sellers.filter(s => {
      return s.items.length > 0;
    });
    this.setState({
      sellers
    });
  }

  fetchData = async (type = "buy") => {
    this.setState({
      type,
      loaded: false
    });

    const response = await request("get", "/user/reservations", true, {
      status: 1,
      type
    });

    if (!response.success) {
      return;
    }

    let sellers = [];
    let sellerMap = response.users;

    for (const item of response.items) {
      const sellerID =
        type == "buy"
          ? item.gsi_2_pk.replace("seller_", "")
          : item.gsi_3_pk.replace("user_", "");

      if (sellerMap[sellerID].items) {
        sellerMap[sellerID].items.push(item);
      } else {
        sellerMap[sellerID].items = [item];
        sellers.push(sellerMap[sellerID]);
      }

      //   if (sellerMap[sellerID].items) {
      //     sellerMap[sellerID].items.push(item);
      //   } else {
      //     sellerMap[sellerID] = {
      //       items: []
      //     };
      //     sellerMap[sellerID].items = [item];
      //     sellers.push(sellerMap[sellerID]);
      //   }
    }

    this.setState({
      loaded: true,
      sellers
    });
  };

  switchToBuying = () => {
    this.fetchData("buy");
  };

  switchToSelling = () => {
    this.fetchData("sell");
  };

  renderContent() {
    const mapped = this.state.sellers.map((seller, index) => (
      <ReservationCard key={index} seller={seller} type={this.state.type}>
        {index === 0 ? (
          ""
        ) : (
          <div className="separatorLine marginBottomLarge"></div>
        )}
      </ReservationCard>
    ));
    if (!this.state.loaded) {
      return <LoadingScreen />;
    } else if (this.state.sellers.length === 0) {
      return (
        <div className="loadingContainer">
          <p className="rippleStatusContainer textCenter label">
            You currently have no reservations.
          </p>
        </div>
      );
    } else {
      return <div className="container">{mapped}</div>;
    }
  }

  render() {
    return (
      <div className="container textCenter dynamicHeaderSpacingLarge">
        <div className="wrap splitView marginBottomLarge">
          <label className="label">
            <input
              onClick={this.switchToBuying}
              type="radio"
              name="radioBtn"
              defaultChecked={this.state.type === "buy"}
            />
            <div className="bg"></div>
            <span className="label">Buying</span>
          </label>
          <label>
            <input
              onClick={this.switchToSelling}
              type="radio"
              name="radioBtn"
              // checked={this.state.type == "sell"}
            />
            <div className="bg"></div>

            <span className="label">Selling</span>
          </label>
        </div>
        {this.renderContent()}
      </div>
    );
  }
}

export default ReservationPage;
