import AppService from "./AppService";
import { isMobileDevice } from "../lib/library";

class HeaderServiceClass extends AppService {
  startingState() {
    return {
      ...super.startingState(),
      ...{
        transparentMode: false,
        showAlerts: false,
        showProfilePopup: false
      }
    };
  }

  showTransparentMode = () => {
    this.setState({
      transparentMode: true
    });
  };

  showNormalMode = () => {
    this.setState({
      transparentMode: false
    });
  };

  toggleAlerts = () => {
    if (!isMobileDevice()) {
      this.setState({
        showAlerts: !this.state.showAlerts,
        showProfilePopup: false
      });
    }
  };

  toggleProfilePopup = () => {
    this.setState({
      showAlerts: false,
      showProfilePopup: !this.state.showProfilePopup
    });
  };

  toggleOffAll = () => {
    if (this.state.showAlerts || this.state.showProfilePopup) {
      this.setState({
        showAlerts: false,
        showProfilePopup: false
      });
    }
  };
}

const HeaderService = new HeaderServiceClass();

export default HeaderService;
