import React from "react";
import PageComponent from "../components/PageComponent";
import styles from "./LogOutPage.module.scss";
import SectionHeader from "../components/header/SectionHeader";

class LogOutPage extends PageComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="centerSection pageSection">
        <div className="marginBottomXLarge"></div>
        <SectionHeader
          title="You have successfully logged out."
          subtitle={
            <p className={styles.subtitle + " label"}>Please come back soon!</p>
          }
          spacing="marginBottomXLarge"
        />
      </div>
    );
  }
}

export default LogOutPage;
