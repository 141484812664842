import React, { Component } from "react";
import Alert from "../../pages/alerts/Alert";
import "./Header.scss";
import { request, isMobileDevice } from "../../lib/library";
import WebsocketManager from "../../lib/websocketManager";

const OFFSET_TO_LOAD_MORE_MESSAGES = 50;

class AlertPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.loading = true;
    const response = await request("get", "/alerts", true, {});
    this.loading = false;

    if (!response.success) {
      // Could not load messages at this time.
      this.setState({
        loaded: "Failed to load your alerts",
        items: []
      });
    } else {
      this.setState({
        loaded: response.items.length === 0 ? "You have no alerts" : true,
        items: response.items,
        LastEvaluatedKey: response.LastEvaluatedKey
      });
      WebsocketManager.mimicWebsocketOnMessage({
        action: "userStateUpdate",
        propertyName: "numAlerts",
        newValue: 0
      });
    }
  };

  loadMoreData = async () => {
    if (this.state.LastEvaluatedKey !== false && !this.fetchingMore) {
      this.fetchingMore = true;

      const response = await request("get", "/alerts", true, {
        pk: this.state.LastEvaluatedKey.pk,
        sort: this.state.LastEvaluatedKey.sort
      });

      if (!response.success) {
        // Could not load messages at this time.
      } else {
        this.setState({
          items: [...this.state.items, ...response.items],
          LastEvaluatedKey: response.LastEvaluatedKey
        });
      }

      this.fetchingMore = false;
    }
  };

  handleOnScroll = async evt => {
    let scrollable = evt.target;
    if (
      scrollable.scrollTop + scrollable.clientHeight >=
      scrollable.scrollHeight - OFFSET_TO_LOAD_MORE_MESSAGES
    ) {
      this.loadMoreData();
    }
  };

  static calculatePopupWidth() {
    let body = document.body;
    if (body.clientWidth > 470) {
      return 420;
    } else {
      return body.clientWidth - 50;
    }
  }

  renderAlertsContent() {
    if (this.state.loaded === true) {
      let mapped = this.state.items.map(item => {
        return <Alert key={item.sort} alert={item} />;
      });
      return <div className="alertsContainer">{mapped}</div>;
    } else if (this.state.loaded) {
      return <div className="alertsMessageContainer">{this.state.loaded}</div>;
    } else {
      return (
        <div className="alertsMessageContainer">
          <div className="loadingGif center" />
        </div>
      );
    }
  }

  render() {
    return (
      <div
        style={{ width: AlertPopup.calculatePopupWidth() }}
        className="dropdown__content"
        onMouseUp={e => {
          e.stopPropagation();
        }}
      >
        <div className="dropdown__filler" />
        <div className="dropdown__linkContainer" onScroll={this.handleOnScroll}>
          {this.renderAlertsContent()}
        </div>
      </div>
    );
  }
}

export default AlertPopup;
