// Prod

export default {
  s3: {
    REGION: "us-west-2",
    BUCKET: "nextbest"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://tsspt4tuij.execute-api.us-west-2.amazonaws.com/prod/v1_0",
    USERS_URL:
      "https://ra73k55q2b.execute-api.us-west-2.amazonaws.com/produsers/v1_0"
  },
  WEBSOCKET_SERVER:
    "wss://6aytvxfghg.execute-api.us-west-2.amazonaws.com/prodws",
  GOOGLE_API_KEY: "AIzaSyA3HVOZcHl15KVDdFdHgtypZN4iWV6gilU"
};

// Hoax2

// export default {
//   s3: {
//     REGION: "us-west-2",
//     BUCKET: "nextbest-s3-hoa2"
//   },
//   apiGateway: {
//     REGION: "us-west-2",
//     // URL: "http://localhost:3001/v1_0",
//     URL: "https://3zioji3pvj.execute-api.us-west-2.amazonaws.com/hoax2/v1_0",
//     USERS_URL:
//       "https://41lan5glz0.execute-api.us-west-2.amazonaws.com/hoausers2/v1_0"
//     //USERS_URL: "http://localhost:3001/v1_0"
//   },
//   WEBSOCKET_SERVER:
//     "wss://1ue7b154i6.execute-api.us-west-2.amazonaws.com/hoaws2",
//   GOOGLE_API_KEY: "AIzaSyBqtb_XgSCbzSTx0WKNemq6TQSy4ebgfXY"
// };

// Hoax

// export default {
//   s3: {
//     REGION: "us-west-2",
//     BUCKET: "nextbest"
//   },
//   apiGateway: {
//     REGION: "us-west-2",
//     URL: "https://fw86kcn0tk.execute-api.us-west-2.amazonaws.com/hoax/v1_0",
//     // URL: "http://localhost:3001/v1_0",
//     USERS_URL:
//       "https://04rdjvsioc.execute-api.us-west-2.amazonaws.com/hoausers/v1_0"
//   },
//   WEBSOCKET_SERVER:
//     "wss://q5lp5p8d4d.execute-api.us-west-2.amazonaws.com/hoaws",
//   GOOGLE_API_KEY: "AIzaSyBqtb_XgSCbzSTx0WKNemq6TQSy4ebgfXY"
// };

// export default {
//   s3: {
//     REGION: "us-west-2",
//     BUCKET: "nextbest"
//   },
//   apiGateway: {
//     REGION: "us-west-2",
//     URL: "http://localhost:3001/v1_0",
//     USERS_URL:
//       "http://localhost:3001/v1_0"
//   },
//   WEBSOCKET_SERVER: "ws://localhost:3001",
//   GOOGLE_API_KEY: "AIzaSyBqtb_XgSCbzSTx0WKNemq6TQSy4ebgfXY"
// };

// https://fw86kcn0tk.execute-api.us-west-2.amazonaws.com/hoax/v1_0
// https://tsspt4tuij.execute-api.us-west-2.amazonaws.com/prod/v1_0
// https://tkg27mkmuc.execute-api.us-west-2.amazonaws.com/lucy/v1_0
// prod  GCLOUD_API_KEY: "AIzaSyA3HVOZcHl15KVDdFdHgtypZN4iWV6gilU",
//   GCLOUD_API_KEY: "AIzaSyBqtb_XgSCbzSTx0WKNemq6TQSy4ebgfXY",
