import React, { Component } from "react";
import "../styles/Modal.scss";
import GoogleLogin from "react-google-login";
import AccountService from "../services/AccountService";

class AccountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.show === "logging in") {
      return (
        <div className="modalContainer">
          <div className="modalBackdrop"></div>
          <div className="modalForeground">
            <h2 className="centerText modalTitle">Logging you in.</h2>
          </div>
        </div>
      );
    } else if (this.props.show) {
      return (
        <div className="modalContainer">
          <div
            onClick={AccountService.handleAccountModalExit}
            className="modalBackdrop"
          ></div>
          <div className="modalForeground">
            <h2 className="centerText modalTitle">
              Please log in to continue.
            </h2>
            <br></br>
            <div className="center googleLoginContainer">
              <GoogleLogin
                clientId="153417564665-g42mcarkumsrsfrd6kl1l8a0uh17p936.apps.googleusercontent.com"
                buttonText="Enter With Google"
                onSuccess={AccountService.handleSignInSuccess}
                onFailure={AccountService.handleSignInFailure}
                cookiePolicy={"single_host_origin"}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default AccountModal;
