import React from "react";
import PageComponent from "../components/PageComponent";
import LoadingScreen from "../components/LoadingScreen";
import ImageGallery from "react-image-gallery";
import ReserveService from "../services/ReserveService";
import WatchService from "../services/WatchService";
import AccountService from "../services/AccountService";
import { Link } from "react-router-dom";
import { SIZE_ENUM, ITEM_STATUS_ENUM } from "../lib/itemEnums";
import {
  request,
  daysAgoLabel,
  getStatus,
  createImageURL
} from "../lib/library";
import "./ProductPage.scss";
import Comments from "./comments/Comments";
import ErrorPageMessage from "../components/ErrorPageMessage";

class ProductPage extends PageComponent {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      watched: false
    };
    this.fetchData();
  }

  componentDidMount() {
    super.componentDidMount();
    ReserveService.addEventListener(this);
  }

  componentWillUnmount() {
    ReserveService.removeEventListener(this);
  }

  onNotification = notification => {
    if (notification.action === "reserve") {
      let item = { ...this.state.item };
      item.status = 2;
      this.setState({
        item
      });
    }
  };

  fetchData = async () => {
    const id = this.props.itemID;
    const response = await request(
      "get",
      `/item/${id}`,
      AccountService.isLoggedIn
    );
    let diffs = {
      loaded: true
    };
    if (!response.success) {
      diffs.message = "Sorry, this item no longer exists";
    } else {
      diffs.item = response.item;
      diffs.watched = response.item.isWatching;
      diffs.comments = response.comments;
    }
    this.setState(diffs);
  };

  toggleWatch = item => {
    if (this.state.watched) {
      WatchService.unwatch(item.itemID);
      item.numWatching--;
      this.setState({
        watched: false,
        item
      });
    } else {
      item.numWatching++;
      WatchService.watch(item.itemID);
      this.setState({
        watched: true,
        item
      });
    }
  };

  render() {
    if (!this.state.loaded) {
      return <LoadingScreen />;
    } else if (this.state.message) {
      return <ErrorPageMessage message={this.state.message} />;
    } else {
      const { item, comments } = this.state;

      let statusSection = null;
      if (item.status !== 1) {
        statusSection = (
          <div className="absolute productPageStatusBar">
            <div className="statusBar">{getStatus(item)}</div>
          </div>
        );
      }

      let watchClass = this.state.watched
        ? "itemIcon watchOpenIcon"
        : "itemIcon watchCloseIcon";
      let watchMessage = this.state.watched ? "Watching" : "Watch Item";
      let watchButton = (
        <a>
          <div className="tooltip">
            <div
              onClick={() => this.toggleWatch(item)}
              className={watchClass}
              alt="Watch Item"
            />
            <span className="tooltiptext tooltip-bottom">{watchMessage}</span>
          </div>
        </a>
      );

      if (AccountService.userID === item.seller.uuid) {
        watchButton = (
          <a>
            <div className="tooltip">
              <div
                className="itemIcon watchOpenIcon disabledIcon"
                alt="Watch Item"
              />
              <span className="tooltiptext tooltip-bottom">Watch Item</span>
            </div>
          </a>
        );
      }

      const images = item.images.map(img => {
        return {
          original: createImageURL(img),
          thumbnail: createImageURL(img),
          originalClass: "product__image"
        };
      });

      let mappedComments;
      if (comments.length === 0) {
        mappedComments = <p> Have a question? Comments are coming soon!</p>;
      } else {
        mappedComments = comments.map(comment => (
          <div className="comment">{comment}</div>
        ));
      }

      let commentsBox = null;
      if (item.status !== ITEM_STATUS_ENUM.SOLD.id) {
        commentsBox = <Comments itemID={this.props.itemID} />;
      }

      this.reserveButton = null;
      if (AccountService.userID === item.seller.uuid) {
        this.reserveButton = (
          <div className="textCenter">
            <Link to={`/edit/item/${item.itemID}`}>
              <button className="primaryButton smallButton">
                <span>Edit Item</span>
              </button>
            </Link>
          </div>
        );
      } else if (item.status === ITEM_STATUS_ENUM.AVAILABLE.id) {
        this.reserveButton = (
          <div className="textCenter">
            <button
              onClick={() => {
                ReserveService.handleReserve(item);
              }}
              className="secondaryButton smallButton"
            >
              <span>Reserve</span>
            </button>
          </div>
        );
      }

      let rightBarContent = (
        <>
          <div className="productLongView">
            <div className="table product__description">
              <div className="tr">
                <div className="td seller__pic">
                  <Link to={"/user/" + item.seller.handle}>
                    <img alt="" src={item.seller.profilePic} className="av" />
                  </Link>
                </div>
                <div className="td vertMiddle">
                  <h5>
                    <Link
                      className="textLink"
                      to={"/user/" + item.seller.handle}
                    >
                      <span className="handle">{item.seller.handle}</span>
                    </Link>
                  </h5>
                  <h4 className="blueText">{item.locationLabel}</h4>
                </div>
              </div>
            </div>
            <div className="marginTopSmall flexBox">
              <div className="shippingOptionIcon locationIcon marginRightXSmall" />
              <h5 className="">Pick up available</h5>
            </div>
            {item.ships ? (
              <div className="flexBox">
                <div className="shippingOptionIcon shippingIcon marginRightXSmall" />
                <h5 className="">This seller also ships</h5>
              </div>
            ) : null}
          </div>

          <div className="productInfo">
            <h5 className="marginTopLarge">
              {item.description}{" "}
              <span className="product__date">
                {daysAgoLabel(item.listDate)}
              </span>
            </h5>
            <h3 className="marginTopMedium">
              ${item.sellersPrice}{" "}
              <span className="barelyNoticableText">|</span> Size:{" "}
              {SIZE_ENUM[item.size].text}
            </h3>
          </div>

          {/* <div className="comments">{mappedComments}</div> */}

          <div className="left marginTopLarge">
            {this.reserveButton}
            <div
              className={
                item.status == ITEM_STATUS_ENUM.AVAILABLE.id ||
                AccountService.userID === item.seller.uuid
                  ? "marginLeftMedium"
                  : ""
              }
            >
              {watchButton}
            </div>
            <h4>{item.numWatching}</h4>
          </div>
        </>
      );

      return (
        <div className="container dynamicHeaderSpacing">
          {/* <div className="product__seller">
            <div className="seller__pic">
              <Link to={"/user/" + item.seller.handle}>
                <img alt="" src={item.seller.profilePic} className="av" />
              </Link>
            </div>
            <div className="right__bar">
              <div className="table product__description">
                <div className="tr">
                  <div className="td">
                    <Link to={"/user/" + item.seller.handle}>
                      <span className="handle">{item.seller.handle}</span>
                    </Link>
                    {item.description}{" "}
                    <span className="product__date">
                      {daysAgoLabel(item.listDate)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="product__card">
            <div className="productImageGalleryContainer">
              <ImageGallery
                items={images}
                showThumbnails={false}
                // thumbnailPosition="right"
                additionalClass="productImageGallery"
                showFullscreenButton={false}
                showPlayButton={false}
                showBullets={true}
                disableThumbnailScroll={true}
              />
              {statusSection}
              <div className="rightBarSmallScreen">
                {rightBarContent}
                <hr className="marginTopLarge" />
              </div>
              <div className="comments">{commentsBox}</div>
            </div>
            <div className="product__info">
              <div className="right__bar marginTopXLarge">
                {rightBarContent}
                {/* <div className="marginTopXLarge">{this.reserveButton}</div> */}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ProductPage;
