import React from "react";
import { Link } from "react-router-dom";
import { createImageURL, getReservationStatus } from "../../lib/library";
import PopupService from "../../services/PopupService";

import "./ReservedItem.scss";
import AccountService from "../../services/AccountService";

class ReservedItem extends React.Component {
  constructor() {
    super();
    this.state = {
      canceling: false
    };
  }

  renderCancelButton(item, reserveDate) {
    let itemID = item.pk.replace("item_", "");
    const cancelHandler = async () => {
      let data = [itemID, reserveDate];
      PopupService.showCancelReserveModal(data);
    };

    if (this.state.canceling) {
      return <p className="bar__item cancel__reservation">Canceling...</p>;
    } else if (item.status == 1) {
      return (
        <button
          onClick={cancelHandler}
          className="bar__item cancel__reservation"
        >
          Cancel Reservation
        </button>
      );
    }
    return (
      <p className="bar__item cancel__reservation">Transaction Completed</p>
    );
  }

  render() {
    const item = this.props.item;
    let itemID = item.pk.replace("item_", "");
    let reserveDate = item.sort.replace("rsv_", "");
    return (
      <div className="entire__card">
        <div className="reserved__item">
          <Link to={"/item/" + itemID}>
            <img
              src={createImageURL(item.firstImage)}
              className="product"
              alt="The item you reserved"
            />
          </Link>
          <p className="status">{getReservationStatus(item)}</p>
        </div>
        <div className="lower__bar">
          {this.renderCancelButton(item, reserveDate)}
          <p className="bar__item">${item.sellersPrice}</p>
        </div>
      </div>
    );
  }
}

export default ReservedItem;
