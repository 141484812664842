import React, { Component } from "react";
import styles from "./LandingPage.module.scss";
import LocationInput from "./LocationInput";
import { constructLocation } from "../../lib/library";
import { Redirect } from "react-router-dom";
import AccountService from "../../services/AccountService";

class SplashLocationSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleUpdateLocation = (lat, lng) => {
    this.lat = lat;
    this.lng = lng;
  };

  handleEnterLocation = async (lat, lng, lastSelectedSuggestion) => {
    window.localStorage.setItem("lastLat", lat);
    window.localStorage.setItem("lastLng", lng);

    let location = await constructLocation(lat, lng, lastSelectedSuggestion);
    location.setDate = new Date() - 0;
    window.localStorage.setItem("shoppingLocation", JSON.stringify(location));
    this.setState({
      locationEntered: true
    });
    // The purpose of this state is to make sure the user returns
    // to the explore page if they click the nextbest logo on this tab.
    // If the user opens a new tab, they'll go back to the landing page.
    AccountService.setState({
      hasEnteredLocation: true
    });
    // window.location = `/shop#lat=${lat}&lng=${lng}`;
  };

  render() {
    if (this.state.locationEntered) {
      return <Redirect to="/shop" />;
    }

    return (
      <div className="container textCenter">
        <img
          className={styles.fullSplashImage + " fullWidth"}
          src="/photos/splash.JPG"
        />
        <div className={styles.floatingOnSplashImage + " textLeft"}>
          <h1 className={styles.gradientText}>Discover local styles!</h1>
          <br />
          <h3 className={styles.gradientText + " marginBottomLarge"}>
            Shop or sell apparel in your local community.
          </h3>
          <LocationInput
            showEnterLocation={true}
            onEnterLocation={this.handleEnterLocation}
            onUpdateLocation={this.handleUpdateLocation}
          />
        </div>
      </div>
    );
  }
}

export default SplashLocationSection;
