import React from "react";
import PageComponent from "../components/PageComponent";
import Feed from "../components/Feed";
import { request } from "../lib/library";

import "./reservation/ReservationPage.scss";
import SectionHeader from "../components/header/SectionHeader";

class WatchingPage extends PageComponent {
  constructor(props) {
    super(props);

    this.state = {
      loaded: true,
      sellers: []
    };
  }

  componentDidMount = () => {
    super.componentDidMount();
    // ReserveService.addEventListener(this);
    // this.fetchData();
  };

  //   componentWillUnmount = () => {
  //     ReserveService.removeEventListener(this);
  //   };

  //   onNotification = notification => {
  //     if (notification.action == "cancel") {
  //       this.removeItem(notification.itemID);
  //     } else if (notification.action == "mark") {
  //       this.markSold(notification.sellerID);
  //     }
  //   };

  removeItem(itemID) {
    let sellers = [...this.state.sellers];
    for (let seller of sellers) {
      for (let i = 0; i < seller.items.length; i++) {
        if (seller.items[i].pk === `item_${itemID}`) {
          seller.items.splice(i, 1);
        }
      }
    }
    sellers = sellers.filter(s => {
      return s.items.length > 0;
    });
    this.setState({
      sellers
    });
  }

  fetchData = async () => {
    this.setState({
      loaded: false
    });

    const response = await request("get", "/user/watching", true);
    if (!response.success) {
      return;
    }

    let sellers = [];
    let sellerMap = response.users;

    for (const item of response.items) {
      const sellerID = item.gsi_2_pk.replace("seller_", "");
      if (sellerMap[sellerID] && sellerMap[sellerID].items.length === 0) {
        sellerMap[sellerID].items.push(item);
        sellers.push(sellerMap[sellerID]);
      } else if (sellerMap[sellerID]) {
        sellerMap[sellerID].items.push(item);
      }
    }

    this.setState({
      loaded: true,
      sellers
    });
  };

  // renderContent() {
  //   const mapped = this.state.sellers.map(seller => (
  //     <ReservationCard
  //       key={seller.uuid}
  //       seller={seller}
  //       type={this.state.type}
  //     />
  //   ));
  //   if (!this.state.loaded) {
  //     return <LoadingScreen />;
  //   } else if (this.state.sellers.length == 0) {
  //     return (
  //       <div className="loadingContainer">
  //         <p className="rippleStatusContainer textCenter">
  //           There are currently no watched items.
  //         </p>
  //       </div>
  //     );
  //   } else {
  //     return <Feed type="watch" selectID="1" />;
  //   }
  // }

  render() {
    return (
      <div className="container dynamicHeaderSpacingLarge">
        <SectionHeader
          title="Watched Items"
          subtitle="You will be notified when the prices of these items drop."
        />
        <div className="separatorLine marginTopMedium marginBottomMedium"></div>
        <Feed type="watch" selectID="1" />
      </div>
    );
  }
}

export default WatchingPage;
