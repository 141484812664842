import React, { Component } from "react";

class StatusButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let buttonContent;
    if (this.props.loading) {
      buttonContent = <div className="loadingGif center" />;
    } else {
      buttonContent = <span>{this.props.label}</span>;
    }

    return (
      <button
        className={this.props.className}
        onClick={this.props.onClick}
        id={this.props.id}
        disabled={this.props.disabled}
      >
        {buttonContent}
      </button>
    );
  }
}

export default StatusButton;
