import React from "react";
import "./ChatPage.scss";
import "./MessageChatCircles";
import MessageService from "../../services/MessageService";
import ConversationBody from "./ConversationBody";
import ConversationFooter from "./ConversationFooter";
import ConversationHandlerComponent from "./ConversationHandlerComponent";
import ConversationHeader from "./ConversationHeader";

let BASE_FOOTER_HEIGHT = 47;

class MessageCircleConversation extends ConversationHandlerComponent {
  componentWillUpdate = () => {
    if (this.props.conversation.opened) {
      // Create a resize service that broadcasts events to listener
      // if resize is needed again.
      window.onresize = this.adjustScrollableArea;
    }
  };

  adjustScrollableArea = () => {
    let footer = document.getElementById(
      "messageConversationFooter_" + this.props.conversation.userID
    );
    if (footer) {
      let footerHeight = footer.clientHeight;
      let bodyHeight = document.getElementById(
        "messageConversationBody_" + this.props.conversation.userID
      ).clientHeight;
      document.getElementById(
        "chatLog_" + this.props.conversation.userID
      ).style.height =
        bodyHeight +
        BASE_FOOTER_HEIGHT -
        this.getReservedItemsHeight() -
        footerHeight +
        "px";
    }
  };

  getReservedItemsHeight = () => {
    let reservedItemsHeight = document.getElementById(
      "reservedItems_" + this.props.conversation.userID
    );
    return reservedItemsHeight ? reservedItemsHeight.clientHeight : 0;
  };

  renderConversationHeader() {
    return <ConversationHeader conversation={this.props.conversation} />;
  }

  renderConversationBody() {
    return (
      <ConversationBody
        conversation={this.props.conversation}
        messages={this.state.messages}
        isTyping={this.state.isTyping}
        onScrollToTheTop={this.handleScrollToTheTop}
      />
    );
  }

  renderConversationFooter() {
    return (
      <ConversationFooter
        conversation={this.props.conversation}
        handleTyping={this.handleTyping}
        textContent={this.state.textContent}
        onSubmitMessage={this.handleSubmitMessage}
      />
    );
  }

  render() {
    if (this.props.conversation.opened) {
      return (
        <div
          onClick={() => {
            MessageService.notifyRead(this.props.conversation, true);
          }}
          className="messageConversationTab messageFooterItem"
        >
          {this.renderConversationHeader()}
          {this.renderConversationBody()}
          {this.renderConversationFooter()}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default MessageCircleConversation;
