import React, { Component } from "react";

class LoadingScreen extends Component {
  render() {
    return (
      <div className="container">
        <div className="loadingContainer">
          <p className="rippleStatusContainer textCenter">
            <img src="/ripple.svg" />
          </p>
        </div>
      </div>
    );
  }
}

export default LoadingScreen;
