import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { isMobileDevice } from "../../lib/library";
import TabBarService from "../../services/TabBarService";
import styles from "./TabBar.module.scss";
import AccountService from "../../services/AccountService";

class TabBar extends Component {
  constructor(props) {
    super(props);
    TabBarService.attachParent(this);
    this.state = {
      ...{},
      ...TabBarService.startingState()
    };
  }

  render() {
    if (
      !AccountService.isLoggedIn ||
      !isMobileDevice() ||
      TabBarService.shouldHide()
    ) {
      return null;
    }

    let userState = AccountService.state.userState;
    let messagesBadge = null;
    let reservationBadge = null;
    let totalReservations =
      (userState.numSellingReservations || 0) +
      (userState.numBuyingReservations || 0);
    if (totalReservations > 0) {
      reservationBadge = (
        <div className={styles.tabBarBadge + " headerBadge alertsBadge"}>
          {totalReservations}
        </div>
      );
    }

    return (
      <>
        <div className={styles.tabBarFiller} />
        <div id={styles.tabBarContainer}>
          <div className={styles.tabIconsGroup + " icon_group"}>
            <div className={styles.tabBarButtonContainer}>
              <NavLink exact to="/" activeClassName={styles.activeTabBarButton}>
                <img alt="" className={styles.tabBarIcon} src="/home.svg" />
              </NavLink>
            </div>
            <div className={styles.tabBarButtonContainer}>
              <NavLink
                to="/reserved"
                activeClassName={styles.activeTabBarButton}
              >
                <img alt="" className={styles.tabBarIcon} src="/reserve.svg" />
                <div className="headerBadgeContainer">{reservationBadge}</div>
              </NavLink>
            </div>
            <div className={styles.tabBarButtonContainer}>
              <NavLink to="/sell" activeClassName={styles.activeTabBarButton}>
                <img alt="" className={styles.tabBarIcon} src="/list.svg" />
              </NavLink>
            </div>
            <div className={styles.tabBarButtonContainer}>
              <NavLink
                to="/messages"
                activeClassName={styles.activeTabBarButton}
              >
                <img alt="" className={styles.tabBarIcon} src="/chat.svg" />
                <div className="headerBadgeContainer">{messagesBadge}</div>
              </NavLink>
            </div>
            <div className={styles.tabBarButtonContainer}>
              <NavLink
                to="/settings"
                activeClassName={styles.activeTabBarButton}
              >
                <img
                  alt=""
                  className={styles.tabBarIcon}
                  src="/hamburger.svg"
                />
              </NavLink>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TabBar;
