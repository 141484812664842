import AppService from "./AppService";

class TabBarService extends AppService {
  startingState() {
    return { ...super.startingState(), ...{ hideFooterTab: false } };
  }

  hide = () => {
    this.setState({
      hideFooterTab: true
    });
  };

  show = () => {
    this.setState({
      hideFooterTab: false
    });
  };

  shouldHide = () => {
    return this.state.hideFooterTab;
  };
}

const tabBarService = new TabBarService();
//exporting singleton
export default tabBarService;
