import React from "react";
import { requestUser } from "../lib/library.js";
import Modal from "./Modal.js";
import "../styles/Modal.scss";

const AVAILABLE_LABEL = "handle available";
const TAKEN_LABEL = "handle taken";

class OnboardModal extends Modal {
  constructor(props) {
    super(props);
    this.state = {
      message: "Please provide a handle",
      creating: false
    };
  }

  componentDidMount = () => {
    let input;
    if (
      !this.props.isOnJumpInScreen &&
      input === document.getElementById("handleInput")
    ) {
      input.focus();
    }
  };

  handleHandleChanged = async e => {
    let handle = e.target.value;
    handle = handle.toLowerCase();
    handle = handle.replace(/[^a-zA-Z0-9_.]/g, "");
    if (handle !== e.target.value) {
      document.getElementById("handleInput").value = handle;
    }
    if (handle.length < 3) {
      this.setState({
        message: "Handle must be longer than 3 characters"
      });
    } else {
      this.setState({
        message: "loading"
      });
      try {
        const result = await requestUser("put", "/user/", false, {
          action: "check",
          handle
        });
        if (result.handle === document.getElementById("handleInput").value) {
          if (result.available) {
            this.setState({
              message: AVAILABLE_LABEL
            });
          } else {
            this.setState({
              message: TAKEN_LABEL
            });
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  handleCreateAccount = async () => {
    try {
      this.setState({
        creating: true
      });

      const result = await requestUser("put", "/user/", true, {
        action: "onboard",
        handle: document.getElementById("handleInput").value
      });
      if (result.success) {
        this.props.onSuccessfulOnboard(result.handle, result.termsDate);
      } else {
        this.setState({
          message: result.message,
          creating: false
        });
      }
    } catch (e) {
      this.setState({
        message: "Something went wrong",
        creating: false
      });
      console.error(e);
    }
  };

  handleLookForEnter = event => {
    if (event.key === "Enter" && !this.createButtonDisabled) {
      this.handleCreateAccount();
    }
  };

  statusMessage = () => {
    if (this.state.message === "loading") {
      return (
        <p className="rippleStatusContainer center">
          <img alt="" src="/ripple.svg" />
        </p>
      );
    } else {
      let textColor = "";
      if (this.state.message === TAKEN_LABEL) {
        textColor = "errorText";
      } else if (this.state.message === AVAILABLE_LABEL) {
        textColor = "successText";
      }
      return (
        <p className={"textFieldStatusLabel center " + textColor}>
          {this.state.message}
        </p>
      );
    }
  };

  get createButtonDisabled() {
    return (
      (this.state.message !== AVAILABLE_LABEL &&
        this.state.message !== "loading") ||
      this.state.creating
    );
  }

  renderCreateButtonContent = () => {
    if (this.state.creating) {
      return <div className="whiteLoadingGif" />;
    }
    return <span>Create Account</span>;
  };

  renderContent() {
    if (this.props.isOnJumpInScreen) {
      return (
        <div className="modalForeground">
          <h2 className="textCenter modalTitle">
            Your account has been created!
          </h2>
          <div className="center textCenter">
            <h4>Next steps</h4>

            <ol className="stepsList">
              <li>Explore new styles and apparel in your local community</li>
              <li>Reserve the items you want to try on</li>
              <li>
                Schedule a meetup with the seller within 5 days of reserving.
              </li>
              <li>Pay for the items you want to keep.</li>
              <li>Sell clothes you don't wear anymore on our platform.</li>
            </ol>

            <div className="marginBottomMedium">
              <button
                className="primaryButton smallButton"
                onClick={this.props.onJumpIn}
              >
                <span>Jump In</span>
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="modalForeground">
          <h2 className="centerText modalTitle textCenter">
            Pick Your Username
          </h2>
          <div className="center textCenter">
            <span className="doubleDefaultFontSize">@&nbsp;</span>
            <input
              className="modalTextField mainFocusInput"
              id="handleInput"
              onKeyPress={this.handleLookForEnter}
              onChange={this.handleHandleChanged}
              type="text"
              maxLength="30"
              placeholder="Username"
            />
            <span className="textCenter">{this.statusMessage()}</span>
            <div className="fillerDiv"></div>
            <p>
              By creating an account, you certify that you have read and agreed
              to both our{" "}
              <a
                className="inTextLink"
                target="_blank"
                href="/reference/privacy.html"
              >
                privacy policy
              </a>{" "}
              and{" "}
              <a
                className="inTextLink"
                target="_blank"
                href="/reference/terms.html"
              >
                terms and conditions
              </a>{" "}
              agreements.
            </p>
            <br />

            <div className="marginBottomMedium">
              <button
                className="primaryButton smallButton"
                onClick={this.handleCreateAccount}
                disabled={this.createButtonDisabled}
              >
                {this.renderCreateButtonContent()}
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default OnboardModal;
