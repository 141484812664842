import React from "react";
import ReservedItem from "./ReservedItem.js";
import { Link } from "react-router-dom";
import "./ReservationCard.scss";
import MessageService from "../../services/MessageService.js";
import { isMobileDevice } from "../../lib/library.js";
import PopupService from "../../services/PopupService";

class ReservationCard extends React.Component {
  constructor() {
    super();
    this.state = {
      marking: false,
      markMessage: "MARK ITEM AS SOLD",
      isItemSold: false
    };
  }

  componentDidMount() {
    PopupService.addEventListener(this);
    if (this.props.seller.items.length >= 2) {
      this.setState({
        markMessage: "MARK ITEMS AS SOLD"
      });
    }
  }

  componentWillUnmount() {
    PopupService.removeEventListener(this);
  }

  onNotification = notification => {
    if (
      notification.action === "markItemSold" &&
      notification.handle === this.props.seller.handle
    ) {
      this.setState({
        markMessage: "SOLD",
        isItemSold: true
      });
    }
  };

  extentReservationTime = seller => {
    PopupService.showExtendReserveModal(seller);
  };

  getDaysLeft() {
    const date = Number.parseInt(
      this.props.seller.items[0].sort.replace("rsv_", "")
    );
    const today = Date.now();
    let remaining = 1000 * 60 * 60 * 24 * 5 + date - today;
    let daysRemaining = Math.round(remaining / (1000 * 60 * 60 * 24));
    return daysRemaining;
  }

  handleMark = async seller => {
    PopupService.showMarkAsSoldModal(seller);
  };

  renderMarkButtonContent() {
    if (this.props.type === "buy") {
      return <span>Waiting for Payment</span>;
    }
    if (this.state.markMessage !== "SOLD" && this.state.marking) {
      return <div className="whiteLoadingGif loadingGif" />;
    }
    return (
      <span>
        {/* MARK ITEM{this.props.seller.items.length === 1 ? " " : "s "}AS SOLD */}
        <span>{this.state.markMessage}</span>
      </span>
    );
  }

  goToChat = () => {
    const seller = this.props.seller;
    MessageService.addConversation(seller);
  };

  renderChatLink() {
    const seller = this.props.seller;
    if (isMobileDevice()) {
      return (
        <Link to={`/chat/${seller.uuid}`}>
          <div className="chatIcon itemIcon" />
        </Link>
      );
    } else {
      return (
        <button onClick={this.goToChat} className="tinyButton primaryButton">
          Message
        </button>
      );
    }
  }

  render() {
    const seller = this.props.seller;
    const mapped = seller.items.map((item, index) => (
      <ReservedItem key={index} item={item} />
    ));
    let totalPrice = 0;
    for (const item of seller.items) {
      totalPrice += item.sellersPrice;
    }

    return (
      <div className="reservation__card">
        {this.props.children}
        <div className="top__bar">
          <div className="user__bar">
            <img src={seller.profilePic} className="user__icon" alt="Profile" />
            <Link to={`/user/${seller.handle}`}>
              <h4 className="textLink marginRightSmall">{seller.handle}</h4>
            </Link>
            {this.renderChatLink()}
          </div>
          <div className="tooltip headerTooltip">
            <h3 className="time__left">{this.getDaysLeft()} days left</h3>
            <span className="tooltiptext tooltip-bottom">
              Days left to meet up
            </span>
          </div>
        </div>
        <div className="main">{mapped}</div>
        <div className="reserveBuyButton">
          <h2>
            Order total is <span>${totalPrice}</span>
          </h2>
          <br></br>

          <button
            className={
              this.props.type === "buy"
                ? "secondaryButton largeButton grayButton"
                : "" + "secondaryButton largeButton"
            }
            onClick={async () => {
              this.handleMark(seller);
            }}
            disabled={
              this.state.marking ||
              this.props.type === "buy" ||
              this.state.isItemSold
            }
          >
            {this.renderMarkButtonContent()}
          </button>
          <div
            className="textLink center extendReserveTime"
            onClick={() => {
              this.extentReservationTime(seller);
            }}
          >
            extend reservation time
          </div>
        </div>
        {/* <hr className="divider" /> */}
      </div>
    );
  }
}

export default ReservationCard;
