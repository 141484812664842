import React from "react";
import CommentMessage from "./CommentMessage";
import AccountService from "../../services/AccountService";
import { request, getQueryStringValue } from "../../lib/library";
import "./Comments.scss";

class Comments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
  }

  componentDidMount() {
    if (getQueryStringValue("focusComments") === "true") {
      document.getElementById("commentText").focus();
    }
    this.fetchData();
  }

  fetchData = async () => {
    let result = await request(
      "get",
      `/item/${this.props.itemID}/comments`,
      false,
      {
        type: "thread"
      }
    );
    if (!result.success) {
      this.setState({
        loading: false,
        message: "Could not load comments at this time."
      });
    } else {
      this.setState({
        items: result.items,
        LastEvaluatedKey: result.LastEvaluatedKey
      });
    }
  };

  removeDuplicate = list => {
    var uniq = {};
    var arrFiltered = list.filter(
      obj => !uniq[obj.sort] && (uniq[obj.sort] = true)
    );
    return arrFiltered;
  };

  saveComment = async () => {
    let commentText = document.getElementById("commentText").value;
    let comment = { type: "thread", comment: commentText };
    const result = await request(
      "post",
      `/item/${this.props.itemID}/comment`,
      true,
      comment
    );
    if (!result.success) {
      alert(result.message);
    } else {
      document.getElementById("commentText").value = "";
      let newItem = {
        ...result.item,
        commenter: AccountService.state.userState,
        commenterProfilePic: AccountService.state.userState
      };
      this.setState({
        items: [...this.state.items, newItem]
      });
      var myElement = document.getElementById(
        "commentContainer_" + newItem.uuid
      );
      var topPos = myElement.offsetTop;
      document.getElementById("commentsBox").scrollTop = topPos;
    }
  };

  renderComments() {
    let commentsThread = this.removeDuplicate(this.state.items);
    return commentsThread.map(item => (
      <CommentMessage
        key={item.uuid}
        commenter={item.commenter.handle}
        commenterProfilePic={item.commenter.profilePic}
        message={item.comment}
        messageTime={item.sort}
        messageID={item.uuid}
        numReplies={item.numReplies}
        itemID={this.props.itemID}
        replySortID={item.sort}
        commentReplies={item.replies}
        removeDuplicate={this.removeDuplicate}
      />
    ));
  }

  loadMore = async () => {
    let result = await request(
      "get",
      `/item/${this.props.itemID}/comments`,
      false,
      {
        type: "thread",
        pk: this.state.LastEvaluatedKey.pk,
        sort: this.state.LastEvaluatedKey.sort
      }
    );
    if (!result.success) {
      this.setState({
        message: "Could not load comments at this time."
      });
    } else {
      this.setState({
        items: [...this.state.items, ...result.items],
        LastEvaluatedKey: result.LastEvaluatedKey
      });
    }
  };

  renderLoadMoreButton = () => {
    let listLength = this.state.items.length;
    if (listLength > 0) {
      if (this.state.LastEvaluatedKey) {
        return (
          <h5
            className="marginBottomMediumSmall marginTopMediumSmall"
            onClick={this.loadMore}
          >
            View more comments
          </h5>
        );
      } else {
        return "";
      }
    } else {
      return (
        <h5 className="marginBottomMediumSmall marginTopMediumSmall noComments">
          No comments
        </h5>
      );
    }
  };

  render() {
    return (
      <div className="commentsContainer">
        <h2 className="marginTopSmall marginBottomMediumSmall">
          Have a question? Ask below!
        </h2>
        <div id="commentsBox" className="commentsBox">
          {this.renderComments()}
          <div className="viewMoreCommentsContainer">
            {this.renderLoadMoreButton()}
          </div>
        </div>
        <div className="replyContainer">
          <div className="reply">
            <div className="userImage">
              <img alt="" src={AccountService.state.userState.profilePic} />
            </div>
            <textarea
              id="commentText"
              maxLength="140"
              placeholder="Ask your question here..."
            ></textarea>
            <button className="postButton" onClick={this.saveComment}>
              post
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default Comments;
