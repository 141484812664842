import React from "react";
import styles from "./Pagination.module.scss";

class Pagination extends React.Component {
  constructor(props) {
    super(props);
  }

  mainGoToPage = page => {
    let { scrollToTop, goToPage } = this.props;
    if (scrollToTop) {
      scrollToTop();
    }
    goToPage(page);
  };

  goToPage = totalPages => {
    if (this.props.currentPage >= totalPages) {
      this.mainGoToPage(1);
    } else {
      this.mainGoToPage(this.props.currentPage + 1);
    }
  };

  previousPage = totalPages => {
    if (this.props.currentPage <= 1) {
      this.mainGoToPage(totalPages);
    } else {
      this.mainGoToPage(this.props.currentPage - 1);
    }
  };

  renderPages = pageNumbers => {
    let item = [];
    const currentPage = this.props.currentPage;
    const lastPage = pageNumbers.length;
    const firstPageToShow = 6;
    const pageLimit = lastPage - firstPageToShow + 1;
    const stopMovingCurrentPage = currentPage + 4;

    if (currentPage > 1 && lastPage > firstPageToShow) {
      item.push(
        <li key="lastThreeDots" className={styles.threeDots}>
          ...
        </li>
      );
    }
    if (lastPage < firstPageToShow + 1) {
      for (let i = 1; i <= lastPage - 1; i++) {
        item.push(
          <li
            key={i}
            className={currentPage === i ? styles.activePage : ""}
            onClick={() => {
              this.mainGoToPage(i);
            }}
          >
            {i}
          </li>
        );
      }
    } else {
      if (currentPage >= pageLimit) {
        for (let i = pageLimit; i <= lastPage - 1; i++) {
          item.push(
            <li
              key={i}
              className={currentPage === i ? styles.activePage : ""}
              onClick={() => {
                this.mainGoToPage(i);
              }}
            >
              {i}
            </li>
          );
        }
      } else {
        for (let i = currentPage; i <= stopMovingCurrentPage; i++) {
          item.push(
            <li
              key={i}
              className={currentPage === i ? styles.activePage : ""}
              onClick={() => {
                this.mainGoToPage(i);
              }}
            >
              {i}
            </li>
          );
        }
      }

      if (stopMovingCurrentPage < lastPage - 1) {
        item.push(
          <li key="firstThreeDots" className={styles.threeDots}>
            ...
          </li>
        );
      }
    }

    item.push(
      <li
        key="lastPage"
        className={currentPage === lastPage ? styles.activePage : ""}
        onClick={() => {
          this.mainGoToPage(lastPage);
        }}
      >
        {lastPage}
      </li>
    );

    return item;
  };

  renderPreviousArrow = pageNumbers => {
    if (this.props.currentPage > 1) {
      return (
        <li
          key="previousPage"
          className={styles.previousArrow}
          onClick={() => {
            this.previousPage(pageNumbers.length);
          }}
        ></li>
      );
    }
  };

  renderNextArrow = pageNumbers => {
    if (this.props.currentPage < pageNumbers.length) {
      return (
        <li
          key="nextPage"
          className={styles.nextArrow}
          onClick={() => {
            this.goToPage(pageNumbers.length);
          }}
        ></li>
      );
    }
  };

  render() {
    let pageNumbers = [];
    const totalItems = this.props.totalItems;
    const itemsPerPage = this.props.itemsPerPage;
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      {
        pageNumbers.push(i);
      }
    }
    return (
      <div className={styles.nextPageLinksContainer}>
        <ul>
          {this.renderPreviousArrow(pageNumbers)}
          {this.renderPages(pageNumbers)}
          {this.renderNextArrow(pageNumbers)}
        </ul>
      </div>
    );
  }
}

export default Pagination;
