import React, { Component } from "react";
import AccountService from "../../services/AccountService";
import ExplorePage from "../ExplorePage";
import LandingPage from "./LandingPage";

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    if (AccountService.isLoggedIn) {
      return <ExplorePage userState={AccountService.state.userState} />;
    } else if (AccountService.state.hasEnteredLocation) {
      return <ExplorePage />;
    }
    return <LandingPage />;
  }
}

export default MainPage;
