import InfiniteScroll from "react-infinite-scroll-component";

class ExpandingInfiniteScroll extends InfiniteScroll {
  componentDidMount() {
    super.componentDidMount();
    // This line fixes the bug where it would sometimes be possible to scroll.
    document
      .getElementsByClassName("infinite-scroll-component")[0]
      .setAttribute("style", "overflow: none; height: auto;");
  }
}

export default ExpandingInfiniteScroll;
