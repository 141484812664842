import React from "react";
import "./ReservationPopup.scss";
import Modal from "../../components/Modal";
import ReserveService from "../../services/ReserveService";
import { POPUP_TYPES } from "../../services/PopupService";

class ReservationPopup extends Modal {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  confirmCancelReserve = async () => {
    let result = await ReserveService.cancelReserve(
      this.props.data[0],
      this.props.data[1]
    );
    if (result) {
      this.setState({
        loading: false
      });
      this.props.showSuccessModal();
    }
  };

  confirmExtendReserve = async () => {
    this.setState({
      loading: false
    });
    this.props.showSuccessModal();
  };

  confirmSold = async () => {
    let result = await ReserveService.markAsPaid(this.props.data);
    if (result) {
      this.setState({
        loading: false
      });
      this.props.showSuccessModal();
    }
  };

  confirm = async () => {
    this.setState({
      loading: true
    });
    switch (this.props.modalToShow) {
      case POPUP_TYPES.CANCEL_RESERVE_MODAL:
        return this.confirmCancelReserve();
      case POPUP_TYPES.EXTEND_RESERVE_MODAL:
        return this.confirmExtendReserve();
      case POPUP_TYPES.MARK_ITEM_AS_SOLD_MODAL:
        return this.confirmSold();
      default:
        return null;
    }
  };

  renderYesButton = () => {
    let buttonContent = <span>Yes</span>;
    if (this.state.loading) {
      buttonContent = <span className="center loadingGif" />;
    }
    return (
      <button className="secondaryButton smallButton" onClick={this.confirm}>
        {buttonContent}
      </button>
    );
  };

  renderExitButton = () => {
    return (
      <button className="secondaryButton smallButton" onClick={this.onExit}>
        OK
      </button>
    );
  };

  renderModalTitle = () => {
    switch (this.props.modalToShow) {
      case POPUP_TYPES.CANCEL_RESERVE_MODAL:
        return "Cancel Reservation";
      case POPUP_TYPES.CANCEL_RESERVE_MODAL_SUCCESS:
        return "Success";
      case POPUP_TYPES.EXTEND_RESERVE_MODAL:
        return "Extend Reservation";
      case POPUP_TYPES.EXTEND_RESERVE_MODAL_SUCCESS:
        return "Success";
      case POPUP_TYPES.MARK_ITEM_AS_SOLD_MODAL:
        return "Payment Confirmation";
      case POPUP_TYPES.MARK_ITEM_AS_SOLD_MODAL_SUCCESS:
        return "Hooray";
      case POPUP_TYPES.ADD_COLLEGE_MODAL:
        return "Oops you caught us!";
      case POPUP_TYPES.ADD_EMAIL_MODAL:
        return "Oops you caught us!";
      default:
        return null;
    }
  };

  renderModalBody = () => {
    switch (this.props.modalToShow) {
      case POPUP_TYPES.CANCEL_RESERVE_MODAL:
        return (
          <>
            <p>Are you sure you want to cancel this reservation?</p>
            <div className="fillerDiv"></div>
          </>
        );

      case POPUP_TYPES.CANCEL_RESERVE_MODAL_SUCCESS:
        return (
          <>
            <p className="marginBottomLarge">
              The item has been removed from your reservation page.
            </p>
            <div className="fillerDiv"></div>
            {/* 🌟 */}
            {this.renderExitButton()}
          </>
        );

      case POPUP_TYPES.EXTEND_RESERVE_MODAL:
        return (
          <>
            <p className="threeDaysLabel">
              Would you like to add and additional
              <h5>
                &nbsp;<b>3 days</b>&nbsp;
              </h5>
              to the reservation time?
            </p>
            <div className="fillerDiv"></div>
          </>
        );

      case POPUP_TYPES.EXTEND_RESERVE_MODAL_SUCCESS:
        return (
          <>
            <p className="threeDaysLabel">
              Your reservation time is extended by
              <h5>
                &nbsp;<b>3 days</b>&nbsp;
              </h5>
              <br />
              <br />
            </p>
            <p>
              Please meet up with the seller in order to complete your order.
            </p>
            <div className="fillerDiv"></div>
            {/* 🌟 */}
            {this.renderExitButton()}
          </>
        );

      case POPUP_TYPES.MARK_ITEM_AS_SOLD_MODAL:
        return (
          <>
            <p>
              Did
              <span className="boldText">
                &nbsp;{this.props.data.handle}&nbsp;
              </span>
              buy ({this.props.data.items.length}) ?
            </p>
            <div className="fillerDiv"></div>
          </>
        );

      case POPUP_TYPES.MARK_ITEM_AS_SOLD_MODAL_SUCCESS:
        return (
          <>
            <p>
              Your items have been marked as sold.
              <br />
              <br />
              The items will no longer be available on the feeds.
            </p>
            <div className="fillerDiv"></div>
            {/* ✌️ */}
            {this.renderExitButton()}
          </>
        );
      case POPUP_TYPES.ADD_COLLEGE_MODAL:
        return (
          <>
            <p>
              This feature hasn't been completely finished yet. Please try again
              in a couple of days.
            </p>
            <div className="fillerDiv"></div>
            {this.renderExitButton()}
          </>
        );
      case POPUP_TYPES.ADD_EMAIL_MODAL:
        return (
          <>
            <p>
              This feature hasn't been completely finished yet. Please try again
              in a couple of days.
            </p>
            <div className="fillerDiv"></div>
            {this.renderExitButton()}
          </>
        );
      default:
        return null;
    }
  };

  renderButtons = () => {
    let modal = this.props.modalToShow;
    if (
      modal === POPUP_TYPES.CANCEL_RESERVE_MODAL ||
      modal === POPUP_TYPES.EXTEND_RESERVE_MODAL ||
      modal === POPUP_TYPES.MARK_ITEM_AS_SOLD_MODAL
    ) {
      return (
        <div className="reservePopupButtonContainer">
          <button
            className="primaryButton smallButton"
            onClick={this.props.onExit}
          >
            <span>Cancel</span>
          </button>
          {this.renderYesButton()}
        </div>
      );
    }
  };

  renderContent() {
    return (
      <div className="reservationModalContainer">
        <h2 className="centerText modalTitle textCenter">
          {this.renderModalTitle()}
        </h2>
        <div className="center textCenter">
          {this.renderModalBody()}
          {this.renderButtons()}
        </div>
      </div>
    );
  }
}

export default ReservationPopup;
