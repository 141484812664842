import AccountService from "../services/AccountService";
import { getAuthHeader } from "./library";
import config from "../config";
import tabex from "tabex";
import MessageService from "../services/MessageService";

let RETRY_DELAY_INCREMENTS = 1000;
let MAX_RETRY_LENGTH = 30000;
let retryDelay = RETRY_DELAY_INCREMENTS;

// import wio from "./helpers/sharedSockets";
class WebsocketManagerClass {
  constructor() {
    this.isAuthenticated = false;
    this.actionChannels = {};
    this.tabID = Math.random().toString(36);

    this.tabBus = tabex.client();
    this.tabBus.on("!sys.master", this.tabBusMaster);
    this.tabBus.on("onMessage", this.handleOnMessage);
  }

  init() {}

  send(data, needsAuth) {
    if (needsAuth) {
      if (!AccountService.isLoggedIn) {
        return;
      }
      data.bearerAuth = getAuthHeader();
    }
    if (this.isConnected()) {
      this.ws.send(JSON.stringify(data));
    }
  }

  // Opens a connection to the websocket.
  open() {
    if (AccountService.isLoggedIn) {
      this.isOpen = true;
      this.openConnection();
    }
  }

  openConnection = () => {
    if (
      !this.opening &&
      this.master &&
      this.isOpen &&
      !this.isConnected() &&
      AccountService.isLoggedIn
    ) {
      this.opening = true;
      this.ws = new WebSocket(config.WEBSOCKET_SERVER);

      this.ws.onclose = this.onClose;
      this.ws.onopen = this.onOpen;
      this.ws.onmessage = this.onMessage;
      this.ws.onerror = this.onError;
    }
  };

  // Close the connection to the websocket.
  close = () => {
    this.isAuthenticated = false;
    if (this.isConnected()) {
      this.ws.close();
      delete this.ws;
    }
  };

  isConnected() {
    return this.ws && this.ws.readyState == 1;
  }

  tabBusMaster = data => {
    // If new master is in our tab - connect
    if (data.node_id === data.master_id) {
      this.master = true;
      this.openConnection();
    } else {
      this.master = false;
      this.close();
    }
  };

  subscribe(actionChannel, handlerKey, handler) {
    if (this.actionChannels[actionChannel]) {
      this.actionChannels[actionChannel].push({
        handlerKey,
        handler
      });
    } else {
      this.actionChannels[actionChannel] = [
        {
          handlerKey,
          handler
        }
      ];
    }
  }

  unsubscribe(actionChannel, handlerKey, handler) {
    let channelHandlers = this.actionChannels[actionChannel];
    if (channelHandlers) {
      for (let i = 0; i < channelHandlers.length; i++) {
        if (channelHandlers[i].handlerKey == handlerKey) {
          channelHandlers.splice(i, 1);
        }
      }
    }
  }

  // WS Event Handler

  onOpen = event => {
    this.opening = false;
    retryDelay = 1000;
    if (this.disconnected) {
      this.disconnected = false;
    }
    this.send({ action: "auth" }, true);
  };

  handleOnMessage = payload => {
    if (payload.action == "authResponse") {
      if (payload.success) {
        this.isAuthenticated = true;
      }
    } else if (payload.action == "numAlertsUpdate") {
      AccountService.updateUserStateProperty("numAlerts", payload.numAlerts);
      if (payload.propertyName) {
        AccountService.updateUserStateProperty(
          payload.propertyName,
          payload.newValue
        );
      }
    } else if (payload.action == "userStateUpdate") {
      AccountService.updateUserStateProperty(
        payload.propertyName,
        payload.newValue
      );
    }
    // notify everyone.
    let channelHandlers = this.actionChannels[payload.action];
    if (channelHandlers) {
      for (let channelHandler of channelHandlers) {
        channelHandler.handler(payload);
      }
    }
  };

  onMessage = event => {
    let payload = JSON.parse(event.data);

    // Play a sound on a new message. We should only play on
    // the master tab.
    if (payload.action == "newMessage") {
      MessageService.handleOnceOnNewMessage(payload);
    } else if (payload.action == "read") {
      MessageService.handleOnceOnNewRead(payload);
    }

    this.tabBus.emit("onMessage", payload);
    this.handleOnMessage(payload);
  };

  /**
   * This method has nothing to do with websockets but it allows you to mimic a
   * websocket event.
   *
   * This method allows you to mimic if you received a message from the server
   * which is very useful to make sure every tab performs an action when an
   * event occurs.
   *
   * @param {Object} payload the data to send to every tab. Must have an action
   * property.
   */
  mimicWebsocketOnMessage = payload => {
    this.tabBus.emit("onMessage", payload);
    this.handleOnMessage(payload);
  };

  onClose = event => {
    this.opening = false;
    if (this.master) {
      // Internet disconnected
      this.disconnected = true;
      if (retryDelay < MAX_RETRY_LENGTH) {
        retryDelay += RETRY_DELAY_INCREMENTS;
      }
      window.setTimeout(this.openConnection, retryDelay);
    }
  };

  onError = event => {};

  onStorageChange(event) {
    let newValue = event.newValue;
  }
}

const WebsocketManager = new WebsocketManagerClass();

export default WebsocketManager;
