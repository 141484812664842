import { Component } from "react";
import ReactGA from "react-ga";

class PageComponent extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);
  }
}

export default PageComponent;
