import React from "react";
import Modal from "../Modal.js";
import "../../styles/Modal.scss";
import { requestUser } from "../../lib/library.js";
import { Link } from "react-router-dom";

const OFFSET_TO_LOAD_MORE_MESSAGES = 50;

class UserListModal extends Modal {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      fetchingMore: false
    };
  }

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = async () => {
    let response = await requestUser(
      "get",
      `/user/${this.props.id}/follow`,
      false,
      {
        shouldListFollowers: this.props.show == "followers"
      }
    );

    if (!response.success) {
      alert(response.message);
    }

    this.setState({
      users: response.items,
      LastEvaluatedKey: response.LastEvaluatedKey
    });
  };

  loadMoreData = async () => {
    if (this.state.LastEvaluatedKey !== false && !this.fetchingMore) {
      this.fetchingMore = true;

      let response = await requestUser(
        "get",
        `/user/${this.props.id}/follow`,
        false,
        {
          shouldListFollowers: this.props.show == "followers",
          pk: this.state.LastEvaluatedKey.pk,
          sort: this.state.LastEvaluatedKey.sort
        }
      );

      if (!response.success) {
        // Could not load messages at this time.
      } else {
        this.setState({
          users: [...this.state.users, ...response.items],
          LastEvaluatedKey: response.LastEvaluatedKey
        });
      }

      this.fetchingMore = false;
    }
  };

  handleOnScroll = async evt => {
    let scrollable = evt.target;
    if (
      scrollable.scrollTop + scrollable.clientHeight >=
      scrollable.scrollHeight - OFFSET_TO_LOAD_MORE_MESSAGES
    ) {
      this.loadMoreData();
    }
  };

  renderContent() {
    return (
      <div className="modalForeground">
        <h2 className="centerText modalTitle textCenter">{this.props.title}</h2>
        <div className="scrollableModalContent" onScroll={this.handleOnScroll}>
          <div className="flexGrid marginBottomLarge">
            {this.state.users.map(user => (
              <Link
                className="userInfoBox"
                to={`/user/${user.handle}`}
                onClick={() => {
                  this.props.onExit();
                }}
              >
                <div key={user.uuid} class="user_info">
                  <img className="avatar" src={user.profilePic} alt="Avatar" />
                  <span className="h4 textLeft">{user.handle}</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default UserListModal;
