import AccountService from "../../services/AccountService";
import { _get } from "../../lib/litedash";
import { renderWithBreaks } from "../../lib/library";
import dateformat from "dateformat";
import React, { Component } from "react";

const MAX_ITEMS_TO_DISPLAY = 2;
const OFFSET_TO_LOAD_MORE_MESSAGES = 50;
class ConversationBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reservedItems: [
        {
          productID: 1,
          imageURL: "/photos/header3.jpg",
          itemPrice: 10
        },
        {
          productID: 2,
          imageURL: "/photos/header3.jpg",
          itemPrice: 5
        },
        {
          productID: 3,
          imageURL: "/photos/header3.jpg",
          itemPrice: 7
        },
        {
          productID: 4,
          imageURL: "/photos/header3.jpg",
          itemPrice: 14
        }
      ]
    };
  }

  isLastReadMessageIndex = i => {
    let messages = this.props.messages;
    let lastReadByOtherUser = this.props.conversation.lastReadByOtherUser;
    if (lastReadByOtherUser - messages[i].messageDate < 0) {
      return false;
    }

    if (i < messages.length - 1) {
      return lastReadByOtherUser - messages[i + 1].messageDate < 0;
    } else {
      return true;
    }
  };

  handleOnScroll = evt => {
    if (evt.target.scrollTop < OFFSET_TO_LOAD_MORE_MESSAGES) {
      this.props.onScrollToTheTop();
    }
  };

  renderRead = () => {
    return (
      <img
        src={this.props.conversation.user.profilePic}
        className="messageTabReadProfileImage"
      />
    );
  };

  renderIsTyping() {
    if (this.props.isTyping) {
      return (
        <li key="typingIndicator" className="chatOtherMessageLi">
          <div className="chatMessageProfileSection">
            <img
              src={this.props.conversation.user.profilePic}
              className="messageTabProfileImage"
            />
          </div>
          <div>
            <div className="chatOtherMessage typingIndicatorMessage">
              <img src="/animated/typing.svg" />
            </div>
          </div>
        </li>
      );
    } else {
      return null;
    }
  }

  renderDate(message) {
    return (
      <li key={message.messageDate} className="chatDate">
        {dateformat(message.messageDate, "mmmm dS h:MM tt")}
      </li>
    );
  }

  renderMessage(message, i) {
    let hasNextMessageFromSamePerson =
      message.messagerID == _get(this.props, `messages[${i + 1}].messagerID`);

    if (AccountService.userID == message.messagerID) {
      return (
        <li key={message.sort} className="chatMyMessageLi">
          <div
            className={
              "chatMyMessage " +
              (hasNextMessageFromSamePerson ? "msgStart" : "")
            }
          >
            {renderWithBreaks(message.message)}
          </div>
          <div
            className={
              "chatLastReadContainer " +
              (hasNextMessageFromSamePerson ? "msgStart" : "")
            }
          >
            {this.isLastReadMessageIndex(i) ? this.renderRead(i) : null}
          </div>
        </li>
      );
    } else {
      return (
        <li key={message.sort} className="chatOtherMessageLi">
          <div className="chatMessageProfileSection">
            {hasNextMessageFromSamePerson ? null : (
              <img
                src={this.props.conversation.user.profilePic}
                className="messageTabProfileImage"
              />
            )}
          </div>
          <div>
            <div
              className={
                "chatOtherMessage " +
                (hasNextMessageFromSamePerson ? "msgStart" : "")
              }
            >
              {renderWithBreaks(message.message)}
            </div>
          </div>
          <div
            className={
              "chatLastReadContainer " +
              (hasNextMessageFromSamePerson ? "msgStart" : "")
            }
          >
            {this.isLastReadMessageIndex(i) ? this.renderRead(i) : null}
          </div>
        </li>
      );
    }
  }

  renderMessages() {
    let conversation = this.props.conversation;
    let rendered = [];
    for (let i = 0; i < this.props.messages.length; i++) {
      let message = this.props.messages[i];
      if (i > 0 && i < this.props.messages.length) {
        if (
          (this.props.messages[i].messageDate -
            this.props.messages[i - 1].messageDate) /
            1000 >=
          90
        )
          rendered.push(this.renderDate(message));
      } else if (i == 0) {
        rendered.push(this.renderDate(message));
      }

      rendered.push(this.renderMessage(message, i));
    }
    return (
      <ul className="chatLog" id={"chatLog_" + conversation.userID}>
        {rendered}
        {this.renderIsTyping()}
      </ul>
    );
  }

  renderItemsTotalPrice = () => {
    let price = 0;
    for (let i = 0; i < this.state.reservedItems.length; i++) {
      price += this.state.reservedItems[i].itemPrice;
    }
    return price;
  };

  renderReservedItemImages = () => {
    let items = this.state.reservedItems;
    let products = [];
    for (let i = 0; i < MAX_ITEMS_TO_DISPLAY; i++) {
      if (items[i]) {
        products.push(<img key={items[i].productID} src={items[i].imageURL} />);
      }
    }
    if (items.length > MAX_ITEMS_TO_DISPLAY) {
      products.push(
        <div className="moreItems">+{items.length - MAX_ITEMS_TO_DISPLAY}</div>
      );
    }
    return products;
  };

  renderReservedItems = () => {
    if (this.state.reservedItems.length === 0) {
      return "";
    }
    return (
      <div
        id={"reservedItems_" + this.props.conversation.userID}
        className="reservedItemsContainer"
      >
        <div className="itemsContainer">
          <div className="reservedItem">{this.renderReservedItemImages()}</div>
          <div className="itemsInfo">
            {this.state.reservedItems.length} Item
            {this.state.reservedItems.length === 1 ? "" : "s"} Reserved
            <div className="itemsTotalPrice">
              ${this.renderItemsTotalPrice()}
            </div>
          </div>
        </div>
        <div className="markAsPaidBtn">
          <button className="tinyButton primaryButton">5 days left</button>
        </div>
      </div>
    );
  };

  render() {
    let conversation = this.props.conversation;
    return (
      <div
        id={"messageConversationBody_" + conversation.userID}
        className="messageConversationBody"
        onScroll={this.handleOnScroll}
      >
        {/* {this.renderReservedItems()} */}
        {this.renderMessages()}
      </div>
    );
  }
}

export default ConversationBody;
