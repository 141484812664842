import React, { Component } from "react";
import { Link } from "react-router-dom";

class TriInfoSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let img1 = (
      <img className="splash__image" src={this.props.firstImage} alt="Splash" />
    );
    let img2 = (
      <img
        className="splash__image"
        src={this.props.secondImage}
        alt="Splash"
      />
    );
    return (
      <>
        <div className="splash table">
          <div className="tr">
            <div className="td">
              {this.props.firstImageHandler ? (
                <a onClick={this.props.firstImageHandler}>{img1}</a>
              ) : (
                img1
              )}
            </div>
            <div className="td">
              {this.props.secondImageHandler ? (
                <a onClick={this.props.secondImageHandler}>{img2}</a>
              ) : (
                img2
              )}
            </div>

            <div className="splash__text td vertMiddle">
              <div className="splash__title">
                {this.props.title}
                <h5 className="splash__subtitle">{this.props.subtitle}</h5>
              </div>

              <div className="textCenter">{this.props.button}</div>
            </div>
          </div>
          {/* Insert functionality for "apply" button */}
        </div>
        <div className="splash__textMobile">
          <div className="splash__title">
            <div className="marginBottomMedium">
              {this.props.smallTitle || this.props.title}
              <p className="splash__subtitle">{this.props.subtitle}</p>
            </div>
            {this.props.button}
          </div>
        </div>
      </>
    );
  }
}

export default TriInfoSection;
