import React from "react";
import Modal from "../Modal.js";
import "../../styles/Modal.scss";
import LocationInput from "../../pages/landing/LocationInput.js";

class LocationModal extends Modal {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleUpdateLocation = (lat, lng) => {
    this.lat = lat;
    this.lng = lng;
  };

  renderContent() {
    return (
      <div className="modalForeground">
        <h2 className="centerText modalTitle textCenter">{this.props.title}</h2>
        <div className="center textCenter">
          <LocationInput
            className="modalLocationInput"
            currentLocationLabel={"Current Location"}
            onUpdateLocation={this.handleUpdateLocation}
            onEnterLocation={this.props.onEnterLocation}
          />
          <br />

          <div className="marginBottomMedium">
            <button
              className="primaryButton smallButton"
              onClick={() => {
                LocationInput.handleEnterLocation(
                  this.props.onEnterLocation,
                  this.lat,
                  this.lng
                );
              }}
              // disabled={this.createButtonDisabled}
            >
              {this.props.isUpdating ? (
                <div className="loadingGif center" />
              ) : (
                "Update Location"
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default LocationModal;
