import React, { Component } from "react";

class SectionHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className={
          "standardWidth textCenter " +
          (this.props.spacing ? this.props.spacing : "marginBottomLarge")
        }
      >
        <span className="h1 sectionTitle">{this.props.title}</span>
        <br />
        {this.props.subtitle ? (
          <h3 className="sectionSubtitle marginTopSmall">
            {this.props.subtitle}
          </h3>
        ) : null}
      </div>
    );
  }
}

export default SectionHeader;
