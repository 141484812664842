import React, { Component } from "react";
import { Link } from "react-router-dom";
import FooterService from "../services/FooterService";
import styles from "./AboutFooter.module.scss";
import { isMobileDevice } from "../lib/library";
import AccountService from "../services/AccountService";
import PopupService from "../services/PopupService";

class AboutFooter extends Component {
  constructor(props) {
    super(props);
    FooterService.attachParent(this);

    this.state = {
      ...{},
      ...FooterService.startingState()
    };
  }

  renderBottomFooter = () => {
    if (isMobileDevice()) {
      return (
        <div className={styles.bottomFooterMobile}>
          <div
            className={
              styles.footerContentContainer + " " + styles.iconsContainerMobile
            }
          >
            <a
              href="https://twitter.com/nextbestshop"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="" src="/socialmediaicons/icon-twitter.png" />
            </a>
            <a
              href="https://www.instagram.com/nextbestshop/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt=""
                className="marginRightSmall"
                src="/socialmediaicons/instagram-logo.png"
              />
            </a>
            <a
              href="https://www.facebook.com/nextbestshop/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt=""
                className="marginRightSmall"
                src="/socialmediaicons/icon-facebook.png"
              />
            </a>
          </div>
          <div className={styles.footerContentContainer + " marginTopMedium"}>
            © 2019
            <div className={styles.copyRightLinks}>
              <a
                className="textLink"
                href="/reference/privacy.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </a>
              <a
                className="textLink"
                href="/reference/terms.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>
            </div>
          </div>
          <div className={styles.nextBestIcon}>
            <a href="/">
              <div className={styles.nextBestIconMobile} />
            </a>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.bottomFooter}>
          <a href="/">
            <div className={styles.nextBestIcon} />
          </a>
          <div className={styles.footerContentContainer}>
            © 2019
            <div className={styles.copyRightLinks}>
              <a
                className={"textLink"}
                href="/reference/privacy.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </a>
              <a
                className={"textLink"}
                href="/reference/terms.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>
            </div>
          </div>
          <div className={styles.footerContentContainer}>
            <a
              href="https://twitter.com/nextbestshop"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="" src="/socialmediaicons/icon-twitter.png" />
            </a>
            <a
              href="https://www.instagram.com/nextbestshop/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="" src="/socialmediaicons/instagram-logo.png" />
            </a>
            <a
              href="https://www.facebook.com/nextbestshop/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="" src="/socialmediaicons/icon-facebook.png" />
            </a>
          </div>
        </div>
      );
    }
  };

  renderDesktopLinks = () => {
    if (!isMobileDevice()) {
      return (
        <>
          <h5 className={styles.footerDesktopTitle}>ABOUT US</h5>
          <h5 className={styles.footerDesktopTitle}>CONNECT</h5>
          <h5 className={styles.footerDesktopTitle}>MORE</h5>
        </>
      );
    }
  };

  render() {
    if (
      FooterService.shouldHide() ||
      (isMobileDevice() && AccountService.isLoggedIn)
    ) {
      return null;
    }
    return (
      <div
        className={
          styles.footerContainer +
          (this.state.marginTop ? " marginTopXLarge" : "")
        }
      >
        <div className={styles.innerFooterContainer}>
          <div className={styles.footerSectionContainer + " marginTopXLarge"}>
            <div className={styles.footerContentContainer}>
              <p className="marginBottomMedium textLeft">
                <b>
                  <i>Don't see your college listed?</i>
                </b>
                <br />
                Add the name of your college campus below!
              </p>
              <div className={styles.collegeNameContainer}>
                <input type="text" placeholder="College Name" />
                <button onClick={PopupService.showAddCollegeModal}>
                  <div className={styles.addCollegeArrow}> &#8594;</div>
                </button>
              </div>
              {/* <h4>
                <Link to="/" className={styles.aboutFooterLink}>
                  Add your college here &#8594;
                </Link>
              </h4> */}
            </div>
            <div className={styles.footerContentContainer}>
              <div className={styles.footerLinks}>
                {this.renderDesktopLinks()}
                <h5 className={styles.footerLink}>
                  <Link className="textLink" to="/how_we_work">
                    HOW WE WORK
                  </Link>
                </h5>
                <h5 className={styles.footerLink}>
                  <Link className="textLink" to="/RepUs">
                    REP US
                  </Link>
                </h5>
                <h5 className={styles.footerLink}>
                  <Link className="textLink" to="/support">
                    SUPPORT
                  </Link>
                </h5>
                <h5 className={styles.footerLink}>
                  <Link className="textLink" to="/selling">
                    SELLER GUIDE
                  </Link>
                </h5>
                <h5 className={styles.footerLink}>
                  <a className="textLink" href="/newsletter">
                    NEWSLETTER
                  </a>
                </h5>
                <h5 className={styles.footerLink}>
                  <Link className="textLink" to="/safety">
                    SAFETY
                  </Link>
                </h5>
                <h5 className={styles.footerLink}>
                  <Link className="textLink" to="/buying">
                    BUYER GUIDE
                  </Link>
                </h5>
                <h5 className={styles.footerLink}>
                  <Link className="textLink" to="/contact">
                    CONTACT US
                  </Link>
                </h5>
                <h5 className={styles.footerLink}>
                  <Link className="textLink" to="/FAQ">
                    FAQ'S
                  </Link>
                </h5>
              </div>
            </div>
          </div>
          <div className="extraSeparatorLine marginBottomMedium marginTopLarge"></div>
          {this.renderBottomFooter()}
        </div>
      </div>
    );
  }
}

export default AboutFooter;
