import React from "react";
import PageComponent from "../../components/PageComponent";
import { Link } from "react-router-dom";
import "./Settings.scss";
import { isMobileDevice } from "../../lib/library";
import AccountService from "../../services/AccountService";

class SettingsPage extends PageComponent {
  constructor(props) {
    super(props);
    this.state = {
      isPaneOpen: false
    };
  }

  render = () => {
    return (
      <div className="settingContainer formContainer dynamicHeaderSpacingLarge">
        {!isMobileDevice() ? (
          <h2 className="textCenter marginTopMedium marginBottomMedium">
            Account Settings
          </h2>
        ) : (
          ""
        )}
        <div className="table">
          <div className="tr settingRow">
            <div className="td vertMiddle">
              <h5 className="boldTitle">ACCOUNT</h5>
            </div>
          </div>
          <div className="td separatorLine"></div>
          <div className="tr settingRow">
            <div className="td vertMiddle viewMyShopCell">
              <Link to={`/user/${AccountService.state.userState.handle}`}>
                <div className="mobileMenuSection">
                  <img
                    alt=""
                    className="mobileMenuHandlePic"
                    src={AccountService.state.userState.profilePic}
                    onClick={this.redirectToUserProfile}
                  />
                  <div className="viewMyShop">
                    <h5>{AccountService.state.userState.handle}</h5>
                    <div className="menuLinksContainer">
                      <span className="inTextLink">View my shop</span>

                      {/* <b>
                        &nbsp;<span className="discreteText">·</span>&nbsp;2
                        items listed
                      </b> */}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="td separatorLine"></div>
          <div className="tr settingRow">
            <div className="td vertMiddle">
              <Link to="/watching">Watched Items</Link>
            </div>
          </div>
          <div className="td separatorLine"></div>
          <div className="tr settingRow">
            <div className="td vertMiddle">
              <Link to="/profile/edit">Manage My Profile and Store</Link>
            </div>
          </div>
          {/* <div className="td separatorLine"></div>
          <div className="tr settingRow">
            <div className="td vertMiddle">
              <Link to="/changepassword">Change Password</Link>
            </div>
          </div> */}
          <div className="td separatorLine"></div>
          {/* <div className="tr settingRow">
            <div className="td vertMiddle">
              <div className="tooltip headerTooltip">
                <Link className="shopPause" to="/changepassword">
                  Place Shop On Pause
                </Link>
                <span className="tooltiptext tooltip-bottom">Coming Soon</span>
              </div>
            </div>
          </div>
          <div className="td separatorLine"></div> */}
        </div>
        <div className="table marginTopSmall">
          <div className="tr settingRow">
            <div className="td vertMiddle">
              <h5 className="boldTitle">ABOUT</h5>
            </div>
          </div>
          <div className="td separatorLine"></div>
          <div className="tr settingRow">
            <div className="td vertMiddle">
              <Link to="/how_we_work" className="linkColor">
                How we work
              </Link>
            </div>
          </div>
          <div className="td separatorLine"></div>
          <div className="tr settingRow">
            <div className="td vertMiddle">
              <Link to="/RepUs">Join Us</Link>
            </div>
          </div>
          <div className="td separatorLine"></div>
          <div className="tr settingRow">
            <div className="td vertMiddle">
              <Link to="/selling">Seller Guide</Link>
            </div>
          </div>
          <div className="td separatorLine"></div>
          <div className="tr settingRow">
            <div className="td vertMiddle">
              <Link to="/buying">Buyer Guide</Link>
            </div>
          </div>
          <div className="td separatorLine"></div>
        </div>
        <div className="table marginTopSmall">
          <div className="tr settingRow">
            <div className="td vertMiddle">
              <h5 className="boldTitle">HELP</h5>
            </div>
          </div>
          <div className="td separatorLine"></div>
          <div className="tr settingRow">
            <div className="td vertMiddle">
              <Link to="/FAQ">FAQ's</Link>
            </div>
          </div>
          <div className="td separatorLine"></div>
          <div className="tr settingRow">
            <div className="td vertMiddle">
              <Link to="/contact">Contact Us</Link>
            </div>
          </div>
          <div className="td separatorLine"></div>
        </div>
        <div className="table marginTopSmall">
          <div className="tr settingRow">
            <div className="td vertMiddle">
              <h5 className="boldTitle">OTHER</h5>
            </div>
          </div>
          <div className="td separatorLine"></div>
          <div className="tr settingRow">
            <div className="td vertMiddle">
              <Link to="/notifications/settings">Notification Settings</Link>
            </div>
          </div>
          <div className="td separatorLine"></div>
          <div className="tr settingRow">
            <div className="td vertMiddle">
              <a href="/" onClick={AccountService.handleLogOut}>
                Log out
              </a>
            </div>
          </div>
          <div className="td separatorLine"></div>
        </div>
        <div className="marginBottomXLarge"></div>
      </div>
    );
  };

  renderTitle = () => {
    return (
      <div className="table headingAlignment">
        <div className="tr">
          <div className="td vertMiddle">Settings Page</div>
        </div>
      </div>
    );
  };
}

export default SettingsPage;
