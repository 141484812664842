import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import importedComponent from "react-imported-component";
import AccountService from "./services/AccountService";
import ReserveService from "./services/ReserveService";
import MainPage from "./pages/landing/MainPage";
import ExplorePage from "./pages/ExplorePage";
import ProductPage from "./pages/ProductPage";
// import ListPage from "./pages/list/ListPage";
import ListSuccessPage from "./pages/list/ListSuccessPage";
import ReservationPage from "./pages/reservation/ReservationPage";
import WatchingPage from "./pages/WatchingPage";
import ProfilePage from "./pages/profile/ProfilePage";
import NotificationsPage from "./pages/alerts/NotificationsPage";
import LogOutPage from "./pages/LogOutPage";
import ComingSoonPage from "./pages/ComingSoonPage";
import Header from "./components/header/Header";
import TabBar from "./components/mobile/TabBar";
import SettingsPage from "./pages/settings/SettingsPage";
import AboutFooter from "./components/AboutFooter";
import LoadingScreen from "./components/LoadingScreen";
import MessageChatCircles from "./pages/messages/MessageChatCircles";
import MessagePane from "./pages/messages/MessagePane";
import WebsocketManager from "./lib/websocketManager";

import ReactGA from "react-ga";
import "./App.scss";
import MessageService from "./services/MessageService";
import PopupService from "./services/PopupService";
import { getBrowser } from "./lib/library";
import HeaderService from "./services/HeaderService";
import MessageToPage from "./pages/messages/MessageToPage";

import AOS from "aos";
import "aos/dist/aos.css";
import URLAnchorService from "./services/URLAnchorService";

function initializeLocalStorageListener() {
  const storageEventHandler = e => {
    if (e.key === "allJWT") {
      const newLoggedIn = !!e.newValue;
      if (newLoggedIn !== AccountService.state.loggedIn) {
        if (newLoggedIn) {
          AccountService.setState({
            loggedIn: true
          });
          if (window.location.pathname === "/logout") {
            window.location = "/";
          }
        } else {
          AccountService.logOut();
        }
      }
    } else if (e.key === "conversations") {
      MessageService.syncState(JSON.parse(e.newValue));
    } else if (e.key === "wsAction") {
      WebsocketManager.onStorageChange(e);
    }
  };
  if (window.addEventListener) {
    window.addEventListener("storage", storageEventHandler, false);
  } else if (window.attachEvent) {
    window.attachEvent("onstorage", storageEventHandler, false);
  }
}

function initializeReactGA(accountService) {
  ReactGA.initialize("UA-152710220-1");
  accountService.gaInitialSetup(ReactGA);
}

// function collectAnalyticsData() {
//   const callback = list => {
//     list.getEntries().forEach(entry => {
//       console.log("entry", entry);
//     });
//   };

//   var observer = new PerformanceObserver(callback);
//   observer.observe({ entryTypes: ["navigation", "resource"] });
// }

const ListPage = importedComponent(
  () => import(/* webpackChunkName:'ListPage' */ "./pages/list/ListPage"),
  {
    LoadingComponent: LoadingScreen
  }
);

const EditItemPage = importedComponent(
  () =>
    import(/* webpackChunkName:'EditItemPage' */ "./pages/list/EditItemPage"),
  {
    LoadingComponent: LoadingScreen
  }
);

const EditProfilePage = importedComponent(
  () =>
    import(
      /* webpackChunkName:'EditProfilePage' */ "./pages/profile/EditProfilePage"
    ),
  {
    LoadingComponent: LoadingScreen
  }
);

const HowWeWorkPage = importedComponent(
  () =>
    import(/* webpackChunkName:'HowWeWorkPage' */ "./pages/info/HowWeWorkPage"),
  {
    LoadingComponent: LoadingScreen
  }
);

const ChatPage = importedComponent(
  () => import(/* webpackChunkName:'ChatPage' */ "./pages/messages/ChatPage"),
  {
    LoadingComponent: LoadingScreen
  }
);

const AdminPortalPage = importedComponent(
  () =>
    import(/* webpackChunkName:'AdminPortalPage' */ "./pages/AdminPortalPage"),
  {
    LoadingComponent: LoadingScreen
  }
);

const NotificationsSettingsPage = importedComponent(
  () => import("./pages/NotificationsSettings"),
  { LoadingComponent: LoadingScreen }
);

const AboutPage = importedComponent(
  () => import(/* webpackChunkName:'AboutPage' */ "./pages/info/AboutPage"),
  {
    LoadingComponent: LoadingScreen
  }
);

const SafetyPage = importedComponent(
  () => import(/* webpackChunkName:'SafetyPage' */ "./pages/info/SafetyPage"),
  {
    LoadingComponent: LoadingScreen
  }
);

const BuyingPage = importedComponent(
  () => import(/* webpackChunkName:'BuyingPage' */ "./pages/info/BuyingPage"),
  {
    LoadingComponent: LoadingScreen
  }
);

const SellingPage = importedComponent(
  () => import(/* webpackChunkName:'SellingPage' */ "./pages/info/SellingPage"),
  {
    LoadingComponent: LoadingScreen
  }
);

const FAQPage = importedComponent(
  () => import(/* webpackChunkName:'FAQPage' */ "./pages/info/FAQPage"),
  {
    LoadingComponent: LoadingScreen
  }
);

const RepUsPage = importedComponent(
  () => import(/* webpackChunkName:'RepUsPage' */ "./pages/info/RepUsPage"),
  {
    LoadingComponent: LoadingScreen
  }
);

const ContactPage = importedComponent(
  () => import(/* webpackChunkName:'ContactPage' */ "./pages/info/ContactPage"),
  {
    LoadingComponent: LoadingScreen
  }
);

const SupportPage = importedComponent(
  () => import(/* webpackChunkName:'SupportPage' */ "./pages/info/SupportPage"),
  {
    LoadingComponent: LoadingScreen
  }
);

const NewsletterPage = importedComponent(
  () =>
    import(
      /* webpackChunkName:'NewsletterPage' */ "./pages/info/NewsletterPage"
    ),
  {
    LoadingComponent: LoadingScreen
  }
);
class App extends React.Component {
  constructor() {
    super();

    initializeLocalStorageListener();
    AccountService.attachParent(this);
    initializeReactGA(AccountService);
    // collectAnalyticsData();

    ReserveService.attachParent(this);
    PopupService.attachParent(this);
    URLAnchorService.attachParent(this);

    this.state = {
      ...{},
      ...AccountService.startingState(),
      ...ReserveService.startingState(),
      ...PopupService.startingState(),
      ...URLAnchorService.startingState()
    };

    // Must occur after this.state;
    WebsocketManager.init();
    WebsocketManager.open();
  }

  componentDidUpdate = () => {};

  componentDidMount = () => {
    if (navigator.appVersion.indexOf("Win") !== -1) {
      document.body.classList.add("hideScrollBar");
    }

    if (getBrowser() === "Safari") {
      document.body.addEventListener("click", this.unlockAudio);
      document.body.addEventListener("touchstart", this.unlockAudio);
      // Needed for sliding pane
    }
    // Needed for sliding pane

    AOS.init({
      // initialise with other settings
      duration: 2000
    });
  };

  unlockAudio = () => {
    if (!this.soundActivated) {
      const sound = new Audio("/sounds/messageSound.mp3");
      sound.play().catch(e => {});
      // Will cause: Error: The error you provided does not contain a stack trace.
      // Unhandled Promise Rejection: AbortError: The operation was aborted.
      sound.pause();
      sound.currentTime = 0;

      document.body.removeEventListener("click", this.unlockAudio);
      document.body.removeEventListener("touchstart", this.unlockAudio);
    }
  };

  handleMouseDown = () => {
    let sendData = () => {
      this.lastSent = Date.now();
      WebsocketManager.send(
        {
          action: "active"
        },
        true
      );
    };

    if (this.lastSent) {
      let currDate = Date.now() - this.lastSent;
      if (currDate / 1000 > 60) {
        sendData();
      }
    } else {
      sendData();
    }
  };

  handleMouseUp = () => {
    HeaderService.toggleOffAll();
  };

  render() {
    return (
      <div onMouseDown={this.handleMouseDown} onMouseUp={this.handleMouseUp}>
        <BrowserRouter>
          {AccountService.renderModal()}
          {ReserveService.renderModal()}
          {PopupService.renderModal()}
          {AccountService.renderOnboard()}

          <Header
            loggedIn={AccountService.state.loggedIn}
            userState={AccountService.state.userState}
          />

          <Route
            path="/item/:id"
            render={props => (
              <ProductPage
                itemID={props.match.params.id}
                onReserve={this.handleReserve}
              />
            )}
          />
          <Route path="/sell-success" component={ListSuccessPage} />
          <Route path="/reserved" component={ReservationPage} />
          <Route path="/admin" component={AdminPortalPage} />
          <Route path="/logout" component={LogOutPage} />
          <Route
            path="/sell"
            render={props => (
              <ListPage
                userState={AccountService.state.userState}
                onSellerApplySuccess={AccountService.handlePhoneSuccess}
              />
            )}
          />

          <Route
            path="/edit/item/:id"
            render={props => <EditItemPage itemID={props.match.params.id} />}
          />

          <Route path="/profile/edit" component={EditProfilePage} />
          <Route path="/how_we_work" component={HowWeWorkPage} />

          <Route
            path="/user/:handle"
            render={props => <ProfilePage handle={props.match.params.handle} />}
          />

          <Route
            path="/chat/:id"
            render={props => <ChatPage userID={props.match.params.id} />}
          />
          <Route path="/messages" component={MessageToPage} />

          <Route path="/changepassword" component={ComingSoonPage} />
          <Route exact path="/notifications" component={NotificationsPage} />
          <Route path="/watching" component={WatchingPage} />
          <Route path="/settings" component={SettingsPage} />
          <Route
            path="/notifications/settings"
            component={NotificationsSettingsPage}
          />

          <Route path="/about" component={AboutPage} />
          <Route path="/safety" component={SafetyPage} />
          <Route path="/buying" component={BuyingPage} />
          <Route path="/selling" component={SellingPage} />
          <Route path="/FAQ" component={FAQPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/support" component={SupportPage} />
          <Route path="/newsletter" component={NewsletterPage} />
          <Route path="/RepUs" component={RepUsPage} />
          <Route
            exact
            path="/"
            render={props => (
              <MainPage userState={AccountService.state.userState} />
            )}
          />

          <Route
            path="/shop"
            render={props => (
              <ExplorePage userState={AccountService.state.userState} />
            )}
          />

          <MessagePane />
          <MessageChatCircles />

          <AboutFooter />

          <TabBar />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
