import AppService from "./AppService";
import AccountService from "./AccountService";
import { request } from "../lib/library";

class MessageServiceClass extends AppService {
  startingState() {
    let conversations = { conversations: [] };
    if (window.localStorage.getItem("conversations")) {
      conversations = JSON.parse(window.localStorage.getItem("conversations"));
    }

    return {
      ...super.startingState(),
      ...conversations,
      isShowingMessageList: false
    };
  }

  setState(newState) {
    super.setState(newState);
    if (newState.conversations === undefined) {
      newState.conversations = this.state.conversations;
    }
    if (newState.isShowingMessageList === undefined) {
      newState.isShowingMessageList = this.state.isShowingMessageList;
    }
    window.localStorage.setItem("conversations", JSON.stringify(newState));
  }

  syncState(newState) {
    super.setState(newState);
  }

  /**
   * This method is called whenever the websocket listener receives
   * an event that a new message has been sent or received.
   * This method is called only once among all of the tabs and windows.
   *
   * Currently, this method is in charge of playing a sound if it's a new
   * message sent by the other user and opening up that conversation. It also
   * updates the number of read messages.
   * @param {NewMessageBroadcastObject} payload
   */
  handleOnceOnNewMessage(payload) {
    if (payload.item.messagerID !== AccountService.userID) {
      MessageServiceClass.soundOnMessage();
      this.addConversation(
        payload.messenger,
        {
          numUnread: payload.numUnread,
          lastReadByOtherUser: payload.item.messageDate
        },
        // Suppress the notify read because the user didn't
        // actually click to open the conversation so there's
        // no guarantee that the user read the conversation yet.
        true
      );
    }
  }

  handleOnceOnNewRead(payload) {
    let conversations = [...this.state.conversations];
    for (let conversation of conversations) {
      if (conversation.userID === payload.readerID) {
        conversation.lastReadByOtherUser = payload.lastReadByOtherUser;
        this.setState({ conversations });
        return;
      }
    }
  }

  findConversationIndex(conversation) {
    for (let i = 0; i < this.state.conversations.length; i++) {
      if (this.state.conversations[i].userID === conversation.userID) {
        return i;
      }
    }
  }

  toggleConversation(conversation) {
    if (conversation.opened) {
      this.closeConversation(conversation);
    } else {
      this.openConversation(conversation);
    }
  }

  closeConversation(conversation) {
    let conversations = [...this.state.conversations];
    let index = this.findConversationIndex(conversation);
    conversations[index].opened = false;
    this.setState({
      conversations
    });
  }

  toggleMessageList = () => {
    if (this.state.isShowingMessageList) {
      this.setState({
        isShowingMessageList: false
      });
    } else {
      let conversations = [...this.state.conversations];
      // Close the other conversations
      for (let conversation of conversations) {
        conversation.opened = false;
      }
      this.setState({
        conversations,
        isShowingMessageList: true
      });
    }
  };

  openConversation(
    conversation,
    { numUnread = 0, lastReadByOtherUser = false } = {},
    suppressNotifyRead,
    user
  ) {
    let conversations = [...this.state.conversations];
    // Close the other conversations
    for (let conv of conversations) {
      if (conversation.userID !== conv.userID) {
        conv.opened = false;
      }
    }

    let conv = conversations[this.findConversationIndex(conversation)];

    conv.notifications = numUnread;
    if (lastReadByOtherUser) {
      conv.lastReadByOtherUser = lastReadByOtherUser;
    }
    if (!conv.opened) {
      conv.opened = true;
      conv.firstScroll = true;
      if (!suppressNotifyRead) {
        this.notifyRead(conversation);
      }
    }

    // If the user has been provided, update the user's information.
    // Currently, the user is provided when onNewMessage has been received.
    if (user) {
      conversation.user = user;
      conversation.lastActiveFetch = new Date();
    }
    this.setState({
      conversations,
      isShowingMessageList: false
    });
  }

  /**
   * This method gets called whenever the user does an action
   * that warrants notifying the server that the user has read the most recent messages in
   * a particular conversation.
   *
   * Currently events includes:
   * - Opening a conversation
   * - Clicking on the MessageCircleConversation
   * @param {Conversation} conversation the conversation that the user has read.
   * @param {boolean} updateState whether to reset the notifications to 0 or not.
   */
  notifyRead = async (conversation, updateState) => {
    let response = await request("put", "/chat/" + conversation.userID, true, {
      type: "read"
    });

    if (response.success) {
      let conversations = [...this.state.conversations];
      let conv = conversations[this.findConversationIndex(conversation)];
      if (updateState) {
        conv.notifications = 0;
      }
      conv.lastActiveFetch = new Date();
      conv.user.lastSeen = response.item.lastSeen;
      this.setState({
        conversations
      });
    }
  };

  onFirstScrollCompleted(conversation) {
    let conversations = [...this.state.conversations];
    let index = this.findConversationIndex(conversation);
    conversations[index].firstScroll = false;
    this.setState({
      conversations
    });
  }

  updateConversation(newConversation) {
    let conversations = [...this.state.conversations];
    let index = this.findConversationIndex(newConversation);
    conversations[index] = newConversation;
    this.setState({
      conversations
    });
  }

  static createConversation(
    user,
    { numUnread = 0, lastReadByOtherUser = false }
  ) {
    return {
      userID: user.uuid,
      firstScroll: true,
      notifications: numUnread,
      lastReadByOtherUser,
      // the last time the user active status was updated.
      lastActiveFetch: new Date(),
      opened: true,
      user
    };
  }

  addConversation(
    user,
    { numUnread = 0, lastReadByOtherUser = false } = {},
    suppressNotifyRead
  ) {
    // Open this conversation if it already exists in the conversation list.
    for (let conversation of this.state.conversations) {
      if (conversation.userID === user.uuid) {
        this.openConversation(
          conversation,
          { numUnread, lastReadByOtherUser },
          suppressNotifyRead,
          user
        );
        return;
      }
    }

    // If the conversation doesn't exist, add the conversation and open it.
    let conversations = [...this.state.conversations];
    // Close the other conversations
    for (let conversation of conversations) {
      conversation.opened = false;
    }

    let newConversation = MessageServiceClass.createConversation(user, {
      numUnread,
      lastReadByOtherUser
    });
    conversations.unshift(newConversation);
    this.setState({
      conversations,
      isShowingMessageList: false
    });

    if (!suppressNotifyRead) {
      this.notifyRead(newConversation);
    }
  }

  removeConversation(conversation) {
    let conversations = [...this.state.conversations];
    conversations.splice(this.findConversationIndex(conversation), 1);
    this.setState({
      conversations
    });
  }

  static soundOnMessage = () => {
    let messageSound = document.createElement("Audio");
    messageSound.setAttribute("src", "/sounds/messageSound.mp3");
    messageSound.volume = 0.2;

    messageSound.play().catch(e => {
      console.error(e);
    });
  };
}

const MessageService = new MessageServiceClass();

export default MessageService;
