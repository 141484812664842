import AppService from "./AppService";
import AccountService from "./AccountService";
import { request } from "../lib/library";
import { ym } from "../lib/analytics";

class WatchService extends AppService {
  //   startingState() {
  //     return {
  //       ...super.startingState(),
  //       ...{
  //         shouldShowReserveModal: false
  //       }
  //     };
  //   }

  watch = itemID => {
    AccountService.runWhenLoggedIn(async () => {
      const response = await request("post", `/item/${itemID}/watch`, true);
      if (response.success) {
        ym("reachGoal", "item_watched", 2);
      }
    });
  };

  unwatch = async itemID => {
    AccountService.runWhenLoggedIn(async () => {
      const response = await request("post", `/item/${itemID}/unwatch`, true);
      if (response.success) {
        ym("reachGoal", "item_unwatched", 1);
      }
    });
  };
}

const mainWatchService = new WatchService();

export default mainWatchService;
