import React, { Component } from "react";
import SectionHeader from "./header/SectionHeader";

class ErrorPageMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="centerSection pageSection">
        <div className="marginBottomXLarge"></div>
        <SectionHeader title={this.props.message} />
      </div>
    );
  }
}

export default ErrorPageMessage;
