import React, { Component } from "react";
import AccountService from "../../services/AccountService";
import {
  request,
  daysAgoLabel,
  isOnline,
  isMobileDevice
} from "../../lib/library";
import MessageService from "../../services/MessageService";
import { Link } from "react-router-dom";
import "./MessageToList.scss";
import ExpandingInfiniteScroll from "../../components/ExpandingInfiniteScroll";

const OFFSET_TO_LOAD_MORE_MESSAGES = 50;

class MessageToItem extends Component {
  get isMessageOwner() {
    return this.props.item.messagerID === AccountService.userID;
  }

  renderOnlineStatus() {
    if (isOnline(this.props.item.messageTo.lastSeen)) {
      return <div className="onlineStatus"></div>;
    } else {
      return null;
      // return (
      //   <span className="discreteText">
      //     &nbsp;{daysAgoLabel(this.props.item.messageTo.lastSeen)}
      //   </span>
      // );
    }
  }

  renderContent() {
    let item = this.props.item;
    return (
      <>
        <div className="imageContainer">
          <img alt="" src={item.messageTo.profilePic} />
        </div>
        <div className="messageBodyContainer">
          <table className="fullWidth">
            <tbody>
              <tr>
                <td className="vertMiddle">
                  <div className="usernameContainer">
                    <h4>{item.messageTo.handle}</h4>
                    {this.renderOnlineStatus()}
                  </div>
                </td>
                <td className="vertMiddle">
                  {!this.isMessageOwner ? (
                    <div className="yourTurn">
                      <div className="yourTurnText">your turn</div>
                    </div>
                  ) : null}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="fullWidth">
            <tbody>
              <tr>
                <td className="vertMiddle">
                  <div className="messageContainer">
                    <p
                      className={
                        "lastMessage " + (item.numUnread ? "boldText" : "")
                      }
                    >
                      {(this.isMessageOwner ? "You: " : "") + item.message}
                    </p>
                    <div className="messageDot"></div>
                    <div className="lastMessageTime">
                      <p className="textRight">
                        {daysAgoLabel(item.messageDate)}
                      </p>
                    </div>
                  </div>
                </td>
                {/* <div className="td vertMiddle">
                <p className="textRight">{daysAgoLabel(item.messageDate)}</p>
              </div> */}
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }

  render() {
    let item = this.props.item;
    if (isMobileDevice()) {
      return (
        <Link to={`/chat/${item.messageTo.uuid}`}>
          <div className="threadContainer">{this.renderContent()}</div>
        </Link>
      );
    } else {
      return (
        <div
          className="threadContainer"
          onClick={() => {
            MessageService.addConversation(item.messageTo);
          }}
        >
          {this.renderContent()}
        </div>
      );
    }
  }
}

class MessageToList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    };
  }

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = async () => {
    this.loading = true;
    const response = await request(
      "get",
      `/chat/${AccountService.userID}`,
      true,
      {
        action: "self"
      }
    );
    this.loading = false;

    if (!response.success) {
      // Could not load messages at this time.
      this.setState({
        loaded: "Failed to load your conversations",
        items: []
      });
    } else {
      this.setState({
        loaded:
          response.items.length === 0
            ? "Users whose items you reserve or users who reserves your items will show up here."
            : true,
        items: response.items,
        LastEvaluatedKey: response.LastEvaluatedKey
      });
    }
  };

  loadMoreData = async () => {
    if (this.state.LastEvaluatedKey !== false && !this.fetchingMore) {
      this.fetchingMore = true;

      const response = await request(
        "get",
        `/chat/${AccountService.userID}`,
        true,
        {
          action: "self",
          pk: this.state.LastEvaluatedKey.pk,
          sort: this.state.LastEvaluatedKey.sort,
          gsi_2_pk: this.state.LastEvaluatedKey.gsi_2_pk,
          gsi_2_sort: this.state.LastEvaluatedKey.gsi_2_sort
        }
      );

      if (!response.success) {
        // Could not load messages at this time.
      } else {
        this.setState({
          items: [...this.state.items, ...response.items],
          LastEvaluatedKey: response.LastEvaluatedKey
        });
      }

      this.fetchingMore = false;
    }
  };

  handleOnScroll = async evt => {
    let scrollable = evt.target;
    if (
      scrollable.scrollTop + scrollable.clientHeight >=
      scrollable.scrollHeight - OFFSET_TO_LOAD_MORE_MESSAGES
    ) {
      this.loadMoreData();
    }
  };

  renderItems = () => {
    if (this.state.loaded) {
      return (
        <>
          <div className="separatorLine"></div>
          {this.state.items.map(item => (
            <div key={item.sort}>
              <MessageToItem item={item}></MessageToItem>
              <div className="separatorLine"></div>
            </div>
          ))}
        </>
      );
    } else {
      return null;
    }
  };

  render() {
    let content = (
      <>
        <h2 className="marginTopSmall marginBottomSmall marginLeftSmall">
          Messages
        </h2>
        {this.state.loaded !== true ? (
          <div className="messageListContainer">
            <p>{this.state.loaded}</p>
          </div>
        ) : (
          <div className="messageListContainer">{this.renderItems()}</div>
        )}
      </>
    );
    if (isMobileDevice()) {
      if (this.state.loaded) {
        return (
          <ExpandingInfiniteScroll
            className="messageListInfiniteScroll"
            dataLength={this.state.items.length}
            next={this.loadMoreData}
            hasMore={this.state.LastEvaluatedKey}
          >
            {content}
          </ExpandingInfiniteScroll>
        );
      } else {
        return (
          <div className="feedLoadingContainer">
            <img alt="" src="/ripple.svg" className="center" />
          </div>
        );
      }
    }
    return (
      <div
        className="messageConversationTab messageListScrollable messageFooterItem"
        onScroll={this.handleOnScroll}
      >
        {content}
      </div>
    );
  }
}

export default MessageToList;
