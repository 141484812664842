import React, { Component } from "react";
import TextareaAutosize from "react-textarea-autosize";

class ConversationFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  checkForEnter = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      this.handleSubmitMessage();
    }
  };

  handleSubmitMessage = (e) => {
    if (this.props.textContent) {
      document.getElementById(
        "messageConversationTextarea_" + this.props.conversation.userID
      ).value = "";
      this.props.onSubmitMessage();
    }
  };

  render() {
    return (
      <div>
        <div
          className="messageConversationFooter table"
          id={"messageConversationFooter_" + this.props.conversation.userID}
        >
          <div className="tr">
            <div className="td">
              <TextareaAutosize
                id={
                  "messageConversationTextarea_" +
                  this.props.conversation.userID
                }
                onKeyPress={(e) => this.checkForEnter(e)}
                onChange={(e) => this.props.handleTyping(e)}
                className="messageConversationTextarea"
                placeholder="Type a message..."
              />
            </div>
            <div className="td">
              <div
                className={
                  "messageSendIcon " +
                  (this.props.textContent ? "active" : "disabled")
                }
                onClick={this.handleSubmitMessage}
              ></div>
            </div>
          </div>
        </div>
        <div>&nbsp;</div>
      </div>
    );
  }
}

export default ConversationFooter;
