export const SCHOOLS_LOCATIONS = {
  UCLA: {
    lat: 34.068921,
    lng: -118.4451811,
    description: "UCLA, Los Angeles, CA, USA",
    matched_substrings: [{ length: 4, offset: 0 }],
    place_id: "ChIJZQ9c8IW8woARN0gTXFiTqSU",
    reference: "ChIJZQ9c8IW8woARN0gTXFiTqSU",
    structured_formatting: {
      main_text: "UCLA",
      main_text_matched_substrings: [{ length: 4, offset: 0 }],
      secondary_text: "Los Angeles, CA, USA"
    },
    terms: [
      { offset: 0, value: "UCLA" },
      { offset: 6, value: "Los Angeles" },
      { offset: 19, value: "CA" },
      { offset: 23, value: "USA" }
    ],
    types: ["university", "point_of_interest", "establishment"]
  },
  CORNELL: {
    lat: 42.4534492,
    lng: -76.4735027,
    description: "Cornell University, Ithaca, NY, USA",
    matched_substrings: [
      { length: 18, offset: 0 },
      { length: 2, offset: 20 }
    ],
    place_id: "ChIJndqRYRqC0IkR9J8bgk3mDvU",
    reference: "ChIJndqRYRqC0IkR9J8bgk3mDvU",
    structured_formatting: {
      main_text: "Cornell University",
      main_text_matched_substrings: [{ length: 18, offset: 0 }],
      secondary_text: "Ithaca, NY, USA",
      secondary_text_matched_substrings: [{ length: 2, offset: 0 }]
    },
    terms: [
      { offset: 0, value: "Cornell University" },
      { offset: 20, value: "Ithaca" },
      { offset: 28, value: "NY" },
      { offset: 32, value: "USA" }
    ],
    types: ["university", "point_of_interest", "establishment"]
  }
};
