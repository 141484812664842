import React from "react";
import AppService from "./AppService";
import AccountService from "./AccountService";
import { request, preventScrolling, allowScrolling } from "../lib/library";
import ReserveModal from "../components/ReserveModal";
import { ym } from "../lib/analytics";
import WebsocketManager from "../lib/websocketManager";

class ReserveService extends AppService {
  startingState() {
    return {
      ...super.startingState(),
      ...{
        shouldShowReserveModal: false
      }
    };
  }

  handleReserve = item => {
    AccountService.runWhenLoggedIn(() => {
      preventScrolling();
      this.setState({
        shouldShowReserveModal: item
      });
    });
  };

  markAsPaid = async seller => {
    const result = await request("post", `/reserve/${seller.uuid}/mark`, true, {
      marker: "buyer",
      items: seller.items.map(i => {
        return {
          pk: i.pk,
          sort: i.sort
        };
      })
    });
    if (result.success) {
      ym("reachGoal", "item_reservationPaid", 10);
      this.notifyEventListeners({
        action: "mark",
        sellerID: seller.uuid
      });
      WebsocketManager.mimicWebsocketOnMessage({
        action: "userStateUpdate",
        propertyName: "numSellingReservations",
        newValue:
          AccountService.state.userState.numSellingReservations -
          seller.items.length
      });
    } else {
      alert(result.message);
    }
    return result.success;
  };

  cancelReserve = async (itemID, reserveDate, isBuying) => {
    const result = await request("post", `/reserve/${itemID}/cancel`, true, {
      reserveDate: reserveDate
    });
    if (result.success) {
      this.notifyEventListeners({
        action: "cancel",
        itemID: itemID
      });
      let propertyName = isBuying
        ? "numBuyingReservations"
        : "numSellingReservations";
      WebsocketManager.mimicWebsocketOnMessage({
        action: "userStateUpdate",
        propertyName,
        newValue: AccountService.state.userState[propertyName] - 1
      });
    } else {
      alert(result.message);
    }
    return result.success;
  };

  hideReserve = () => {
    allowScrolling();
    this.setState({
      shouldShowReserveModal: false
    });
  };

  renderModal() {
    return (
      <ReserveModal
        show={this.state.shouldShowReserveModal}
        userState={AccountService.state.userState}
        onExit={this.hideReserve}
        onBuyerApplySuccess={AccountService.handlePhoneSuccess}
      />
    );
  }
}

const mainReserveService = new ReserveService();

export default mainReserveService;
