import React, { Component } from "react";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  stopProp = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  renderContent() {}

  delayedExit = () => {
    window.setTimeout(() => {
      this.onExit();
    }, 200);
  };

  onExit = () => {
    this.props.onExit();
  };

  render() {
    if (this.props.show === false) return null;
    return (
      <div className="modalContainer">
        <div onClick={this.onExit} className="modalBackdrop">
          <div onClick={this.stopProp} className="modalForeground">
            <div
              onClick={this.delayedExit}
              className="itemIcon modalCloseIcon"
              alt="Close"
            />
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
