import React, { Component } from "react";
import { Link } from "react-router-dom";
import { SIZE_ENUM, ITEM_STATUS_ENUM } from "../lib/itemEnums";
import ReserveService from "../services/ReserveService";
import WatchService from "../services/WatchService";
import AccountService from "../services/AccountService";
import { createThumbnailURL, getStatus, daysAgoLabel } from "../lib/library";

class ItemDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      watched: this.props.item.isWatching
    };
  }

  toggleWatch = item => {
    if (this.state.watched) {
      WatchService.unwatch(item.itemID);
      this.setState({
        watched: false
      });
    } else {
      WatchService.watch(item.itemID);
      this.setState({
        watched: true
      });
    }
  };

  render() {
    const item = this.props.item;

    let statusSection = null;
    if (item.status !== ITEM_STATUS_ENUM.AVAILABLE.id) {
      statusSection = (
        <div className="absolute statusBarContainer">
          <div
            className={
              "statusBar " +
              (item.status === ITEM_STATUS_ENUM.SOLD.id ? "sold " : "") +
              (item.status === ITEM_STATUS_ENUM.UNAVAILABLE.id
                ? "unavailable "
                : "")
            }
          >
            {getStatus(item)}
          </div>
        </div>
      );
    } else if (item.ships) {
      statusSection = (
        <div className="absolute statusBarContainer">
          <div className="shippingIconContainer">
            <div className="shippingIcon whiteIcon" />
          </div>
        </div>
      );
    }

    // since we need a similar toolbar for the product page, we should refactor this into a component
    // for now, I've just copied this code into ProductPage.js

    let reserveButton = null;

    let watchClass = this.state.watched
      ? "itemIcon watchOpenIcon"
      : "itemIcon watchCloseIcon";
    let watchMessage = this.state.watched ? "Watching" : "Watch Item";
    let watchButton = (
      <div className="tooltip">
        <div
          onClick={() => this.toggleWatch(item)}
          className={watchClass}
          alt="Watch Item"
        />
        <span className="tooltiptext tooltip-bottom">{watchMessage}</span>
      </div>
    );

    if (
      AccountService.payload &&
      AccountService.payload.user === item.seller.uuid
    ) {
      reserveButton = (
        <div className="tooltip">
          <Link to={`/edit/item/${item.itemID}`}>
            <div className="itemIcon editIcon" alt="Edit Item" />
            <span className="tooltiptext tooltip-bottom">Edit Item</span>
          </Link>
        </div>
      );
      watchButton = null;
    } else if (item.status === 1) {
      reserveButton = (
        <div className="tooltip">
          <div
            onClick={() => ReserveService.handleReserve(item)}
            className="itemIcon reserveIcon"
            alt="Reserve Item"
          />
          <span className="tooltiptext tooltip-bottom">Reserve Item</span>
        </div>
      );
    }

    return (
      <div className="itemCard">
        {statusSection}
        <Link
          to={"/item/" + item.itemID}
          // target="_blank"
          // rel="noopener no referrer"
        >
          <div className="lucy-overlay">
            <div className="overlay-content">
              <div className="user_info">
                <img
                  className="avatar"
                  src={item.seller.profilePic}
                  alt="Avatar"
                />
                <h4>{item.seller.handle || "nohandle"}</h4>
              </div>
              <p className="desc">{item.description}</p>
              <div className="marginTopMedium marginBottomMedium">
                <h4>Size: {SIZE_ENUM[item.size].text}</h4>
              </div>
              <div className="more_info">
                <div className="daysAgo">{daysAgoLabel(item.listDate)}</div>
                see item
              </div>
            </div>
          </div>
          <img
            className="product"
            src={createThumbnailURL(item.images[0])}
            alt="Product"
          />
        </Link>

        <div className="info">
          <span className="textLeft">${item.sellersPrice}</span>
          <span className="right">
            {watchButton}

            <Link
              to={"/item/" + item.itemID + "?focusComments=true"}
              // target="_blank"
              // rel="noopener no referrer"
            >
              <div className="tooltip">
                <div className="itemIcon commentIcon" alt="Comments" />
                <span className="tooltiptext tooltip-bottom">Comments</span>
              </div>
            </Link>

            {reserveButton}
          </span>
        </div>
      </div>
    );
  }
}

export default ItemDisplay;
