export const CATEGORY_KEY_LIST = [1, 2, 3, 4, 5, 6];
export const MENS_CATEGORY_KEY_LIST = [21, 22, 23, 24, 25];

export const CATEGORY_ENUM = {
  1: {
    id: 1,
    text: "Tops",
    sizeGroups: [1, 2, 4]
  },
  2: {
    id: 2,
    text: "Bottoms",
    sizeGroups: [1, 2, 4]
  },
  3: {
    id: 3,
    text: "Skirts/Dresses",
    sizeGroups: [1, 2, 4]
  },
  4: {
    id: 4,
    text: "Outerwear",
    sizeGroups: [1, 2, 4]
  },
  5: {
    id: 5,
    text: "Shoes",
    sizeGroups: [3]
  },
  6: {
    id: 6,
    text: "Accessories",
    sizeGroups: [4]
  },
  21: {
    id: 21,
    text: "Tops",
    sizeGroups: [21, 24]
  },
  22: {
    id: 22,
    text: "Bottoms",
    sizeGroups: [21, 22, 24]
  },
  23: {
    id: 23,
    text: "Outerwear",
    sizeGroups: [21, 24]
  },
  24: {
    id: 24,
    text: "Shoes",
    sizeGroups: [23]
  },
  25: {
    id: 25,
    text: "Accessories",
    sizeGroups: [24]
  }
};

export const SIZE_GROUP_ENUM = {
  1: {
    id: 1,
    text: "General",
    sizes: [1, 2, 3, 4, 5]
  },
  2: {
    id: 2,
    text: "US Sizes",
    sizes: [7, 8, 9, 10, 11, 12, 13, 14, 29]
  },
  3: {
    id: 3,
    text: "Shoes",
    sizes: [15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27]
  },
  4: {
    id: 4,
    text: "Other",
    sizes: [28]
  },
  21: {
    id: 21,
    text: "Men's General",
    sizes: [101, 102, 103, 104, 105, 106]
  },
  22: {
    id: 22,
    text: "Men's Pants Waist",
    sizes: [121, 122, 123, 124, 125, 126, 127, 128, 129, 130]
  },
  23: {
    id: 23,
    text: "Men's Shoes",
    sizes: [141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153]
  },
  24: {
    id: 24,
    text: "Men's Other",
    sizes: [161]
  }
};

export const SIZE_ENUM = {
  1: {
    text: "XS",
    id: 1
  },
  2: {
    text: "S",
    id: 2
  },
  3: {
    text: "M",
    id: 3
  },
  4: {
    text: "L",
    id: 4
  },
  5: {
    text: "XL+",
    id: 5
  },
  7: {
    text: "0",
    id: 7
  },
  8: {
    text: "2",
    id: 8
  },
  9: {
    text: "4",
    id: 9
  },
  10: {
    text: "6",
    id: 10
  },
  11: {
    text: "8",
    id: 11
  },
  12: {
    text: "10",
    id: 12
  },
  13: {
    text: "12",
    id: 13
  },
  14: {
    text: "14",
    id: 14
  },
  29: {
    text: "16+",
    id: 29
  },
  15: {
    text: "5",
    id: 15
  },
  16: {
    text: "5½",
    id: 16
  },
  17: {
    text: "6",
    id: 17
  },
  18: {
    text: "6½",
    id: 18
  },
  19: {
    text: "7",
    id: 19
  },
  20: {
    text: "7½",
    id: 20
  },
  21: {
    text: "8",
    id: 21
  },
  22: {
    text: "8½",
    id: 22
  },
  23: {
    text: "9",
    id: 23
  },
  24: {
    text: "9½",
    id: 24
  },
  25: {
    text: "10",
    id: 25
  },
  26: {
    text: "10½",
    id: 26
  },
  27: {
    text: "11+",
    id: 27
  },
  28: {
    text: "OS",
    display: "One Size",
    id: 28
  },
  101: {
    text: "XS",
    id: 101
  },
  102: {
    text: "S",
    id: 102
  },
  103: {
    text: "M",
    id: 103
  },
  104: {
    text: "L",
    id: 104
  },
  105: {
    text: "XL",
    id: 105
  },
  106: {
    text: "XXL+",
    id: 106
  },
  121: {
    text: "W26/W27",
    id: 121
  },
  122: {
    text: "W28/W29",
    id: 122
  },
  123: {
    text: "W30/W31",
    id: 123
  },
  124: {
    text: "W32/W33",
    id: 124
  },
  125: {
    text: "W34/W35",
    id: 125
  },
  126: {
    text: "W36/W37",
    id: 126
  },
  127: {
    text: "W38/W39",
    id: 127
  },
  128: {
    text: "W40/W41",
    id: 128
  },
  129: {
    text: "W42/W43",
    id: 129
  },
  130: {
    text: "W44+",
    id: 130
  },
  141: {
    text: "7",
    id: 141
  },
  142: {
    text: "7½",
    id: 142
  },
  143: {
    text: "8",
    id: 143
  },
  144: {
    text: "8½",
    id: 144
  },
  145: {
    text: "9",
    id: 145
  },
  146: {
    text: "9½",
    id: 146
  },
  147: {
    text: "10",
    id: 147
  },
  148: {
    text: "10½",
    id: 148
  },
  149: {
    text: "11",
    id: 149
  },
  150: {
    text: "11½",
    id: 150
  },
  151: {
    text: "12",
    id: 151
  },
  152: {
    text: "12½",
    id: 152
  },
  153: {
    text: "13+",
    id: 153
  },
  161: {
    text: "OS",
    display: "One Size",
    id: 161
  }
};

export const ITEM_STATUS_ENUM = {
  AVAILABLE: {
    id: 1,
    text: "Available"
  },
  RESERVED: {
    id: 2,
    text: "Reserved"
  },
  SOLD: {
    id: 3,
    text: "Sold"
  },
  REMOVED: {
    id: 4,
    text: "Removed"
  },
  UNAVAILABLE: {
    id: -1,
    text: "Unavailable" // if the seller paused their shop.
  }
};

export const RESERVATION_STATUS = {
  ACTIVE: 1,
  COMPLETED: 2,
  CANCELED: 3
};

export const SORT_ENUM = {
  FEATURED: {
    id: 1,
    text: "Featured"
  },
  FOLLOWING: {
    id: 2,
    text: "Following"
  },
  LOWEST: {
    id: 3,
    text: "Lowest Price"
  },
  HIGHEST: {
    id: 4,
    text: "Highest Price"
  },
  POPULAR: {
    id: 5,
    text: "Popular"
  }
};

export const REVIEW_TYPE = {
  BUYER: 1, // reviews left for buyers
  SELLER: 2 // reviews left for sellers
};

export const ALERT_LINK_TYPE_ENUM = {
  REVIEW_LINK: 1,
  ITEM_LINK: 2,
  USER_LINK: 3,
  CONTACT_LINK: 4,
  COMMENT_LINK: 5
};
