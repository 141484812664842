import React from "react";
import PageComponent from "../../components/PageComponent";
import Feed from "../../components/Feed";
import styles from "./ProfilePage.module.scss";
import { Link } from "react-router-dom";
import AccountService from "../../services/AccountService";
import LoadingScreen from "../../components/LoadingScreen";
import { requestUser, renderWithBreaks } from "../../lib/library";
import ErrorPageMessage from "../../components/ErrorPageMessage";
import StatusButton from "../../components/ui/StatusButton";
import UserListModal from "../../components/modals/UserListModal";

class ProfilePage extends PageComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isTogglingFollow: false,
      showFollow: false
    };
  }

  componentDidUpdate() {
    if (this.handle !== this.props.handle) {
      this.handle = this.props.handle;
      this.fetchData();
    }
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.props.handle) {
      this.handle = this.props.handle;
      this.fetchData();
    } else {
      AccountService.addEventListener(this);
      if (AccountService.payload) {
        this.fetchData();
      }
    }
  }

  toggleFollow = async () => {
    AccountService.runWhenLoggedIn(async () => {
      let newFollowState = !this.state.user.isFollowing;
      this.setState({
        isTogglingFollow: true
      });

      let result = await requestUser(
        "post",
        `/user/${this.state.user.uuid}/follow`,
        true,
        {
          shouldFollow: newFollowState
        }
      );

      if (!result.success) {
        alert(result.message);
        this.setState({
          isTogglingFollow: false
        });
      } else {
        let user = { ...this.state.user };
        user.isFollowing = newFollowState;
        this.setState({
          isTogglingFollow: false,
          user
        });
      }
    });
  };

  renderButton = () => {
    if (!this.isMyProfilePage()) {
      return (
        <StatusButton
          className="primaryButton smallButton center"
          onClick={this.toggleFollow}
          label={this.state.user.isFollowing ? "unfollow" : "follow"}
          loading={this.state.isTogglingFollow}
        />
      );
    } else {
      return (
        <Link to="/profile/edit">
          <button className="primaryButton smallButton center">
            Edit Profile
          </button>
        </Link>
      );
    }
  };

  componentWillUnmount() {
    if (this.props.handle) return;
    AccountService.removeEventListener(this);
  }

  onNotification = notification => {
    if (notification.action === "login") {
      this.fetchData();
    }
  };

  isMyProfilePage = () => {
    return this.props.handle == AccountService.state.userState.handle;
  };

  fetchData = async () => {
    let result = await requestUser(
      "get",
      `/userhandle/${this.props.handle}`,
      AccountService.state.loggedIn,
      false
    );
    if (!result.success) {
      this.setState({
        loading: false,
        message: "Could not load this profile at this time."
      });
    }

    this.setState({
      loading: false,
      user: result.item
    });
  };

  copyLink = () => {
    const el = document.createElement("textarea");
    el.value = window.location.href;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    // const property = document.getElementById("linkCopied");
    // property.style.display = "";
    // property.innerHTML = "Link Copied &#10004";
  };

  viewFollowers = () => {
    this.setState({
      showFollow: "followers"
    });
  };

  viewFollowing = () => {
    this.setState({
      showFollow: "following"
    });
  };

  hideUserList = () => {
    this.setState({
      showFollow: false
    });
  };

  render() {
    if (this.state.loading) {
      return <LoadingScreen />;
    } else if (this.state.message) {
      return <ErrorPageMessage message={this.state.message} />;
    }
    let user = this.state.user;
    let selectID = "";
    if (this.props.handle) {
      selectID = this.props.handle;
    } else if (AccountService.payload && AccountService.payload.user) {
      selectID = AccountService.payload.user;
    }

    let myPic = user.profilePic;
    if (myPic.includes("/thumbnails")) {
      // TODO: implement a way to get the localOriginal URL or ID from the backend.
      myPic = myPic.replace("thumbnails", "images");
      // set the originalID as well.
    }

    return (
      <div className="container dynamicHeaderSpacingXLarge">
        {this.state.showFollow ? (
          <UserListModal
            show={this.state.showFollow}
            id={this.state.user.uuid}
            title="Followers"
            onExit={this.hideUserList}
          />
        ) : null}

        <div className={styles.profileDataContainer}>
          <div></div>
          <div className={styles.profileInnerContainer}>
            <div className={styles.profileImage}>
              <img alt="" src={myPic} />
            </div>
            <div className={styles.userDataContainer}>
              <div className={styles.userData}>
                <div className={styles.userDataRow + " marginBottomSmall"}>
                  <h3 className="marginRightMedium">{user.handle}</h3>

                  <div className={"tooltip " + styles.userImageContainer}>
                    <div className="defaultIcon statsIcon disabledIcon" />
                    <span className="tooltiptext tooltip-bottom">
                      Coming Soon
                    </span>
                  </div>
                  {/* {this.renderSettingStatIcon()} */}
                  {/* <div
                    className={"tooltip headerTooltip " + styles.toolTipStyle}
                    onClick={this.copyLink}
                  >
                    <img src="/share.svg" alt="Copy Link" />
                    <span id="linkCopied" className="tooltiptext tooltip-bott">
                      Copy Link
                    </span>
                  </div> */}
                </div>
                <div className="flexBox marginBottomSmall">
                  <div className="marginRightLarge textLink">
                    <a onClick={this.viewFollowers}>
                      <span className="h4">{user.numFollowers}</span> followers
                    </a>
                  </div>
                  <div className="textLink">
                    <a onClick={this.viewFollowing}>
                      <span className="h4">{user.numFollowing}</span> following
                    </a>
                  </div>
                </div>
                <p>{renderWithBreaks(user.caption)}</p>
                {!user.school.displayName ? (
                  <h4>{user.school.displayName}</h4>
                ) : (
                  <h4>{user.locationLabel}</h4>
                )}
              </div>
              <div className={"table fullheight " + styles.autoHeight}>
                <div className={styles.saveButton + " td vertMiddle textRight"}>
                  {this.renderButton()}
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div className={styles.listingContainer}>
          <div className="separatorLine marginBottomSmall"></div>
          <div className={styles.heading}>
            <h3 className="marginRightXLarge"></h3>
            <h3 className={styles.soldColor}></h3>
          </div>
          <Feed type="user" selectID={selectID} />
        </div>
      </div>
    );
  }
}

export default ProfilePage;
