import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
// import awsexports from "./aws-exports";
import * as serviceWorker from "./serviceWorker";

const loc = window.location.href;
if (
  loc.startsWith("http://www.nextbest.com") ||
  loc.startsWith("http://nextbest.com")
) {
  window.location =
    "https://nextbest.com" +
    window.location.pathname +
    window.location.search +
    window.location.hash;
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
