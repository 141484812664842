import React, { Component } from "react";
import PageComponent from "../../components/PageComponent";
import styles from "./Notifications.module.scss";
import { daysAgoLabel, createImageURL } from "../../lib/library";
import { Link } from "react-router-dom";
import HeaderService from "../../services/HeaderService";
import MessageService from "../../services/MessageService";

export const ALERT_TYPES = {
  MESSAGE: 1, // This user has sent you a message.
  FOLLOW: 2, // A new user has followed you.
  RESERVE_ITEM: 3, // An item you are selling has been reserved
  CANCEL_ITEM: 4, // An reserved item you are selling has been canceled
  PRICE_UPDATE: 5, // An item you are watching has changed in price
  COMMENT: 6, // An user as commented on an item you are selling
  COMMENT_REPLY: 7, // A user has replied to a comment you have posted on.
  FEATURE: 8, // NextBest has deployed this cool new feature!
  MARK_AS_PAID: 9
};

class AlertTemplate extends Component {
  render() {
    return (
      <div className={styles.alertContainer}>
        <div className={styles.innerAlertContainer}>
          <div className={styles.imageContainer}>
            <img alt="" 
              src={
                this.props.displayImage.startsWith("http")
                  ? this.props.displayImage
                  : createImageURL(this.props.displayImage)
              }
            />
          </div>
          <div className={styles.contentContainer}>
            <Link
              className="textLink"
              to={this.props.handleLink}
              onClick={HeaderService.toggleAlerts}
            >
              <div className={styles.username}>{this.props.handle}</div>
            </Link>
            <div className={styles.lowerBody}>
              <div
                className={
                  styles.message + (this.props.seen ? "" : " boldText")
                }
              >
                {this.props.message}
              </div>
              <div className={styles.time}>
                {daysAgoLabel(this.props.eventDate)}
              </div>
            </div>
          </div>
          <div className={styles.alertButton}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

class Alert extends PageComponent {
  renderCancelAlert() {
    if (this.props.alert.isSeller) {
      return this.renderItemByUserAlert(
        "has canceled your reservation.",
        "See Item"
      );
    } else {
      return this.renderItemByUserAlert(
        "has canceled their reservation.",
        "See Item"
      );
    }
  }

  renderReserveAlert() {
    return this.renderItemByUserAlert("has reserved your item!", "See Item");
  }

  renderMarkAsPaidAlert() {
    let alert = this.props.alert;
    let buttonText = "Message";
    let message = `has marked ${alert.numItems} reservation${
      alert.numItems > 1 ? "s" : ""
    } as complete.`;
    return (
      <AlertTemplate
        eventDate={alert.eventDate}
        message={message}
        handle={alert.handle}
        handleLink={"/user/" + alert.handle}
        displayImage={alert.displayImage}
        seen={alert.seen}
      >
        <button
          onClick={() => {
            MessageService.addConversation({
              uuid: alert.fromUserID,
              profilePic: alert.displayImage,
              handle: alert.handle
            });
            HeaderService.toggleAlerts();
          }}
          className="tinyButton primaryButton"
        >
          {buttonText}
        </button>
      </AlertTemplate>
    );
  }

  // Alert with an item picture
  // A user on the handle.
  // A button that has the buttonText
  renderItemByUserAlert(message, buttonText) {
    let alert = this.props.alert;
    return (
      <AlertTemplate
        eventDate={alert.eventDate}
        message={message}
        handle={alert.handle}
        handleLink={"/user/" + alert.handle}
        displayImage={alert.displayImage}
        seen={alert.seen}
      >
        <Link to={"/item/" + alert.itemID} onClick={HeaderService.toggleAlerts}>
          <button className="tinyButton primaryButton">{buttonText}</button>
        </Link>
      </AlertTemplate>
    );
  }

  render() {
    switch (this.props.alert.alertType) {
      case ALERT_TYPES.RESERVE_ITEM:
        return this.renderReserveAlert();
      case ALERT_TYPES.CANCEL_ITEM:
        return this.renderCancelAlert();
      case ALERT_TYPES.MARK_AS_PAID:
        return this.renderMarkAsPaidAlert();
      default:
        return (
          <div className={styles.alertContainer}>
            <div className={styles.innerAlertContainer}>
              <div className={styles.imageContainer}>
                <img alt="" />
              </div>
              <div className={styles.contentContainer}>
                <div className={styles.username}>{this.props.userName}</div>
                <div className={styles.lowerBody}>
                  <div className={styles.message}>{this.props.message}</div>
                  <div className={styles.time}>{this.props.time}</div>
                </div>
              </div>
              <div className={styles.alertButton}>
                <button className="tinyButton primaryButton">Message</button>
              </div>
            </div>
          </div>
        );
    }
  }
}

export default Alert;
