import PageComponent from "../../components/PageComponent";
import React from "react";
import HeaderService from "../../services/HeaderService";
import SplashLocationSection from "./SplashLocationSection";
import SectionHeader from "../../components/header/SectionHeader";
import { constructLocation } from "../../lib/library";
import styles from "./LandingPage.module.scss";
import FooterService from "../../services/FooterService";
import TriInfoSection from "./TriInfoSection";
import { Redirect } from "react-router-dom";
import AccountService from "../../services/AccountService";
import { SCHOOLS_LOCATIONS } from "../../lib/const";

class LandingPage extends PageComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    HeaderService.showTransparentMode();
    FooterService.hideMarginTop();
  }

  componentWillUnmount() {
    HeaderService.showNormalMode();
    FooterService.showMarginTop();
  }

  goToUCLA = () => {
    this.goToCampus(
      SCHOOLS_LOCATIONS.UCLA.lat,
      SCHOOLS_LOCATIONS.UCLA.lng,
      SCHOOLS_LOCATIONS.UCLA
    );
  };

  goToCornell = () => {
    this.goToCampus(
      SCHOOLS_LOCATIONS.CORNELL.lat,
      SCHOOLS_LOCATIONS.CORNELL.lng,
      SCHOOLS_LOCATIONS.CORNELL
    );
  };

  goToCampus = async (lat, lng, lastSelectedSuggestion) => {
    window.localStorage.setItem("lastLat", lat);
    window.localStorage.setItem("lastLng", lng);

    let location = await constructLocation(lat, lng, lastSelectedSuggestion);
    location.setDate = new Date() - 0;
    window.localStorage.setItem("shoppingLocation", JSON.stringify(location));
    this.setState({
      locationEntered: true
    });
    // The purpose of this state is to make sure the user returns
    // to the explore page if they click the nextbest logo on this tab.
    // If the user opens a new tab, they'll go back to the landing page.
    AccountService.setState({
      hasEnteredLocation: true
    });
  };

  render() {
    if (this.state.locationEntered) {
      return <Redirect to="/shop" />;
    }
    return (
      <div>
        <SplashLocationSection />
        <div className="marginBottomXLarge"></div>
        {/* <TriInfoSection
          firstImage="photos/nextbestshop_splash0.jpg"
          secondImage="photos/nextbestshop_splash1.jpg"
          title={
            <h2>
              Or check out our <br />
              handpicked items ✌️
            </h2>
          }
          subtitle={<span>We also sell curated pre-loved items online.</span>}
          button={
            <a href="https://nextbestshop.com">
              <button className="primaryButton mediumButton scalableButton">
                <span>Shop Now</span>
              </button>
            </a>
          }
        /> */}
        <TriInfoSection
          firstImage="/photos/college_banners/ucla.jpg"
          firstImageHandler={this.goToUCLA}
          secondImage="/photos/college_banners/cornell.jpg"
          secondImageHandler={this.goToCornell}
          title={
            <span>
              Campuses
              <br /> we are on now
            </span>
          }
          smallTitle="Campuses we are on now"
          subtitle={
            <span>
              We make it easy for college students to find and cycle all things
              fashion.
            </span>
          }
        />

        <div className={"centerSection smallSection"}>
          <div className="marginBottomXLarge"></div>
          <SectionHeader
            title="🌎 &nbsp; Founded for the Environment"
            subtitle={
              <div>
                Reduce textile waste, packaging waste, and shipping emmissions.
                <br />
                <br />
                Read more coming soon.
              </div>
            }
            spacing="marginBottomXLarge"
          />
        </div>
      </div>
    );
  }
}

export default LandingPage;
