import React from "react";
import PageComponent from "../components/PageComponent";
import { Link } from "react-router-dom";
import Filters from "../components/Filters";
import Feed from "../components/Feed";
import "./ExplorePage.scss";
import { SORT_ENUM } from "../lib/itemEnums";
import SectionHeader from "../components/header/SectionHeader";
import TriInfoSection from "./landing/TriInfoSection";
import LocationModal from "../components/modals/LocationModal";
import {
  requestUser,
  constructLocation,
  createLocationLabel
} from "../lib/library";
import URLAnchorService from "../services/URLAnchorService";
import AccountService from "../services/AccountService";

const DEFAULT_SORT_ENUM = SORT_ENUM.FEATURED.id;

class ExplorePage extends PageComponent {
  constructor(props) {
    super(props);

    this.state = {
      showLocation: false,
      isUpdatingLocation: false,
      shopLocation: this.getInitialShopLocation(),
      categories: [],
      size_groups: [1, 2, 3, 4],
      sizes: []
    };
  }

  getShopLocation() {
    if (this.props.userState) {
      return this.props.userState.shopLocation;
    } else {
      return this.state.shopLocation;
    }
  }

  getInitialShopLocation() {
    if (this.props.userState) {
      return this.props.userState.shopLocation;
    } else {
      let shoppingLocation = window.localStorage.getItem("shoppingLocation");
      if (shoppingLocation) {
        return JSON.parse(shoppingLocation);
      }
    }
    return false;
  }

  getShopLocationLabel() {
    let shopLocation = this.getShopLocation();
    if (shopLocation) {
      return createLocationLabel(shopLocation);
    } else {
      return "Earth";
    }
  }

  componentWillUnmount() {
    URLAnchorService.clearHash();
  }

  applyText() {
    return "Sell your clothes";
  }

  renderApplyButton() {
    if (this.props.userState) {
      return (
        <Link to="/sell">
          <button className="primaryButton mediumButton">
            <span>{this.applyText()}</span>
          </button>
        </Link>
      );
    } else {
      return (
        <button
          onClick={e => {
            window.location = "/sell";
          }}
          className="primaryButton mediumButton"
        >
          <span>{this.applyText()}</span>
        </button>
      );
    }
  }

  renderSellLink() {
    if (this.props.userState) {
      return (
        <Link to="/sell" title="Sell Link">
          Sell →
        </Link>
      );
    } else {
      return <a href="/sell">Sell →</a>;
    }
  }

  handleSort = e => {
    let updates = [
      {
        action: "remove",
        key: "page"
      }
    ];
    if (e.target.value == DEFAULT_SORT_ENUM) {
      updates.push({
        action: "remove",
        key: "sortKey"
      });
    } else {
      updates.push({
        action: "set",
        key: "sortKey",
        value: e.target.value
      });
    }
    URLAnchorService.updateKeyValues(updates);
  };

  getSortKey = () => {
    return URLAnchorService.getHashValue("sortKey", DEFAULT_SORT_ENUM);
  };

  getSizes = () => {
    return URLAnchorService.getHashArray("sizes", []).map(a => parseInt(a));
  };

  getCategories = () => {
    return URLAnchorService.getHashArray("categories", []).map(a =>
      parseInt(a)
    );
  };

  handleSelect = (value, content) => {
    let isSize = content === "size";
    let filterType = isSize ? this.getSizes() : this.getCategories();

    if (typeof value === "object" && !Array.isArray(value)) {
      let new_value = value.id;
      value = new_value;
    }

    let newCat = [];

    if (value === "All Sizes" || value === "All Categories") {
      newCat = [];
    } else {
      let i = filterType.indexOf(value);
      if (i === -1) {
        newCat = filterType.concat(value);
      } else {
        newCat = [...filterType];
        newCat.splice(i, 1);
      }
    }

    let updates = [
      {
        action: "remove",
        key: "page"
      }
    ];

    if (isSize) {
      if (newCat.length) {
        updates.push({
          action: "set",
          key: "sizes",
          value: newCat
        });
      } else {
        updates.push({
          action: "remove",
          key: "sizes"
        });
      }
    } else {
      if (newCat.length) {
        updates.push({
          action: "set",
          key: "categories",
          value: newCat
        });
      } else {
        updates.push({
          action: "remove",
          key: "categories"
        });
      }
    }

    URLAnchorService.updateKeyValues(updates);
  };

  handleChangeShopLocation = async () => {
    // const result = await requestUser("get", "/state", true, { ip: true });
    // console.log(result);
    this.setState({
      showLocation: true
    });
  };

  hideLocation = () => {
    this.setState({
      showLocation: false,
      isUpdatingLocation: false
    });
  };

  handleEnterLocation = async (lat, lng, suggestion) => {
    console.log(suggestion);
    let shopLocation = await constructLocation(lat, lng, suggestion);
    if (this.props.userState) {
      this.setState({
        isUpdatingLocation: true
      });
      const response = await requestUser("post", "/user", true, {
        shopLocation
      });

      if (!response.success) {
        alert(response.message);
        this.setState({
          isUpdatingLocation: false
        });
        return;
      } else {
        let userState = { ...AccountService.state.userState };
        userState.shopLocation = shopLocation;
        AccountService.handleNewUserState(userState);
      }
    } else {
      window.localStorage.setItem(
        "shoppingLocation",
        JSON.stringify(shopLocation)
      );
    }
    this.setState({
      shopLocation,
      showLocation: false,
      isUpdatingLocation: false
    });
  };

  render() {
    return (
      <>
        <LocationModal
          title="Where do you want to shop?"
          show={this.state.showLocation}
          onEnterLocation={this.handleEnterLocation}
          onExit={this.hideLocation}
          isUpdating={this.state.isUpdatingLocation}
        />
        <div className="container dynamicHeaderSpacing">
          <TriInfoSection
            firstImage="photos/header1.jpg"
            secondImage="photos/header3.jpg"
            title={
              <div>
                Shopping locally, <br />
                made easy
              </div>
            }
            smallTitle={<div>Shopping locally, made easy</div>}
            subtitle={
              <span>
                Buy and sell apparel at your school. Cut out shipping and
                packaging.
              </span>
            }
            button={
              <Link to="/how_we_work">
                <button className="primaryButton mediumButton scalableButton">
                  <span>How We Work</span>
                </button>
              </Link>
            }
          />
          <div className="separatorLine marginTopMedium marginBottomLarge"></div>
          <SectionHeader
            title={
              <>
                Explore
                <a className="link" onClick={this.handleChangeShopLocation}>
                  {this.getShopLocationLabel()}
                </a>
              </>
            }
            subtitle="Discover what your community is selling in your local area!"
            spacing="marginBottomXLarge"
          />
          <Filters
            handleSelect={this.handleSelect}
            onSort={this.handleSort}
            filterable={true}
            categories={this.getCategories()}
            sizegroups={this.state.size_groups}
            sizes={this.getSizes()}
            sortKey={this.getSortKey()}
          />
          <Feed
            type="school"
            selectID="1"
            filterable={true}
            shopLocation={this.getShopLocation()}
            sortKey={this.getSortKey()}
            categories={this.getCategories()}
            sizes={this.getSizes()}
            setRefresh={this.setRefresh}
          />
        </div>
      </>
    );
  }
}

export default ExplorePage;
