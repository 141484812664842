import { Component } from "react";
import AccountService from "../../services/AccountService";
import WebsocketManager from "../../lib/websocketManager";
import { request, isMobileDevice } from "../../lib/library";
import MessageService from "../../services/MessageService";

class ConversationHandlerComponent extends Component {
  constructor(props) {
    super(props);
    let messagerID = AccountService.userID;
    let otherUserID = this.props.conversation.userID;
    this.channelID =
      otherUserID < messagerID
        ? `${otherUserID}_${messagerID}`
        : `${messagerID}_${otherUserID}`;
    this.jumpToBottom = false;
    this.state = {
      loading: true,
      loaded: false,
      textContent: "",
      isTyping: false,
      lastSeen: false,
      bodyHeight: false,
      messages: []
    };
  }

  componentDidUpdate() {
    this.adjustScrollableArea();

    this.scrollToBottomOnConversationOpen();

    if (this.correctScrollPosition) {
      // Readjusts the scroll position as the user scrolls up.
      this.chatLog.scrollTo(
        0,
        this.chatLog.scrollHeight - this.correctScrollPosition
      );
      delete this.correctScrollPosition;
    } else if (this.jumpToBottom) {
      this.jumpToBottom = false;
      this.scrollToBottom();
    }
  }

  scrollToBottomOnConversationOpen = () => {
    let conversation = this.props.conversation;
    if (this.state.loaded === true) {
      // Fetch Data completed (works for refreshes)
      this.scrollToBottom();
      this.setState({
        loaded: "scrolled"
      });
    } else if (conversation.firstScroll) {
      // MessageService.openConversation has been called.
      this.scrollToBottom();
      if (!isMobileDevice()) {
        MessageService.onFirstScrollCompleted(conversation);
      }
    }
  };

  componentDidMount = () => {
    this.fetchData();

    this.handlerKey = Math.random().toString(36);
    WebsocketManager.subscribe(
      "newMessage",
      this.handlerKey,
      this.handleNewMessages
    );

    WebsocketManager.subscribe(
      "typing",
      this.handlerKey,
      this.handleTypingUpdate
    );
  };

  componentWillUnmount() {
    if (this.handlerKey) {
      WebsocketManager.unsubscribe("newMessage", this.handlerKey);
      WebsocketManager.unsubscribe("typing", this.handlerKey);
    }
  }

  fetchData = async () => {
    const response = await request(
      "get",
      `/chat/${this.props.conversation.userID}`,
      true,
      {}
    );

    if (!response.success) {
      // Could not load messages at this time.
      this.setState({
        loading: "failed"
      });
    } else {
      this.setState({
        loading: false,
        loaded: true,
        messages: response.items.reverse(),
        LastEvaluatedKey: response.LastEvaluatedKey
      });
    }
  };

  handleScrollToTheTop = async () => {
    if (this.state.LastEvaluatedKey !== false && !this.fetchingMore) {
      this.fetchingMore = true;
      const response = await request(
        "get",
        `/chat/${this.props.conversation.userID}`,
        true,
        {
          pk: this.state.LastEvaluatedKey.pk,
          sort: this.state.LastEvaluatedKey.sort
        }
      );

      if (!response.success) {
        // Could not load messages at this time.
      } else {
        this.correctScrollPosition = this.chatLog.scrollHeight;
        this.setState({
          messages: [...response.items.reverse(), ...this.state.messages],
          LastEvaluatedKey: response.LastEvaluatedKey
        });
      }

      this.fetchingMore = false;
    }
  };

  handleNewMessages = data => {
    if (this.channelID === data.channelID) {
      for (let message of this.state.messages) {
        if (data.tempID && message.tempID === data.tempID) {
          // Message was added by this tab.
          return;
        }
        if (data.item.sort === message.sort) {
          // Message already exists, there must be two active
          // websocket connections.
          return;
        }
      }
      let messages = [...this.state.messages];
      messages.push(data.item);
      this.jumpToBottom = true;
      this.setState({
        messages,
        isTyping: false
      });
    }
  };

  createMessageObject(message) {
    let messageDate = Date.now();
    let messagerID = AccountService.userID;
    return {
      pk: `chat_${this.channelID}`,
      sort: `message_${messageDate}_${messagerID}`,
      type: 1,
      messageDate,
      message,
      messagerID,
      tempID: Math.random().toString(36),
      messager: AccountService.payload
    };
  }

  get chatLog() {
    // We can't lazy instantiate because the values get outdated.
    return document.getElementById("chatLog_" + this.props.conversation.userID);
  }

  scrollToBottom() {
    if (this.chatLog) {
      this.chatLog.scrollTo(0, this.chatLog.scrollHeight);
      window.setTimeout(() => {
        if (this.chatLog) {
          this.chatLog.scrollTo(0, this.chatLog.scrollHeight);
        }
      }, 100);
    }
  }

  get isScrolledToTheBottom() {
    if (this.chatLog) {
      return (
        this.chatLog.scrollHeight ===
        this.chatLog.scrollTop + this.chatLog.clientHeight
      );
    } else {
      return false;
    }
  }

  handleTyping = e => {
    if (this.lastTypingState !== (e.target.value !== "")) {
      this.lastTypingState = e.target.value !== "";

      WebsocketManager.send(
        {
          action: "messageStatus",
          type: "typing",
          isTyping: this.lastTypingState,
          toUserID: this.props.conversation.userID
        },
        true
      );
    }

    this.setState({
      textContent: e.target.value
    });
  };

  handleTypingUpdate = data => {
    if (data.userID === this.props.conversation.userID) {
      this.jumpToBottom = this.isScrolledToTheBottom;
      this.setState({
        isTyping: data.isTyping
      });
    }
  };

  handleSubmitMessage = async () => {
    let messages = [...this.state.messages];
    let newMessage = this.createMessageObject(this.state.textContent);
    messages.push(newMessage);

    // tells reactJS to jump to the bottom on componentDidUpdate
    this.jumpToBottom = true;
    this.setState({
      messages,
      textContent: ""
    });

    /*
     * This is a HACK to fix a bug where adjustScrollableArea
     * is not adjust correctly (probably to early even on
     * componentDidUpdate).
     */
    window.setTimeout(() => {
      this.adjustScrollableArea();
    }, 10);

    this.lastTypingState = false;

    const response = await request(
      "post",
      `/chat/${this.props.conversation.userID}`,
      true,
      {
        message: this.state.textContent,
        tempID: newMessage.tempID
      }
    );

    if (!response.success) {
      console.error(response.message);
    }
  };
}

export default ConversationHandlerComponent;
