import AppService from "./AppService";
import React from "react";
import ReservationPopups from "../pages/reservation/ReservationPopups";

export const POPUP_TYPES = {
  CANCEL_RESERVE_MODAL: 1,
  EXTEND_RESERVE_MODAL: 2,
  MARK_ITEM_AS_SOLD_MODAL: 3,
  CANCEL_RESERVE_MODAL_SUCCESS: 4,
  EXTEND_RESERVE_MODAL_SUCCESS: 5,
  MARK_ITEM_AS_SOLD_MODAL_SUCCESS: 6,
  ADD_COLLEGE_MODAL: 7,
  ADD_EMAIL_MODAL: 8
};

class PopupServiceClass extends AppService {
  startingState() {
    return {
      ...super.startingState(),
      ...{
        modalToShow: 0,
        data: null,
        show: false
      }
    };
  }

  showCancelReserveModal = data => {
    this.setState({
      data: data,
      show: true,
      modalToShow: POPUP_TYPES.CANCEL_RESERVE_MODAL
    });
  };

  showExtendReserveModal = data => {
    this.setState({
      data: data,
      show: true,
      modalToShow: POPUP_TYPES.EXTEND_RESERVE_MODAL
    });
  };

  showMarkAsSoldModal = data => {
    // this.onSuccess = onSuccess;
    this.setState({
      data: data,
      show: true,
      modalToShow: POPUP_TYPES.MARK_ITEM_AS_SOLD_MODAL
    });
  };

  showAddCollegeModal = () => {
    this.setState({
      show: true,
      modalToShow: POPUP_TYPES.ADD_COLLEGE_MODAL
    });
  };

  showAddEmailModal = () => {
    this.setState({
      show: true,
      modalToShow: POPUP_TYPES.ADD_EMAIL_MODAL
    });
  };

  showSuccessModal = () => {
    switch (this.state.modalToShow) {
      case POPUP_TYPES.CANCEL_RESERVE_MODAL: {
        this.setState({
          modalToShow: POPUP_TYPES.CANCEL_RESERVE_MODAL_SUCCESS
        });
        break;
      }
      case POPUP_TYPES.EXTEND_RESERVE_MODAL: {
        this.setState({
          modalToShow: POPUP_TYPES.EXTEND_RESERVE_MODAL_SUCCESS
        });
        break;
      }
      case POPUP_TYPES.MARK_ITEM_AS_SOLD_MODAL: {
        this.notifyEventListeners({
          action: "markItemSold",
          handle: this.state.data.handle
        });
        this.setState({
          modalToShow: POPUP_TYPES.MARK_ITEM_AS_SOLD_MODAL_SUCCESS
        });
        break;
      }
    }
  };

  onExit = () => {
    this.setState({
      modalToShow: 0,
      show: false,
      data: null
    });
  };

  renderModal() {
    return (
      <ReservationPopups
        show={this.state.show}
        onExit={this.onExit}
        modalToShow={this.state.modalToShow}
        showSuccessModal={this.showSuccessModal}
        data={this.state.data}
      />
    );
  }
}

const PopupService = new PopupServiceClass();

export default PopupService;
