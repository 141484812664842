import React from "react";
import Select from "./Select";

import styles from "./Filters.module.scss";
import {
  CATEGORY_KEY_LIST,
  CATEGORY_ENUM,
  SORT_ENUM,
  SIZE_GROUP_ENUM,
  SIZE_ENUM
} from "../lib/itemEnums.js";

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: ["All"],
      sizes: ["All"]
    };
  }

  render() {
    let category_list = [];
    CATEGORY_KEY_LIST.forEach(key => {
      category_list.push(CATEGORY_ENUM[key].text);
    });

    let size_list = [];

    return (
      <div className={"standardWidth " + styles.filtersWidth}>
        <div className={styles.filters}>
          <select
            onChange={this.props.onSort}
            name="sort"
            className={styles.filtersSort}
            defaultValue={this.props.sortKey}
          >
            <option value={SORT_ENUM.FEATURED.id}>
              {SORT_ENUM.FEATURED.text}
            </option>
            <option value={SORT_ENUM.FOLLOWING.id}>
              {SORT_ENUM.FOLLOWING.text}
            </option>
            <option value={SORT_ENUM.LOWEST.id}>{SORT_ENUM.LOWEST.text}</option>
            <option value={SORT_ENUM.HIGHEST.id}>
              {SORT_ENUM.HIGHEST.text}
            </option>
            <option value={SORT_ENUM.POPULAR.id}>
              {SORT_ENUM.POPULAR.text}
            </option>
          </select>

          <Select
            options={{
              Presets: ["All Categories"]
              // Categories: {}
            }}
            type="headers"
            content="category"
            handleSelect={this.props.handleSelect}
            selected={this.props.categories}
          />

          <Select
            options={{
              Presets: ["All Sizes"] // , "My Sizes"]
              // Sizes: {}
            }}
            type="headers"
            content="size"
            handleSelect={this.props.handleSelect}
            selected={this.props.sizes}
            sizegroups={this.props.sizegroups}
          />

          {/* <p className={styles.num}>39 items</p> */}
        </div>
      </div>
    );
  }
}

export default Filters;
