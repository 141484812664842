import React, { Component } from "react";
import ConversationBody from "./ConversationBody";
import ConversationFooter from "./ConversationFooter";
import { preventScrolling, allowScrolling } from "../../lib/library";
import { Link } from "react-router-dom";
import SlidingPane from "react-sliding-pane";

class MessagePane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textContent: "",
      isPaneOpen: false,
      conversation: {
        userID: "gid-1099992518261434307955",
        opened: false,
        user: {
          handle: "rice.life",
          profilePic:
            "https://lh6.googleusercontent.com/-nt1uk8QpFjA/AAAAAAAAAAI/AAAAAAAAAAA/ACHi3rfPAUDIHD38irZmGziMbAXBrOvbFw/s96-c/photo.jpg",
          userID: "gid-109999251826143430798"
        }
      }
    };
  }

  adjustScrollableArea = () => {
    let footer = document.getElementById(
      "messageConversationFooter_" + this.state.conversation.userID
    );
    if (footer) {
      let headerHeight = document.getElementsByClassName(
        "slide-pane__header"
      )[0].clientHeight;
      let footerHeight = footer.clientHeight;
      document.getElementById(
        "chatLog_" + this.state.conversation.userID
      ).style.height = window.innerHeight - footerHeight - headerHeight + "px";
    }
  };

  componentDidUpdate() {
    if (this.state.isPaneOpen) {
      preventScrolling();
      this.adjustScrollableArea();
    } else {
      allowScrolling();
    }
  }

  handleTyping = e => {
    this.setState({
      textContent: e.target.value
    });
  };

  hidePane = () => {
    this.setState({
      isPaneOpen: false
    });
  };

  renderTitle() {
    let conversation = this.state.conversation;
    return (
      <div className="table">
        <div className="tr">
          <div className="td vertMiddle">
            <img
              src={conversation.user.profilePic}
              className="messagePaneProfileImage"
            />
          </div>
          <div className="td vertMiddle">
            <Link to={"/user/" + conversation.userID} onClick={this.hidePane}>
              {conversation.user.handle}
            </Link>
          </div>
        </div>
      </div>
    );
  }

  renderContent() {
    return (
      <div className="messagePaneContainer">
        <div id="messagePaneBodyContainer">
          <ConversationBody conversation={this.state.conversation} />
        </div>
        <ConversationFooter
          conversation={this.state.conversation}
          handleTyping={this.handleTyping}
          textContent={this.state.textContent}
        />
      </div>
    );
  }

  render() {
    return (
      <SlidingPane
        className="some-custom-class"
        overlayClassName="some-custom-overlay-class"
        isOpen={this.state.isPaneOpen}
        title={this.renderTitle()}
        width="100%"
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          this.setState({ isPaneOpen: false });
        }}
      >
        {this.renderContent()}
      </SlidingPane>
    );
  }
}

export default MessagePane;
