import React from "react";
import PageComponent from "../../components/PageComponent";
import AccountService from "../../services/AccountService";
import { Link } from "react-router-dom";

class ListSuccessPage extends PageComponent {
  render() {
    return (
      <div className="container dynamicHeaderSpacingLarge">
        <div className="standardWidth textCenter">
          <h2>Success!</h2>

          <h3> Your new items are now live on your store.</h3>
          <div className="marginTopLarge">
            <Link to="/sell">
              <button className="normalButton smallButton">
                List more items
              </button>
            </Link>
            <Link to={`/user/${AccountService.state.userState.handle}`}>
              <button className="normalButton smallButton">
                Go to my store
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default ListSuccessPage;
