import React, { Component } from "react";
// import MessageCircle from "./MessageTab";
import MessageService from "../../services/MessageService";
import MessageCircleConversation from "./MessageCircleConversation";
import styles from "./MessageChatCircles.scss";
import { isMobileDevice, request, isOnline } from "../../lib/library";
import MessageToList from "./MessageToList";
import AccountService from "../../services/AccountService";

class MessageChatCircle extends Component {
  render() {
    let conversation = this.props.conversation;
    return (
      <button
        className="messageChatCircle"
        onClick={() => {
          MessageService.toggleConversation(conversation);
        }}
      >
        {conversation.notifications ? (
          <div className="chatNotificationsBadge">
            {conversation.notifications}
          </div>
        ) : null}

        {isOnline(conversation.user.lastSeen) ? (
          <div className="chatCircleOnlineBadge"></div>
        ) : null}
        <div
          className="chatCloseButton"
          onClick={e => {
            MessageService.removeConversation(conversation);
            e.stopPropagation();
          }}
        >
          <div className="chatCloseButtonIcon" />
        </div>
        <img
          src={conversation.user.profilePic}
          className="messageChatCircleProfilePic"
        />
        {conversation.opened ? <div className="chatSideArrow"></div> : null}
      </button>
    );
  }
}

class MessageChatCircles extends Component {
  constructor(props) {
    super(props);

    MessageService.attachParent(this);

    this.state = {
      ...{
        isShowingMessageList: false
      },
      ...MessageService.startingState()
    };
  }

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = async () => {
    let response = await request(
      "get",
      `/chat/${AccountService.userID}`,
      true,
      {
        action: "unread",
        users: MessageService.state.conversations
          .slice(0, 5)
          .map(conversation => {
            return conversation.userID;
          })
          .join(",")
      }
    );

    if (!response.success) {
      return;
    }

    let notificationsMap = {};
    for (let item of response.items) {
      notificationsMap[item.sort.split("_")[1]] = item;
    }

    let conversations = [...MessageService.state.conversations];

    for (let conversation of conversations) {
      if (notificationsMap[conversation.userID] !== undefined) {
        let item = notificationsMap[conversation.userID];
        conversation.user = item.user;
        conversation.lastActiveFetch = new Date();
        conversation.notifications = item.numUnread || 0;
        conversation.lastReadByOtherUser = item.lastReadByOtherUser;
      }
    }

    MessageService.setState({ conversations });
  };

  render() {
    if (isMobileDevice() || !AccountService.isLoggedIn) {
      return null;
    }

    return (
      <div>
        <div className="messageChatIconContainer">
          <div>
            <button
              onClick={MessageService.toggleMessageList}
              className="messageChatIcon"
            >
              <div className="chatIcon itemIcon"></div>
            </button>
            {MessageService.state.isShowingMessageList ? (
              <div className="chatSideArrow"></div>
            ) : null}
          </div>
        </div>
        <div className="messageChatCircles">
          {MessageService.state.conversations
            .slice(0, 5)
            .reverse()
            .map(conversation => {
              return (
                <MessageChatCircle
                  key={conversation.userID}
                  conversation={conversation}
                />
              );
            })}
        </div>
        {MessageService.state.isShowingMessageList ? <MessageToList /> : null}
        {MessageService.state.conversations
          .slice(0, 5)
          .reverse()
          .map(conversation => {
            return (
              <MessageCircleConversation
                key={conversation.userID}
                conversation={conversation}
              />
            );
          })}
      </div>
    );
  }
}

export default MessageChatCircles;
