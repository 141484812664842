import React, { Component } from "react";
import { Link } from "react-router-dom";
import AccountService from "../../services/AccountService";
import HeaderService from "../../services/HeaderService";

class ProfilePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderAdminPortal() {
    if (this.props.userState.permissions >= 40) {
      return <Link to="/admin">Admin Portal</Link>;
    }
  }

  render() {
    if (this.props.show) {
      return (
        <div
          className="dropdown__content dropdown__menu"
          onMouseUp={e => {
            e.stopPropagation();
          }}
          onClick={HeaderService.toggleOffAll}
        >
          <div className="dropdown__filler"></div>
          <div className="dropdown__linkContainer">
            <div className="dropdown__filler"></div>
            <Link to={`/user/${AccountService.state.userState.handle}`}>
              My Profile
            </Link>
            <Link to="/watching">Watched Items</Link>
            {this.renderAdminPortal()}
            <Link to="/settings">Settings</Link>
            <span onClick={AccountService.handleLogOut} to="/logout">
              Logout
            </span>
            <div className="dropdown__filler"></div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default ProfilePopup;
