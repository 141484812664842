import React from "react";
import { CONSTANTS, requestUser } from "../lib/library";
import WebsocketManager from "../lib/websocketManager";
import AppService from "./AppService";
import OnboardModal from "../components/OnboardModal";
import AccountModal from "../components/AccountModal";
import { ym } from "../lib/analytics";

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

class AccountServiceClass extends AppService {
  startingState() {
    const jwts = window.localStorage.getItem(CONSTANTS.JWT_KEY);
    if (jwts) {
      const longTermJWT = jwts.split("|")[1];
      this.payload = parseJwt(longTermJWT);
    }
    let oldUserState = window.localStorage.getItem("userState");
    return {
      ...super.startingState(),
      ...{
        handle: window.localStorage.getItem("handle"),
        userState: oldUserState ? JSON.parse(oldUserState) : false,
        shouldShowAccountModal: this.shouldShowAccountModal(),
        loggedIn: jwts && true
      }
    };
  }

  gaInitialSetup(ReactGA) {
    this.ReactGA = ReactGA;
    this.updateAnalyticsSettings();
  }

  updateAnalyticsSettings() {
    if (this.isLoggedIn) {
      this.ReactGA.set({
        userID: this.userID
      });
      ym("setUserID", this.userID);
    }
  }

  get userID() {
    if (this.payload) {
      return this.payload.user;
    } else {
      return false;
    }
  }

  get isLoggedIn() {
    return !!this.payload;
  }

  attachParent(parent) {
    super.attachParent(parent);
    this.updateUserState();
  }

  runWhenLoggedIn(toRun) {
    if (this.state.loggedIn) {
      toRun();
    } else {
      this.pendingRun = toRun;
      this.showLoginModal();
    }
  }

  // Executed when the user logs in.
  runPendingActions() {
    WebsocketManager.open();
    if (this.pendingRun) {
      this.pendingRun();
      delete this.pendingRun;
    }
  }

  logOut = dontUpdate => {
    WebsocketManager.close();

    // Purpose of this line is to use it has a hack to trigger onStorage to log out other tabs
    window.localStorage.setItem(CONSTANTS.JWT_KEY, "");

    window.localStorage.clear();
    delete this.payload;
    delete this.pendingRun;
    this.updateAnalyticsSettings();

    if (!dontUpdate) {
      this.setState({ loggedIn: false, userState: false });
    }
  };

  handleNewUserState = (result, pendingStates = {}) => {
    if (result.loggedIn === false) {
      this.logOut();
    } else {
      window.localStorage.setItem("userState", JSON.stringify(result));
      this.setState({
        ...pendingStates,
        ...{
          userState: result
        }
      });
    }
  };

  shouldShowAccountModal = () => {
    if (CONSTANTS.PROTECTED_PATHS[window.location.pathname]) {
      return window.localStorage.getItem(CONSTANTS.JWT_KEY) ? false : true;
    }
    return false;
  };

  updateUserState = async () => {
    const result = await requestUser("get", "/state", true);
    if (result.success) {
      this.handleNewUserState(result);
    }
  };

  updateUserStateProperty = (propertyName, newValue = 0) => {
    let userState = { ...this.state.userState };
    userState[propertyName] = newValue;
    this.setState({ userState });
  };

  handleLogOut = async () => {
    const result = await requestUser("post", "/auth", true, {
      action: "logout"
    });
    if (result.success) {
      this.logOut(true);
      window.location = "/logout";
    } else {
      alert("Unable to log out at this time.");
    }
  };

  handleAccountModalExit = () => {
    if (this.shouldShowAccountModal()) {
      window.location = "/";
    }
    document.getElementsByTagName("body")[0].classList.remove("noscroll");
    this.setState({ shouldShowAccountModal: false });
  };

  handleSignInSuccess = async response => {
    try {
      const idToken = response.getAuthResponse().id_token;
      const jwts = await requestUser("post", "/auth", false, {
        action: "create",
        idToken
      });
      if (jwts.longTermJWT && jwts.shortTermJWT) {
        this.payload = parseJwt(jwts.longTermJWT);
        this.updateAnalyticsSettings();
        if (window.location.pathname === "/logout") {
          window.location = "/";
        } else {
          this.handleNewUserState(jwts.userState, {
            loggedIn: true,
            shouldShowAccountModal: false
          });
        }
        if (jwts.userState.handle) {
          this.runPendingActions();
        } else {
          ym("reachGoal", "onboard_signUp", 5);
        }
      } else {
        this.handleSignInFailure("Enter with Google failed. Try again later.");
      }
    } catch (e) {
      this.handleSignInFailure("Enter with Google failed :( Try again later.");
    }
  };

  handleSignInFailure = response => {
    if (response.error) {
      if (response.error != "popup_closed_by_user") {
        alert(
          "Google does not support signing in from a web view. Please sign in from a browser app."
        );
      }
    } else {
      alert(response);
    }
    alert(response);
    this.logOut();
  };

  showLoginModal = () => {
    this.setState({ shouldShowAccountModal: true });
  };

  handleSuccessfulOnboard = (handle, termsDate) => {
    let userState = { ...this.state.userState };
    userState.handle = handle;
    userState.termsDate = termsDate;
    userState.onboarded = false;
    this.setState({
      userState
    });
    ym("reachGoal", "onboard_handleProvided", 6);
  };

  onProfileDataChanged = newData => {
    if (newData.newJWT) {
      this.handlePhoneSuccess(newData.newJWT, newData.handle);
    } else {
      let userState = { ...this.state.userState };
      if (newData.handle) {
        userState.handle = newData.handle;
      }
      if (newData.profilePic) {
        userState.profilePic = newData.profilePic;
      }
      this.setState({
        userState
      });
    }
  };

  handlePhoneSuccess = (jwt, handle) => {
    let userState = { ...this.state.userState };
    if (handle) {
      userState.handle = handle;
    }
    window.localStorage.setItem(CONSTANTS.JWT_KEY, jwt);
    userState.hasSetupSeller = true;
    userState.hasSetupBuyer = true;
    userState.permissions = 20;
    this.setState({
      userState
    });
  };

  handleJumpIn = ignoreAnalytics => {
    let userState = { ...this.state.userState };
    userState.onboarded = true;
    this.setState({
      userState
    });
    if (!ignoreAnalytics) {
      ym("reachGoal", "onboard_jumpInButton", 3);
    }
    this.runPendingActions();
  };

  onboardExit = () => {
    if (this.state.userState.handle) {
      ym("reachGoal", "onboard_exitOutside", 1);
      this.handleJumpIn(true);
    } else if (
      window.confirm(
        "Exiting right now will log you out. Do you still want to exit?"
      )
    ) {
      this.handleLogOut();
    }
  };

  renderOnboard = () => {
    if (
      (this.state.userState &&
        this.state.userState.handle === false &&
        this.state.userState.termsDate === false) ||
      this.state.userState.onboarded === false
    ) {
      return (
        <OnboardModal
          onExit={this.onboardExit}
          onSuccessfulOnboard={this.handleSuccessfulOnboard}
          onJumpIn={this.handleJumpIn}
          isOnJumpInScreen={this.state.userState.onboarded === false}
        />
      );
    } else {
      return null;
    }
  };

  renderModal() {
    return <AccountModal show={this.state.shouldShowAccountModal} />;
  }
}

const AccountService = new AccountServiceClass();

export default AccountService;
