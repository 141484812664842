import React from "react";
import PageComponent from "../components/PageComponent";

class ComingSoonPage extends PageComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="container">
        <div className="loadingContainer">
          <p className="rippleStatusContainer textCenter">Coming Soon ☺ </p>
        </div>
      </div>
    );
  }
}

export default ComingSoonPage;
