import config from "../config";
import ReactGA from "react-ga";
import dateformat from "dateformat";
import React from "react";
import Geocode from "react-geocode";
import { ITEM_STATUS_ENUM } from "./itemEnums";

Geocode.setApiKey(config.GOOGLE_API_KEY);

const serialize = function (obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

export function isMobileDevice() {
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  );
}

/**
 * Works on legacy browser:
 * Converts the query params into an object
 * where all values is a string.
 */
export function getQueryStringValue(key, queryString = window.location.search) {
  return decodeURIComponent(
    queryString.replace(
      new RegExp(
        "^(?:.*[#&\\?]" +
          encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
          "(?:\\=([^&]*))?)?.*$",
        "i"
      ),
      "$1"
    )
  );
}

export async function api(method, path, params, pathURL) {
  params.method = method;
  params.cache = "no-cache";
  if (params.body) {
    params.body = JSON.stringify(params.body);
  }
  let url = pathURL + path;
  if (params.queryStringParameters) {
    url = url + "?" + serialize(params.queryStringParameters);
  }
  if (!params.headers) {
    params.headers = {};
  }
  if (method === "put" || method === "post") {
    params.headers["Content-Type"] = "application/json";
  }
  const response = await fetch(url, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers: params.headers,
    body: params.body // body data type must match "Content-Type" header
  });
  return await response.json();
}

export const CONSTANTS = {
  JWT_KEY: "allJWT",
  // Probably not the right place to put this here.
  PROTECTED_PATHS: {
    "/sell": true,
    "/profile": true,
    "/reserved": true,
    "/profile/edit": true
  }
};

export const createImageURL = imageID => {
  return (
    `https://${config.s3.BUCKET}.s3-${config.s3.REGION}.amazonaws.com/images/` +
    imageID
  );
};

export const createThumbnailURL = imageID => {
  return (
    `https://${config.s3.BUCKET}.s3-${config.s3.REGION}.amazonaws.com/thumbnails/` +
    imageID
  );
};

export const getAuthHeader = () => {
  const allJWT = window.localStorage.getItem(CONSTANTS.JWT_KEY);
  return `Bearer ${allJWT}`;
};

export async function requestUser(method, path, auth, body) {
  return await request(method, path, auth, body, config.apiGateway.USERS_URL);
}

export async function request(
  method,
  path,
  auth,
  body,
  pathURL = config.apiGateway.URL
) {
  try {
    const params = {};
    if (auth) {
      params.headers = {
        Authorization: getAuthHeader()
      };
    }
    if (body) {
      if (method === "get") {
        params.queryStringParameters = body;
      } else {
        params.body = body;
      }
    }
    const response = await api(method, path, params, pathURL);

    if (response.shortTermJWT) {
      let longTermJWT =
        response.longTermJWT ||
        window.localStorage.getItem(CONSTANTS.JWT_KEY).split("|")[1];
      window.localStorage.setItem(
        CONSTANTS.JWT_KEY,
        `${response.shortTermJWT}|${longTermJWT}`
      );
    }
    if (!response.success) {
      ReactGA.exception({
        description: `Response failure: ${method} ${path}`,
        fatal: false
      });
    }
    return response;
  } catch (e) {
    return {
      success: false,
      message: "Could not connect to the server at this time"
    };
  }
}

export const preventScrolling = () => {
  document.getElementsByTagName("body")[0].classList.add("unscrollable");
};

export const allowScrolling = () => {
  document.getElementsByTagName("body")[0].classList.remove("unscrollable");
};

export const getStatus = item => {
  switch (item.status) {
    case ITEM_STATUS_ENUM.AVAILABLE.id:
      return "";
    case ITEM_STATUS_ENUM.RESERVED.id:
      return "RESERVED";
    case ITEM_STATUS_ENUM.SOLD.id:
      return "SOLD";
    case ITEM_STATUS_ENUM.UNAVAILABLE.id:
      return "UNAVAILABLE";
    default:
      return "";
  }
};

export const getReservationStatus = item => {
  switch (item.status) {
    case 1:
      return "RESERVED";
    case 2:
      return "SOLD";
    default:
      return "CANCELED";
  }
};

export const daysAgoLabel = listDate => {
  const today = Date.now();
  const difference = today - listDate;
  const minute = 1000 * 60;
  const hour = minute * 60;
  const day = hour * 24;
  if (difference < day) {
    if (difference < hour) {
      if (difference / minute < 1) {
        return "Just now";
      }
      return Math.round(difference / minute) + "m";
    } else {
      return Math.round(difference / hour) + "h";
    }
  } else {
    let days = Math.round(difference / day);
    if (days < 15) {
      return Math.round(difference / day) + "d";
    } else {
      return dateformat(listDate, "mmm d");
    }
  }
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const renderWithBreaks = message => {
  if (!message) {
    return null;
  }
  let lines = message.split("\n");
  let render = [];
  for (let i = 0; i < lines.length; i++) {
    render.push(lines[i]);
    if (i < lines.length - 1) {
      render.push(<br key={i} />);
    }
  }
  return render;
};

export const getBrowser = () => {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) !== -1
  ) {
    return "Opera";
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return "Firefox";
  } else if (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    !!document.documentMode === true
  ) {
    //IF IE > 10
    return "IE";
  } else {
    return "unknown";
  }
};

export const isOnline = lastSeenDate => {
  const NUM_MIN_CONSIDERED_ONLINE = 5;

  let timeSinceLastSeen = new Date() - lastSeenDate;
  return timeSinceLastSeen < 1000 * 60 * NUM_MIN_CONSIDERED_ONLINE;
};

// import Sortable from "react-sortablejs";
export const constructLocationFromLatLng = (lat, lng) => {
  return new Promise((resolve, reject) => {
    Geocode.fromLatLng(lat, lng).then(
      response => {
        const address = response.results[0].formatted_address;
        let parts = address.split(",");
        let stateZip = parts[2].trim().split(" ");

        resolve({
          addressLine1: parts[0],
          addressLine2: false,
          zip: stateZip[1],
          city: parts[1].trim(),
          state: stateZip[0],
          lat: lat,
          long: lng,
          isUni: false
        });
      },
      error => {
        reject(error);
      }
    );
  });
};

// suggestion is constructed from Google Places API
export const constructLocationFromSuggestion = (lat, long, suggestion) => {
  let isUni = suggestion.types.indexOf("university") != -1;
  let addressParts = suggestion.structured_formatting.secondary_text
    .split(",")
    .map(s => {
      return s.trim();
    });
  let city;
  let state;
  let addressLine1 = false;

  if (
    suggestion.types.indexOf("locality") != -1 &&
    suggestion.types.indexOf("political") != -1
  ) {
    city = suggestion.structured_formatting.main_text;
    state = addressParts[0];
  } else {
    addressLine1 = suggestion.structured_formatting.main_text;
    city = addressParts[0];
    state = addressParts[1];
  }

  return {
    addressLine1,
    addressLine2: false,
    zip: false,
    city,
    state,
    lat,
    long,
    isUni
  };
};

export const constructLocation = async (lat, lng, suggestion) => {
  if (suggestion) {
    return constructLocationFromSuggestion(lat, lng, suggestion);
  }
  return await constructLocationFromLatLng(lat, lng);
};

export const createLocationLabel = location => {
  if (location.isUni) {
    return location.addressLine1;
  } else {
    return location.city + ", " + location.state;
  }
};

export const arraysEqual = (_arr1, _arr2) => {
  if (
    !Array.isArray(_arr1) ||
    !Array.isArray(_arr2) ||
    _arr1.length !== _arr2.length
  )
    return false;

  var arr1 = _arr1.concat().sort();
  var arr2 = _arr2.concat().sort();

  for (var i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
};
