import React from "react";
import { request, daysAgoLabel as daysAgoLabelMethod } from "../../lib/library";
import "./Comments.scss";
import CommentReplies from "./CommentReplies";
import AccountService from "../../services/AccountService";
import { Link } from "react-router-dom";

let SCROLL_TO_MESSAGE_OFFSET = 100;
class CommentMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showReplyBox: false,
      showRepliesBox: false,
      items: this.props.commentReplies,
      totalReplies: this.props.numReplies
    };
  }

  saveReply = async () => {
    let reply = document.getElementById(this.props.messageID).value;
    let commentReply = {
      type: "reply",
      comment: reply,
      replySortID: this.props.replySortID
    };

    const result = await request(
      "post",
      `/item/${this.props.itemID}/comment`,
      true,
      commentReply
    );
    if (!result.success) {
      alert(result.message);
    } else {
      document.getElementById("commentText").value = "";
      let newItem = {
        ...result.item,
        commenter: AccountService.state.userState
      };
      this.setState({
        showReplyBox: false,
        items: [...this.state.items, newItem],
        totalReplies: this.state.totalReplies + 1
      });
      this.renderReplies();
      var replyDiv = document.getElementById("replyContainer_" + newItem.uuid);
      var topPos = replyDiv.offsetTop;
      document.getElementById("commentsBox").scrollTop =
        topPos - SCROLL_TO_MESSAGE_OFFSET;
    }
  };

  loadMoreReplies = async () => {
    let attributes;
    if (this.state.items.length >= 10) {
      attributes = {
        type: "reply",
        threadID: this.props.messageID,
        pk: this.state.LastEvaluatedKey.pk,
        sort: this.state.LastEvaluatedKey.sort
      };
    } else {
      attributes = {
        type: "reply",
        threadID: this.props.messageID
      };
    }
    let result = await request(
      "get",
      `/item/${this.props.itemID}/comments`,
      false,
      attributes
    );
    if (!result.success) {
      this.setState({
        message: "Could not load comments at this time."
      });
    } else {
      this.setState({
        items: [...this.state.items, ...result.items],
        LastEvaluatedKey: result.LastEvaluatedKey
      });
    }
  };

  renderReplies = () => {
    if (document.getElementById(this.props.replySortID)) {
      document.getElementById(this.props.replySortID).style.display = "none";
    }
    this.setState({
      renderReplies: true
    });
  };

  totalReplies = () => {
    let replies = this.props.removeDuplicate(this.state.items);
    return replies.map(item => {
      return (
        <CommentReplies
          key={item.sort}
          commentReply={item}
          daysAgoLabel={this.daysAgoLabel(item.sort, 2)}
        />
      );
    });
  };

  renderRepliesBox = () => {
    if (this.state.renderReplies) {
      return (
        <div className="commentsBox replies marginTopSmall">
          {this.totalReplies()}
          <h5
            className="marginBottomMedium textCenter"
            onClick={this.loadMoreReplies}
          >
            {this.remainingReplies()}
          </h5>
        </div>
      );
    } else {
      return null;
    }
  };

  remainingReplies = () => {
    let numRepliesLeft = this.state.totalReplies - this.state.items.length;
    if (numRepliesLeft >= 1) {
      return "view " + numRepliesLeft + " more replies";
    } else {
      return null;
    }
  };

  daysAgoLabel = (messageTime, splitPlace) => {
    return daysAgoLabelMethod(parseInt(messageTime.split("_")[splitPlace]));
  };

  toggleShowReplyBox = () => {
    if (!this.state.showReplyBox) {
      this.setState({ showReplyBox: true });
    } else {
      this.setState({ showReplyBox: false });
    }
  };

  render() {
    let {
      commenter,
      messageID,
      commenterProfilePic,
      message,
      messageTime,
      replySortID,
      numReplies
    } = this.props;
    return (
      <div id={"commentContainer_" + messageID} className="cmt_msgContainer">
        <div className="userImage">
          <Link to={"/user/" + commenter}>
            <img alt="" src={commenterProfilePic} />
          </Link>
        </div>
        <div className="cmt_msgBox">
          <div className="cmt_msg">
            <p className="lineWithoutSpace">
              <span className="marginRightSmall">
                <Link to={"/user/" + commenter}>{commenter}</Link>
              </span>
              {message}
            </p>
          </div>
          <div className="msgTime">
            <div className="time marginRightMedium">
              <h6>{this.daysAgoLabel(messageTime, 1)}</h6>
            </div>
            <h6 onClick={this.toggleShowReplyBox}>reply</h6>
          </div>
          {this.state.showReplyBox ? (
            <div className="replyBox">
              <textarea
                id={messageID}
                maxLength="140"
                placeholder={"replying to " + commenter + "..."}
              ></textarea>
              <button className="postButton" onClick={this.saveReply}>
                reply
              </button>
            </div>
          ) : (
            ""
          )}
          {numReplies ? (
            <h6 id={replySortID} onClick={this.renderReplies}>
              <div className="marginRightXSmall">—</div>
              {numReplies} replies
            </h6>
          ) : (
            ""
          )}
          {this.renderRepliesBox()}
        </div>
      </div>
    );
  }
}

export default CommentMessage;
