import React, { Component } from "react";
import styles from "./LandingPage.module.scss";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-google-places-autocomplete";

const CURRENT_LOCATION_LABEL = "Current Location";

let lastSelectedSuggestion = null;

class LocationInput extends Component {
  static async getLatLngFromAddress(address) {
    return geocodeByAddress(address).then(results => getLatLng(results[0]));
  }

  static getAddress() {
    let locationInput = document.getElementsByClassName(
      styles.locationInput
    )[0];
    if (locationInput && locationInput.value) {
      return locationInput.value;
    }
    return "";
  }

  constructor(props) {
    super(props);
    this.state = {
      address: "Current Location",
      suggestions: [],
      loadingCurrentLocation: false
    };
  }

  componentDidMount() {
    let locationInput = document.getElementsByClassName(
      styles.locationInput
    )[0];
    if (locationInput) {
      locationInput.addEventListener("keyup", this.handleLocationInputKeyPress);
    }
  }

  useCurrentLocation = () => {
    this.setState({
      loadingCurrentLocation: true
    });
    navigator.geolocation.getCurrentPosition(
      position => {
        this.setState({
          loadingCurrentLocation: false
        });
        let locationInput = document.getElementsByClassName(
          styles.locationInput
        )[0];
        if (locationInput) {
          locationInput.value = CURRENT_LOCATION_LABEL;
          locationInput.focus();
        }
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.props.onUpdateLocation(
          position.coords.latitude,
          position.coords.longitude
        );
      },
      () => {
        this.setState({
          loadingCurrentLocation: false
        });
        alert(
          "You need to allow access to your location to use the current location feature."
        );
      }
    );
  };

  handleLocationInputKeyPress = event => {
    if (event.which == 13 || event.keyCode == 13) {
      //code to execute here
      LocationInput.handleEnterLocation(
        this.props.onEnterLocation,
        this.lat,
        this.lng
      );
    }
    return true;
  };

  static handleEnterLocation = (callback, lat, lng) => {
    let locationInput = document.getElementsByClassName(
      styles.locationInput
    )[0];
    if (locationInput && locationInput.value) {
      if (locationInput.value == CURRENT_LOCATION_LABEL) {
        callback(lat, lng);
      } else {
        LocationInput.getLatLngFromAddress(locationInput.value)
          .then(location => {
            callback(location.lat, location.lng, lastSelectedSuggestion);
          })
          .catch(error => {
            if (error == "ZERO_RESULTS") {
              alert("Please enter a valid address.");
            } else {
              console.log(error);
              alert("Could not connect to the server at this time.");
            }
          });
      }
    } else {
      alert("Please enter a valid address.");
    }
  };

  renderGettingCurrentLocationModal() {
    if (this.state.loadingCurrentLocation) {
      return (
        <div className="modalContainer">
          <div className="modalBackdrop">
            <div className="modalForeground">
              <div className="loadingGif center" />
              <div className="marginBottomLarge marginTopSmall textCenter">
                Getting Your Current Location
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <>
        <div
          className={
            styles.locationInputSection + " " + styles[this.props.className]
          }
        >
          <GooglePlacesAutocomplete
            renderSuggestions={(active, suggestions, onSelectSuggestion) => {
              if (this.state.suggestions != suggestions) {
                this.setState({
                  active: active,
                  suggestions: suggestions
                });
                this.onSelectSuggestion = (...args) => {
                  onSelectSuggestion(...args);
                  let locationInput = document.getElementsByClassName(
                    styles.locationInput
                  )[0];
                  locationInput.focus();
                };
              }
            }}
            inputClassName={styles.locationInput}
            loader={<div></div>}
          />
          {this.props.showEnterLocation ? (
            <div className={styles.enterLocationSection}>
              <div
                onClick={() => {
                  LocationInput.handleEnterLocation(
                    this.props.onEnterLocation,
                    this.lat,
                    this.lng
                  );
                }}
              >
                →
              </div>
            </div>
          ) : null}
        </div>

        <ul
          className={
            styles.suggestionContainer + " " + styles[this.props.className]
          }
        >
          {this.state.suggestions.length ? (
            <li className="table" onClick={this.useCurrentLocation}>
              <div className="tr">
                <div
                  className={"td vertMiddle " + styles.locationIconContainer}
                >
                  <div className={styles.currentLocationIcon} />
                </div>
                <div className={"td " + styles.mainText}>
                  {this.props.currentLocationLabel
                    ? this.props.currentLocationLabel
                    : "Shop Current Location"}
                </div>
              </div>
            </li>
          ) : null}
          {this.state.suggestions.slice(0, 3).map(suggestion => (
            <li
              className="table"
              key={suggestion.id}
              onClick={event => {
                lastSelectedSuggestion = suggestion;
                this.onSelectSuggestion(suggestion, event);
              }}
            >
              <div className="tr">
                <div
                  className={"td vertMiddle " + styles.locationIconContainer}
                >
                  <div className="locationIcon" />
                </div>
                <div className={"td " + styles.mainText}>
                  {suggestion.structured_formatting.main_text}
                </div>
              </div>
              <div className="tr">
                <div className="td"></div>
                <div className={"td " + styles.secondaryText}>
                  {suggestion.structured_formatting.secondary_text}
                </div>
              </div>
            </li>
          ))}
        </ul>
        {this.renderGettingCurrentLocationModal()}
      </>
    );
  }
}

export default LocationInput;
