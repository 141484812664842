import AppService from "./AppService";

class FooterService extends AppService {
  startingState() {
    return {
      ...super.startingState(),
      ...{
        hideFooter: false,
        marginTop: true
      }
    };
  }

  hide = () => {
    this.setState({
      hideFooter: true
    });
  };

  show = () => {
    this.setState({
      hideFooter: false
    });
  };

  hideMarginTop = () => {
    this.setState({
      marginTop: false
    });
  };

  showMarginTop = () => {
    this.setState({
      marginTop: true
    });
  };

  shouldHide = () => {
    return this.state.hideFooter;
  };
}

const footerService = new FooterService();

export default footerService;
