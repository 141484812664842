import React from "react";
import { isMobileDevice } from "../../lib/library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import GoogleLogin from "react-google-login";
import AccountService from "../../services/AccountService";
import "./Header.scss";
import AlertPopup from "./AlertPopup";
import ProfilePopup from "./ProfilePopup";
import HeaderService from "../../services/HeaderService";
const topSearch = <FontAwesomeIcon icon={faSearch} />;

class Header extends React.Component {
  constructor() {
    super();
    HeaderService.attachParent(this);

    this.state = {
      ...{},
      ...HeaderService.startingState()
    };
  }

  jumpToTop = () => {
    window.scrollTo(0, 0);
  };

  iconGroup() {
    let userState = AccountService.state.userState;
    let alertBadge = null;
    if (userState.numAlerts > 0) {
      alertBadge = (
        <div className="headerBadgeContainer">
          <div className="headerBadge alertsBadge">{userState.numAlerts}</div>
        </div>
      );
    }

    let reservationBadge = null;
    let totalReservations =
      (userState.numSellingReservations || 0) +
      (userState.numBuyingReservations || 0);
    if (totalReservations > 0) {
      reservationBadge = (
        <div className="headerBadgeContainer">
          <div className="headerBadge reserveBadge">{totalReservations}</div>
        </div>
      );
    }

    const alertIcon = (
      <div className="tooltip headerTooltip">
        <img className="headerIcon" src="/alerts_thin.svg" alt="Alerts" />
      </div>
    );

    if (this.props.loggedIn) {
      return (
        <div className="icon_group">
          {isMobileDevice() ? null : (
            <>
              <div className="headerTextLink">
                <NavLink to="/sell">
                  <h4 alt="Sell">SELL</h4>
                  <div className="tooltip headerTooltip">
                    <span className="tooltiptext tooltip-bottom">Sell</span>
                  </div>
                </NavLink>
              </div>
              <div className="headerTextLink reserveTextLink">
                {reservationBadge}
                <NavLink to="/reserved">
                  <h4 alt="Sell">RESERVATIONS</h4>
                  <div className="tooltip headerTooltip">
                    <span className="tooltiptext tooltip-bottom">Reserved</span>
                  </div>

                  {/* <p>Reserved</p> */}
                </NavLink>
              </div>
            </>
          )}
          <div className="icon alertIconSection">
            {alertBadge}
            {isMobileDevice() ? (
              <NavLink
                to="/notifications"
                className="link"
                activeClassName="active"
              >
                {alertIcon}
              </NavLink>
            ) : (
              <a
                onClick={HeaderService.toggleAlerts}
                onMouseUp={e => {
                  e.stopPropagation();
                }}
                className={
                  "link " + (HeaderService.state.showAlerts ? "active" : "")
                }
              >
                {alertIcon}
              </a>
            )}
            {HeaderService.state.showAlerts ? <AlertPopup /> : null}
          </div>
          {/* <div className="icon">
            <NavLink
              to="/watching"
              className="link"
              activeClassName="link active"
            >
              <div className="tooltip headerTooltip">
                <img
                  className="headerIcon"
                  src="/watch_close.svg"
                  alt="Notifications"
                />
                <span className="tooltiptext tooltip-bottom">Watching</span>
              </div>
            </NavLink>
          </div> */}
          {isMobileDevice() ? null : (
            <div className="icon dropdown">
              <a
                onClick={HeaderService.toggleProfilePopup}
                onMouseUp={e => {
                  e.stopPropagation();
                }}
                className={
                  "link " +
                  (HeaderService.state.showProfilePopup ? "active" : "")
                }
              >
                <img className="headerIcon" src="/profile.svg" alt="Profile" />
                <p>
                  {/* <span className="hide">&#9662;</span> Profile &#9662; */}
                </p>
              </a>
              <ProfilePopup
                show={HeaderService.state.showProfilePopup}
                userState={this.props.userState}
              />
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="google_button_container">
          <GoogleLogin
            clientId="153417564665-g42mcarkumsrsfrd6kl1l8a0uh17p936.apps.googleusercontent.com"
            buttonText="Login or Register"
            onSuccess={AccountService.handleSignInSuccess}
            onFailure={AccountService.handleSignInFailure}
            cookiePolicy={"single_host_origin"}
          />
        </div>
      );
    }
  }

  renderHeader = () => {
    return (
      <div className={this.state.transparentMode ? "transparentMode" : ""}>
        <header>
          <h4 className="nextbest alignLeft">
            <NavLink exact to="/" activeClassName="active">
              <div onClick={this.jumpToTop} className="nextbestLogo">
                nextbest
              </div>
              {/* <img src="/nbnewlogo.svg" className="nextbestLogo" /> */}
            </NavLink>
          </h4>
        </header>
        <div className="header__iconGroup">
          <div className="rightHeaderGroup">{this.iconGroup()}</div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderHeader()}
        {this.state.transparentMode ? null : (
          <div className="headerFiller"></div>
        )}
      </div>
    );
  }
}

export default Header;

/*
 <div className="search">
          <img src="/search.svg" alt="Search" />
          <input
            className="searchField"
            placeholder="Search (Coming Soon)"
          ></input>
        </div>
*/
