import React from "react";
import Modal from "./Modal";
import {
  request,
  requestUser,
  CONSTANTS,
  isMobileDevice
} from "../lib/library";
import { Link } from "react-router-dom";
import "../styles/Modal.scss";
import "cleave.js/dist/addons/cleave-phone.us";
import ReserveService from "../services/ReserveService";
import Cleave from "cleave.js/react";
import { ym } from "../lib/analytics";
import MessageService from "../services/MessageService";
import AccountService from "../services/AccountService";
import WebsocketManager from "../lib/websocketManager";

class AccountModal extends Modal {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      reserving: false
    };
  }

  handleOnPhoneChange = e => {};

  handleReserve = async () => {
    this.setState({
      reserving: true
    });
    const response = await request(
      "post",
      `/item/${this.props.show.itemID}/reserve`,
      true,
      {
        message: document.getElementById("reserveTimes").value
      }
    );

    if (response.success) {
      if (this.props.show) {
        this.setState({
          success: true,
          reserving: false
        });
      }
      ReserveService.notifyEventListeners({
        action: "reserve",
        itemID: this.props.show.itemID
      });
      WebsocketManager.mimicWebsocketOnMessage({
        action: "userStateUpdate",
        propertyName: "numBuyingReservations",
        newValue: AccountService.state.userState.numBuyingReservations + 1
      });
      ym("reachGoal", "item_reservationMade", 7);
    } else {
      this.setState({
        reserving: false
      });
      alert(response.message);
    }
  };

  handleSetupBuyer = async () => {
    const response = await requestUser("put", "/user/reserve", true, {
      action: "apply"
    });

    if (response.success) {
      ym("reachGoal", "buyer_firstTimeRegisterSuccess", 6);
      this.props.onBuyerApplySuccess(response.newJWT);
    } else {
      alert(response.message);
    }
  };

  onExit = () => {
    if (this.state.reserving) {
      if (
        !window.confirm(
          "Are you sure you want to exit? Note that your reservation may have already gone through."
        )
      ) {
        return;
      }
    }
    this.setState({
      success: false,
      reserving: false
    });
    this.props.onExit();
  };

  goToChat = () => {
    window.setTimeout(() => {
      const seller = this.props.show.seller;
      MessageService.addConversation(seller);
      this.onExit();
    }, 200);
  };

  renderChatLink() {
    const seller = this.props.show.seller;
    if (isMobileDevice()) {
      return (
        <Link to={`/chat/${seller.uuid}`}>
          <button className="secondaryButton smallButton">
            <span>See Message</span>
          </button>
        </Link>
      );
    } else {
      return (
        <button onClick={this.goToChat} className="secondaryButton smallButton">
          <span>See Message</span>
        </button>
      );
    }
  }

  renderContent() {
    if (this.state.success) {
      return (
        <div>
          <h2 className="centerText modalTitle textCenter">
            Congrats, this item on hold!
          </h2>

          <div className="center textCenter">
            <p className="textLeft">
              Please coordinate with the seller to schedule a time to meet up.
            </p>
            <br />
            <p className="textLeft">
              You have 5 days to complete this transaction before the item goes
              back on the market.
            </p>

            <div className="fillerDiv"></div>

            <div className="marginBottomMedium">
              <button
                onClick={this.onExit}
                className="primaryButton smallButton"
              >
                <span>Keep Shopping</span>
              </button>

              <span>&nbsp;</span>

              {this.renderChatLink()}
            </div>
          </div>
        </div>
      );
    } else if (this.props.userState.hasSetupBuyer) {
      let buttonContent;
      if (this.state.reserving) {
        buttonContent = <div className="loadingGif center" />;
      } else {
        buttonContent = <span>Reserve</span>;
      }
      return (
        <div>
          <h2 className="centerText modalTitle textCenter">Reserve Item</h2>

          <div className="center textCenter">
            <p className="textLeft">
              When are some good times for you to meet up during the next five
              days?
            </p>

            <textarea
              className="center modalTextArea"
              placeholder="ex: Friday @ 2pm, Sat @ 3pm"
              maxLength="300"
              id="reserveTimes"
            ></textarea>
            <div className="fillerDiv"></div>

            <div className="marginBottomMedium">
              <button
                onClick={this.handleReserve}
                className="primaryButton smallButton"
              >
                {buttonContent}
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h4 className="centerText modalTitle textCenter">Reserve Item</h4>

          <div className="center">
            <h3 className="textLeft">
              Here's how our reservation system works:
            </h3>

            <p className="marginTopMedium">
              1. The seller will immediately be notified about your reservation.
            </p>
            <p>2. The seller will text you to coordinate a meetup.</p>
            <p>3. You have 5 days to meet up with the seller.</p>
            <p>
              4. Try on the item and only pay for the items you want to keep.
            </p>

            <div className="fillerDiv"></div>
          </div>
          <div className="center textCenter marginBottomMedium">
            <button
              onClick={this.handleSetupBuyer}
              className="primaryButton smallButton"
            >
              <span>Next</span>
            </button>
          </div>
        </div>
      );
    }
  }
}

export default AccountModal;
