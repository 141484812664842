export default class AppService {
  constructor() {
    this.parent = false;
    this.listeners = [];
  }

  get state() {
    return this.parent.state;
  }

  startingState() {
    return {};
  }

  setState(newState) {
    this.parent.setState(newState);
  }

  attachParent(parent) {
    this.parent = parent;
  }

  addEventListener(listener) {
    this.listeners.push(listener);
  }

  removeEventListener(listener) {
    const index = this.listeners.indexOf(listener);
    if (index !== -1) {
      this.listeners.splice(index, 1);
    }
  }

  notifyEventListeners(notification) {
    notification.notifier = this.constructor.name;
    for (let listener of this.listeners) {
      listener.onNotification(notification);
    }
  }
}
