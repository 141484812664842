import React, { Component } from "react";
import MessageService from "../../services/MessageService";
import { isOnline, daysAgoLabel, isMobileDevice } from "../../lib/library";
import { Link } from "react-router-dom";

class ConversationHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderOnlineStatus() {
    if (isOnline(this.props.conversation.user.lastSeen)) {
      return <span className="onlineStatus"></span>;
    } else {
      return (
        <span className="messageTabLastSeenLabel">
          {daysAgoLabel(this.props.conversation.user.lastSeen)}
        </span>
      );
    }
  }

  render() {
    let conversation = this.props.conversation;
    return (
      <div className="messageConversationHeader">
        <div
          className="table"
          onClick={() => {
            MessageService.closeConversation(conversation);
          }}
        >
          <div className="tr">
            {isMobileDevice() ? (
              <div className="td vertMiddle messageTabProfileIcon">
                <h5
                  onClick={e => {
                    window.history.back();
                    e.stopPropagation();
                  }}
                >
                  <b>Back</b>
                </h5>
              </div>
            ) : null}
            <div className="td vertMiddle messageTabProfileIcon">
              
              <Link
                to={"/user/" + conversation.user.handle}
                onClick={e => {
                  window.scrollTo(0, 0);
                  e.stopPropagation();
                }}
              >
                <img
                  src={conversation.user.profilePic}
                  className="messageTabProfileImage"
                />
              </Link>
            </div>
            <div className="td vertMiddle messageTabHandle">
              <Link
                to={"/user/" + conversation.user.handle}
                onClick={e => {
                  window.scrollTo(0, 0);
                  e.stopPropagation();
                }}
              >
                <span className="textLink">{conversation.user.handle}</span>
              </Link>
              {this.renderOnlineStatus()}
            </div>
            {isMobileDevice() ? null : (
              <div className="td vertMiddle messageTabClose">
                <div className="tooltip">
                  <div
                    onClick={e => {
                      MessageService.removeConversation(conversation);
                      e.stopPropagation();
                    }}
                    className="messageTabCloseIcon"
                  />
                  <span className="tooltiptext tooltip-bottom-extra">
                    Close tab
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ConversationHeader;
